import {Accordion, AccordionDetails, AccordionSummary, Box, Container, Typography} from "@mui/material";
import UserSeries from "../../components/user/UserSeries";
import UserPartnerEmail from "../../components/user/UserPartnerEmail";
import UserImages from "../../components/user/UserImages";
import UserPersonalInformation from "../../components/user/UserPersonalInformation";

const UserSettingsPage = () => {


  return (
    <Container sx={{height: '100%', margin: 0, marginTop: '3rem', maxWidth: '100vw !important'}}>
      <Typography variant='h5'>Setari utilizator</Typography>
      <Accordion sx={{my: 2}}>
        <AccordionSummary>
          <Typography variant='h6'>Serii facturi</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <UserSeries/>
        </AccordionDetails>
      </Accordion>

      <Accordion sx={{my: 2}}>
        <AccordionSummary>
          <Typography variant='h6'>Adrese email</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <UserPartnerEmail/>
        </AccordionDetails>
      </Accordion>

      <Accordion sx={{my: 2}}>
        <AccordionSummary>
          <Typography variant='h6'>Logo firma</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <UserImages />
        </AccordionDetails>
      </Accordion>

        <Accordion sx={{my: 2}}>
            <AccordionSummary>
                <Typography variant='h6'>Informatii personale</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <UserPersonalInformation />
            </AccordionDetails>
        </Accordion>

    </Container>
  )
}

export default UserSettingsPage;
