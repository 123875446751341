import React, { ReactElement, FC } from "react";
import { Box, Button, Typography } from "@mui/material";
import { createSearchParams, useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";


const HomePage: FC<any> = (): ReactElement => {
	const navigate = useNavigate();

	const options = {
		pathname: "/invoices/notice",
		search: `?${createSearchParams({ showModal: "true" })}`,
	};
	const onClick = () => {
		navigate(options);
	};

	return (
		<Box
			sx={{
				display: "flex",
				padding: "50px 10px 10px 0px",
			}}>
			<Button
				variant="contained"
				size="large"
				onClick={() => onClick()}
				endIcon={<AddIcon />}>
				Creare Aviz
			</Button>
			{/* <Typography variant="h1">Home page</Typography> */}
		</Box>
	);
};

export default HomePage;
