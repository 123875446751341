import {Box, Button} from "@mui/material";
import {FieldValues, useForm} from "react-hook-form";
import BasicFormControl from "../form-helpers/BasicFormControl";
import AutocompleteFormControl from "../form-helpers/AutocompleteFormControl";
import * as api from "../../api/api-client";
import {toast} from "react-toastify";
import {useState} from "react";

interface Props {
  onUserPartnerEmailSubmit: (data: FieldValues) => void;
}

const CreateUserPartnerEmailForm = (props: Props) => {
  const [partners, setPartners] = useState<any[]>([]);

  const {
    handleSubmit,
    formState: {errors},
    control,
    watch,
    setValue,
    reset
  } = useForm();

  const handleUserPartnerEmailSubmit = (data: FieldValues) => {
    console.log(data);

    props.onUserPartnerEmailSubmit(data);
  }

  const onPartnerAutocompleteInput = (event: any) => {
    const {value} = event.target;

    if (value.length >= 3) {
      api.get(`/partners/user/autocomplete?searchKey=${value}`).then((res) => {
        setPartners(res);
      }).catch((err) => {
          toast(err, {type: 'error'});
          console.error(err);
        }
      )
    }
  }

  const onPartnerAutocompleteChange = (event: any, value: any) => {
    setValue('partner', value.partner_id);
    setValue('partner_id', value.partner_id);
  }

  return (

    <form onSubmit={handleSubmit(handleUserPartnerEmailSubmit)}>
      <Box sx={{display: 'flex', width: '100%', flexDirection: 'column'}}>

        <Box sx={{display: 'flex'}}>
          <BasicFormControl control={control}
                            name='partner_email'
                            displayText='Adresa email'
                            rules={{
                              required: {value: true, message: 'Adresa de email este obligatorie'}
                            }}
                            errors={errors}
          />
          <BasicFormControl control={control}
                            name='smtp'
                            displayText='SMTP'
                            rules={{
                              required: {value: true, message: 'Adresa SMTP este obligatorie'}
                            }}
                            errors={errors}
          />
        </Box>

        <Box sx={{display: 'flex'}}>
          <BasicFormControl control={control}
                            name='password'
                            displayText='Parola email'
                            type='password'
                            rules={{
                              required: {value: true, message: 'Parola este obligatorie'}
                            }}
                            errors={errors}
          />
          <AutocompleteFormControl control={control}
                                   name='partner'
                                   displayText='Firma'
                                   options={partners}
                                   optionLabelFunc={(partners) => partners?.partner_name || ""}
                                   onChangeHandler={onPartnerAutocompleteChange}
                                   onInputHandler={onPartnerAutocompleteInput}
                                   rules={{
                                     required: {value: true, message: 'Firma este obligatorie'}
                                   }}
                                   errors={errors}
          />
        </Box>
        <Button variant='contained' type='submit'>Submit</Button>
      </Box>
    </form>
  )
}

export default CreateUserPartnerEmailForm;