export const AutoProfitLossTableConstants = [

    {
      id: 'car',
      label: 'Vehicul',
      width: 100,
    },
    {
      id: 'date',
      type: 'date',
      label: 'Data',
      width: 100,
    },
    {
      id: 'description',
      label: 'Descriere',
      width: 150
    },
    {
        id: "currency",
        label: "Valuta",
        width: 100,
    },
    {
      id: 'profit',
      label: 'Profit',
      width: 100,
    },
    {
      id: 'loss',
      label: 'Pierdere',
      width: 100,
    },

  ]