import {Box, Typography} from "@mui/material";
import {Description, MonetizationOn, ReceiptLong} from "@mui/icons-material";
import React, {useEffect, useState} from "react";
import ReportsCard from "../../components/reports-card/ReportsCard";

interface Props {
  content: any
}

interface ProfitLoss {
  currency: string;
  amount: number;
}

const ProfitLossReports = (props: Props) => {
  const [profit, setProfit] = useState<ProfitLoss[]>([]);
  const [loss, setLoss] = useState<ProfitLoss[]>([]);

  useEffect(() => {
    let ronProfit: ProfitLoss = {currency: 'RON', amount: 0};
    let eurProfit: ProfitLoss = {currency: 'EUR', amount: 0};
    let ronLoss: ProfitLoss = {currency: 'RON', amount: 0};
    let eurLoss: ProfitLoss = {currency: 'EUR', amount: 0};

    const ron = props.content?.find((item: any) => item.currency === 'RON');
    const eur = props.content?.find((item: any) => item.currency === 'EUR');
    if(ron) {
      ronProfit.amount = Number(ron.profit);
      ronLoss.amount = Number(ron.loss);
    }
    if(eur) {
      eurProfit.amount = Number(eur.profit);
      eurLoss.amount = Number(eur.loss);
    }

    eurProfit.amount = parseFloat(eurProfit.amount?.toFixed(2));
    ronProfit.amount = parseFloat(ronProfit.amount?.toFixed(2));

    setProfit([eurProfit, ronProfit]);
    setLoss([eurLoss, ronLoss]);
  }, [props.content])

  return <Box sx={{
    display: 'flex',
    flexWrap: 'wrap', justifyContent: {xs:'space-evenly', md: 'start'}}}>
    <ReportsCard titleText='Profit' icon={<MonetizationOn/>} content={<>
      {profit.map((profitItem: ProfitLoss) => (
        <Box sx={{display: 'flex'}}>
          <Typography color='green' fontWeight='bold'
                      sx={{marginRight: '0.5rem'}}>{profitItem.amount}</Typography>
          <Typography color='white' fontWeight='bold'
                      sx={{textShadow: '1px 1px 2px black'}}>{profitItem.currency}</Typography>
        </Box>
      ))}
    </>}/>
      <ReportsCard titleText='Pierderi' icon={<MonetizationOn/>} content={<>
      {loss.map((profitItem: ProfitLoss) => (
              <Box sx={{display: 'flex'}}>
                  <Typography color='red' fontWeight='bold'
                              sx={{marginRight: '0.5rem'}}>{profitItem.amount}</Typography>
                  <Typography color='white' fontWeight='bold'
                              sx={{textShadow: '1px 1px 2px black'}}>{profitItem.currency}</Typography>
              </Box>
          ))}
      </>}/>
  </Box>
}

export default ProfitLossReports;