import { Box, Button, Card, Container, TextField, Toolbar, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { ProductDto } from "../../types/ProductDto";
import * as api from "../../api/api-client";
import { FieldValues, useForm } from "react-hook-form";
import DatePickerFormControl from "../../components/form-helpers/DatePickerFormControl";
import BasicFormControl from "../../components/form-helpers/BasicFormControl";
import AutocompleteFormControl from "../../components/form-helpers/AutocompleteFormControl";

const ProductDetailsPage = () => {
    const [product, setProduct] = useState<ProductDto>({} as any);
    const [editMode, setEditMode] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [nc8Codes, setNc8Codes] = useState<any>([]);

    const {
        handleSubmit,
        formState: { errors },
        control,
        reset,
        setValue
    } = useForm({
        defaultValues: {
            created_at_utc: "",
            material: "",
            modified_at_utc: "",
            product_id: 0,
            product_name: "",
            purchase_price: "",
            quantity: "",
            type: "",
            unit_of_measure: "",
            vat: "",
            nc8_code_id: 0,
            nc8Code:'',
        }
    })

    let { id } = useParams();

    const navigate = useNavigate();

    useEffect(() => {
        setLoading(true);

        setTimeout(() => {
            api.get(`/products/${id}`).then((res: ProductDto) => {
                setProduct(res);
                reset({ ...res} as any);
            }).catch((err: any) => {
                toast(err, { position: 'bottom-right', type: 'error' })
            }).finally(() => {
                setLoading(false);
            })
        }, 1500)

    }, [])

    const toggleEdit = () => {
        setEditMode(!editMode)
    }

    const handleInputChange = (event: any) => {
        const { name, value } = event.target;

        setProduct((prevState: ProductDto) => {
            return {
                ...prevState,
                [name]: value
            }
        })
    }

    const updateProduct = (data: FieldValues) => {
        api.put(`/products`, data).then((res) => {
            toast('Product succesfully updated', { position: 'top-right', type: 'success' });
            navigate('/products');
        }).catch((err) => {
            toast(err, { position: 'bottom-right', type: 'error' })
        })
    }

    const onNc8CodeAutocompleteInput = (event: any) => {
        const {value} = event.target;

        if (value.length >= 3) {
            api.get(`/products/nc8codes/autocomplete?searchKey=${value}`).then((res) => {
                setNc8Codes(res);
            }).catch((err) => {
                    toast(err, {type: 'error'});
                    console.error(err);
                }
            )
        }
    }

    const onNc8AutocompleteChange = (event: any, value: any) => {
        setValue('nc8_code_id', value?.nc8_code_id || null);
    }

    return (
        <Container sx={{ height: '100%', margin: 0, marginTop: '3rem', maxWidth: '100vw !important' }}>
            <Toolbar sx={{ width: '100%', paddingLeft: '0px !important', paddingRight: '0px !important' }}>
                <Card sx={{ width: '100%' }}>
                    <Button onClick={toggleEdit}>Editare</Button>
                </Card>
            </Toolbar>
            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                <form onSubmit={handleSubmit(updateProduct)} style={{ width: "100%" }}>
                    <Typography sx={{ margin: '1rem' }} variant='h6'>Basic information</Typography>

                    <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row ' } }}>
                        <DatePickerFormControl
                            control={control}
                            displayText="Data crearii"
                            name="created_at_utc"
                            disabled={!editMode}
                        />

                        <BasicFormControl control={control} displayText="Material" name="material" disabled={!editMode} />

                        <DatePickerFormControl
                            control={control}
                            displayText="Data modificarii"
                            name="modified_at_utc"
                            disabled={!editMode}
                        />

                        <BasicFormControl control={control} displayText="Nume" name="product_name" disabled={!editMode} />
                    </Box>

                    <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row ' } }}>
                        <BasicFormControl control={control} displayText="Pret" name="purchase_price" disabled={!editMode} />

                        <BasicFormControl control={control} displayText="Cantitate" name="quantity" disabled={!editMode} />

                        <BasicFormControl control={control} displayText="Tip" name="type" disabled={!editMode} />

                        <BasicFormControl control={control} displayText="Unitate de masura" name="unit_of_measure" disabled={!editMode} />

                        <BasicFormControl control={control} displayText="TVA" name="vat" disabled={!editMode} />
                        {(product && product.product_id) && <AutocompleteFormControl control={control}
                                                 name='nc8Code'
                                                 displayText='Cod NC'
                                                 options={nc8Codes}
                                                 optionLabelFunc={(nc8Code) => (nc8Code? (nc8Code.code + ' - '+nc8Code.description) : "")}
                                                 onInputHandler={onNc8CodeAutocompleteInput}
                                                 onChangeHandler={onNc8AutocompleteChange}
                                                 disabled={!editMode}
                                                 defaultValue={product.nc8Code}
                        />}
                    </Box>
                    <Box>
                        {editMode && <Button type="submit" variant="contained" sx={{ width: '95%', m: '2rem' }}>Submit</Button>}
                    </Box>
                </form>
            </Box>
        </Container>
    )
}

export default ProductDetailsPage;
