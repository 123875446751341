import React, {useEffect, useState} from "react";

import {Container} from "@mui/material";

import DeleteConfirmationDialog from "../../components/DeleteConfirmationDialog";
import {Link, useLocation, useNavigate} from "react-router-dom";
import * as api from "../../api/api-client";
import {toast} from "react-toastify";
import Table from "../../components/table/Table";
import {ReceiptTableConstants} from "./ReceiptTableConstants";
import Filters from "../../components/filters/Filters";
import {KeyValuePair} from "../../types/CommonTypes";
import {FilterSettings} from "../../types/FilterTypes";

const ReceiptPage = () => {
    const [receipts, setReceipts] = useState<any[]>([]);
    const [rowDeleteRequested, setRowDeleteRequested] = useState<any>(undefined);
    const [queryParams, setQueryParams] = useState<string>();
    const [filters, setFilters] = useState<KeyValuePair>({} as KeyValuePair);

    const navigate = useNavigate();

    const filtersSettings: FilterSettings[] = [
        {
            key: 'series',
            display: 'Serie',
            type: 'text',
        },
        {
            key: 'number',
            display: 'Numar',
            type: 'number'
        },
        {
            key: 'created_from',
            display: 'Data emitere (de la)',
            type: 'date'
        },
        {
            key: 'created_to',
            display: 'Data emitere (pana la)',
            type: 'date'
        }
    ];

    const actions: any[] = [
        {
            name: 'deleteReceiptRow',
            displayName: 'Delete row',
            action: handleDeleteRowRequest
        }
    ];

    const location = useLocation();

    useEffect(() => {
        if (queryParams !== location.search) {
            setQueryParams(location.search);
        }
    }, [location]);

    useEffect(() => {
        api.get(`/receipt${queryParams || ''}`).then(res => {
            const mappedReceipts = res.map((receipt: any) => ({
                ...receipt,
                buyer_partner: receipt.buyer_partner.name,
                seller_partner: receipt.seller_partner.name,
                cash_register: receipt.cash_register.name
            }));

            setReceipts(mappedReceipts);
        }).catch(err => {
            toast.error(err.message);
        })
    }, [queryParams])

    useEffect(() => {
        if (filters) {
            navigate(`?${new URLSearchParams(filters).toString()}`);
        }
    }, [filters])

    const formatCell = (row: any, value: string) => {
        return <Link to={`${row.receipt_id}`}>{value}</Link>
    }

    function handleDeleteRowRequest(row: any) {
        setRowDeleteRequested(row);
    }

    const rowDeleteCanceled = (row: any) => {
        setRowDeleteRequested(undefined);
    }

    const handleDeleteRow = () => {
        api.remove(`/receipt`, rowDeleteRequested).then((res) => {
            toast.success(res.message);
            setRowDeleteRequested(undefined);
            api.get(`/receipt`).then((res: any) => {
                const mappedReceipts = res.map((receipt: any) => ({
                    ...receipt,
                    buyer_partner: receipt.buyer_partner.name,
                    seller_partner: receipt.seller_partner.name,
                    cash_register: receipt.cash_register.name
                }));

                setReceipts(mappedReceipts);
            })
        }).catch((err) => {
            toast.error(err);
        })
    }

    const onFiltersChange = (filters: KeyValuePair) => {
        setFilters(filters);
    }

    return <Container sx={{height: '100%', margin: 0, marginTop: '3rem', maxWidth: '100vw !important'}}>
        <Filters filters={filters} filtersSettings={filtersSettings} onFiltersSubmit={onFiltersChange}></Filters>
        <Table
            headers={ReceiptTableConstants}
            rows={receipts}
            getFormattedCell={formatCell}
            actions={actions}
        />

        {rowDeleteRequested &&
            <DeleteConfirmationDialog rowDeleteRequested={rowDeleteRequested}
                                      deleteConfirmationText={rowDeleteRequested.series + rowDeleteRequested.series_number}
                                      handleDeleteRow={handleDeleteRow} rowDeleteCanceled={rowDeleteCanceled}/>
        }

    </Container>;
};

export default ReceiptPage;