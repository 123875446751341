import {useEffect, useState} from "react";
import {
  Box,
  Button,
  Card,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Select,
  Toolbar,
  useMediaQuery,
  useTheme
} from "@mui/material";
import * as api from "../../api/api-client";
import {toast} from "react-toastify";
import {useParams} from "react-router-dom";
import Table from "../../components/table/Table";
import {InvoiceProductTableHeaders} from "./InvoiceProductTableConstants";
import {Controller, FieldValues, useForm} from "react-hook-form";
import {InvoiceProductInfo} from "../../types/InvoiceDto";
import {Close} from "@mui/icons-material";
import AddInvoiceProductForm from "../../components/forms/AddInvoiceProductForm";
import DatePickerFormControl from "../../components/form-helpers/DatePickerFormControl";
import BasicFormControl from "../../components/form-helpers/BasicFormControl";
import AutocompleteFormControl from "../../components/form-helpers/AutocompleteFormControl";
import EditInvoiceProductForm from "../../components/forms/UpdateInvoiceProductForm";
import SelectFormControl from "../../components/form-helpers/SelectFormControl";

const InvoiceDetailsPage = () => {
  const [invoice, setInvoice] = useState<any>({} as any);
  const [productList, setProductList] = useState<any>([]);
  const [series, setSeries] = useState<any[]>([{label: 'Selecteaza o serie', value: 'no'}]);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [clients, setClients] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [addActionDialogOpen, setAddActionDialogOpen] = useState<boolean>(false);
  const [resetInvoice, setResetInvoice] = useState<number>(0);
  const [editInvoiceProductDialogOpen, setEditInvoiceProductDialogOpen] = useState<boolean>(false);
  const [selectedProduct, setSelectedProduct] = useState<any>({} as any);

  const {
    handleSubmit,
    formState: {errors},
    control,
    setValue,
    reset
  } = useForm({
    defaultValues: {
      status: 'default',
      sent_status: 'default',
      series: '',
      number: '',
      created_at: '',
      deadline_at: '',
      client: '',
      buyer: ''
    }
  });

  const {id} = useParams();

  const actions = [
    {
      name: "removeInvoiceItem",
      displayName: "Sterge produs",
      action: removeInvoiceItem
    },
    {
      name: "updateInvoiceItem",
      displayName: "Modifica produs",
      action: updateInvoiceItem
    }
  ]

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    api.get('/users/series').then((res) => {
      const orderSeries = res.filter((resItem: any) => resItem.invoice_type === invoice.type);
      setSeries(orderSeries.map((orderSerie: any) => {
        return {label: orderSerie.series, value: orderSerie.series}
      }));
    }).catch((err) => {
      toast.error(err);
    });
  }, [invoice]);

  useEffect(() => {
    api.get(`/invoices/${id}`).then((res) => {
      setLoading(true);

      const invoiceInformation = {
        invoice_id: res?.invoice?.invoice_id,
        buyer_id: res?.invoice?.buyer_id,
        client_id: res?.invoice?.client_id,
        created_at_utc: res?.invoice?.created_at_utc,
        deadline_at_utc: res?.invoice?.deadline_at_utc || res?.invoice?.created_at_utc,
        number: res?.invoice?.number,
        series: res?.invoice?.series,
        sent_status: res?.invoice?.sent_status,
        status: res?.invoice?.status,
        client: res?.invoice?.client,
        buyer: res?.invoice?.buyer,
        type: res?.invoice?.type
      }

      reset({
        status: res?.invoice?.status || 'default',
        sent_status: res?.invoice?.sent_status || 'default',
        series: res?.invoice?.series,
        number: res?.invoice?.number,
        created_at: res?.invoice?.created_at_utc,
        deadline_at: res?.invoice?.deadline_at_utc ? res?.invoice?.deadline_at_utc : res?.invoice?.created_at_utc,
        client: res?.invoice?.client?.partner_id,
        buyer: res?.invoice?.buyer?.partner_id
      })

      setInvoice(invoiceInformation);
      setProductList(res.productList);
    }).catch((err) => {
      toast.error(err);
    }).finally(() => {
      setLoading(false);
    })
  }, [resetInvoice]);

  const toggleEdit = () => {
    setEditMode(!editMode);
  }

  const onClientAutocompleteInput = (event: any) => {
    const {value} = event.target;

    if (value.length >= 3) {
      api.get(`/partners/autocomplete?searchKey=${value}`).then((res) => {
        setClients(res);
      }).catch((err) => {
          toast(err, {type: 'error'});
          console.error(err);
        }
      )
    }
  }

  const onClientAutocompleteChange = (event: any, value: any) => {
    setValue('buyer', value.partner_id);
  }

  const onInvoiceDataSubmit = (data: FieldValues) => {
    data.sent_status = data.sent_status !== 'default' ? data.sent_status : null;
    data.status = data.status !== 'default' ? data.status : null;
    data.invoice_id = invoice.invoice_id;

    api.put(`/invoices`, data).then((res) => {
      toast.success('Factura modificata cu success')
      setEditMode(false);
    }).catch((err) => {
      toast.error(err?.message);
      console.error(err);
    });
  }

  const openAddInvoiceProductDialog = () => {
    setAddActionDialogOpen(true);
  }

  const handleIssuedInvoiceProduct = (data: any) => {
    if (data.type === 'service') {
      api.post('/products', data).then((res) => {
        const productInfo: InvoiceProductInfo = {
          invoice_id: Number(id),
          product_id: res.product_id,
          quantity: data.quantity,
          purchase_price: Number(data.purchase_price)
        }

        api.post(`/invoices/${id}/product/add`, productInfo).then((res) => {
          toast.success('Produse adaugate cu success');
          setResetInvoice((prevState: number) => prevState + 1);
        }).catch((err) => {
          console.error(err);
        });
      }).catch((err) => {
        toast.error(err);
      }).finally(() => {
        setAddActionDialogOpen(false);

      }).catch((err) => {
        toast.error(err);
      })

      return;
    } else {

      api.post('/products/quantity/reserve', {product_name: data.product_name, quantity: data.quantity}).then((res) => {
        const productInfo: InvoiceProductInfo = {
          invoice_id: Number(id),
          product_id: data.product_id,
          quantity: data.quantity,
          purchase_price: Number(data.purchase_price)
        }

        api.post(`/invoices/${id}/product/add`, productInfo).then((res) => {
          toast.success('Produse adaugate cu success');
          setResetInvoice((prevState: number) => prevState + 1);
        }).catch((err) => {
          console.error(err);
        });
      }).catch((err) => {
        toast.error(err);
      }).finally(() => {
        setAddActionDialogOpen(false);
      })
    }
  }

  const handleReceivedInvoiceProduct = (data: any) => {
    api.post('/products', data).then((res) => {
      toast.success('Produs adaugat cu success');
      const productInfo: InvoiceProductInfo = {
        invoice_id: Number(id),
        product_id: res.product_id,
        quantity: data.quantity,
        purchase_price: Number(data.purchase_price)
      }


      api.post(`/invoices/${id}/product/add`, productInfo).then((res) => {
        setResetInvoice((prevState: number) => prevState + 1);
        toast.success('Produse adaugate cu success');
      }).catch((err) => {
        console.error(err);
      });
    }).catch((err) => {
      toast.error(err);
    }).finally(() => {
      setAddActionDialogOpen(false);
    })
  }

  const onInvoiceProductSubmit = (data: any) => {

    if (invoice.type === 'received') {
      handleReceivedInvoiceProduct(data);
    }

    if (invoice.type === 'issued') {
      handleIssuedInvoiceProduct(data);
    }

    if (invoice.type === 'notice') {
      handleIssuedInvoiceProduct(data);
    }
  }

  function removeInvoiceItem(currentRow: any) {
    const productInfo: InvoiceProductInfo = {
      invoice_id: Number(id),
      product_id: currentRow.product_id,
      quantity: currentRow.quantity,
      purchase_price: Number(currentRow.purchase_price)
    }

    api.post(`/invoices/${id}/product/remove`, productInfo).then((res) => {
      toast.success(res.message);
      setResetInvoice((prevState: number) => prevState + 1);
    }).catch((err) => {
      toast.error(err);
    })
  }

  function updateInvoiceItem(currentRow: any) {
    setSelectedProduct(currentRow);
    setEditInvoiceProductDialogOpen(true);
  }

  const onInvoiceProductEdit = (data: FieldValues) => {
    data.invoice_id = invoice.invoice_id;
    data.invoice_product_id = productList.find((prod: any) => prod.product_name === data.product_name).invoice_product_id;

    api.post(`/invoices/${invoice.invoice_id}/product/update`, data).then((res) => {
      setResetInvoice((prevState: number) => prevState + 1);
      toast.success('Produs modificat cu success');
    }).catch((err) => {
      console.error(err)
    }).finally(() => {
      setEditInvoiceProductDialogOpen(false);
    });
  }

  return (
    <Container sx={{height: '100%', margin: 0, marginTop: '3rem', maxWidth: '100vw !important'}}>
      <Toolbar sx={{width: '100%', paddingLeft: '0px !important', paddingRight: '0px !important'}}>
        <Card sx={{width: '100%'}}>
          <Button onClick={toggleEdit}>Editare</Button>
        </Card>
      </Toolbar>
      <Box>
        <>
          {!loading &&
              <form onSubmit={handleSubmit(onInvoiceDataSubmit)}>

                {invoice && invoice.client && <Box sx={{display: "flex", flexDirection: "row", width: '100%'}}>
                    <AutocompleteFormControl control={control} name="client" displayText="Client" options={[]}
                                             optionLabelFunc={(partner) => partner?.partner_name}
                                             defaultValue={{
                                               partner_id: invoice.client.partner_id,
                                               partner_name: invoice.client.name
                                             } || ""} disabled={true}
                    />
                    <AutocompleteFormControl control={control} name="buyer" displayText="Buyer" options={clients}
                                             defaultValue={{
                                               partner_id: invoice.buyer.partner_id,
                                               partner_name: invoice.buyer.name
                                             } || ""}
                                             optionLabelFunc={(clients) => clients?.partner_name}
                                             onChangeHandler={onClientAutocompleteChange}
                                             onInputHandler={onClientAutocompleteInput}
                                             disabled={!editMode}
                    />

                </Box>}

                  <Box sx={{display: 'flex', width: '100%'}}>

                      { invoice?.type === "received" ? 
                        <BasicFormControl control={control} name="series" displayText="Series" disabled={!editMode}/>
                        :
                        <SelectFormControl control={control}
                                        name='series'
                                        selectOptions={series}
                                        displayText='Serie'
                                        rules={{required: {value: true, message: 'Seria este obligatorie'}}}
                                        disabled={!editMode}
                                        errors={errors}
                      />}
                      <BasicFormControl control={control} name="number" displayText="Number" disabled={!editMode}/>

                  </Box>

                  <Box sx={{display: 'flex'}}>

                      <DatePickerFormControl control={control} name="created_at" displayText="Created at"
                                             rules={{required: {value: true, message: 'Creation date is required'}}}
                                             errors={errors} disabled={!editMode}/>
                      <DatePickerFormControl control={control} name="deadline_at" displayText="Deadline at"
                                             disabled={!editMode}/>
                  </Box>

                  <Box sx={{display: 'flex'}}>
                      <Box sx={{display: 'flex', flexDirection: 'column', mx: 1, width: '100%'}}>
                          <Controller
                              render={({field}) => (
                                <Select {...field} sx={{my: '1rem'}} disabled={!editMode}>
                                  <MenuItem value='default'>Status email</MenuItem>
                                  <MenuItem value='sent'>Trimis</MenuItem>
                                  <MenuItem value='not sent'>Netrimis</MenuItem>
                                </Select>
                              )}
                              name="sent_status"
                              control={control}
                              rules={{
                                pattern: {value: /default|sent|not sent/, message: 'Incorrect sent status'}
                              }}
                          />
                      </Box>

                      <Box sx={{display: 'flex', flexDirection: 'column', mx: 1, width: '100%'}}>
                          <Controller
                              render={({field}) => (
                                <Select {...field} sx={{my: '1rem'}} disabled={!editMode}>
                                  <MenuItem value='default'>Status plati</MenuItem>
                                  <MenuItem value='paid'>Platit</MenuItem>
                                  <MenuItem value='overdue'>Depasit</MenuItem>
                                  <MenuItem value='incomplete payment'>Incomplet</MenuItem>
                                  <MenuItem value='unpaid'>Neplatit</MenuItem>
                                </Select>
                              )}
                              name="status"
                              control={control}
                              rules={{
                                required: {value: true, message: 'Product type is required'},
                                pattern: {
                                  value: /default|paid|overdue|incomplete payment|unpaid/,
                                  message: 'Incorrect payment status'
                                }
                              }}
                          />
                      </Box>

                  </Box>

                {editMode &&
                    <Button type="submit" variant='contained' sx={{width: '100%'}}>Trimiteti datele facturii</Button>
                }
              </form>
          }
          <Table headers={InvoiceProductTableHeaders}
                 rows={productList}
                 actions={actions}
                 handleAddActionClick={openAddInvoiceProductDialog}
          />
        </>
      </Box>

      <Dialog
        fullScreen={fullScreen}
        open={addActionDialogOpen}
        aria-labelledby="responsive-dialog-title"
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle id="responsive-dialog-title"
                     sx={{width: '100%', display: 'flex', justifyContent: 'space-between'}}>
          Adauga produse
          <IconButton
            edge="end"
            color="inherit"
            onClick={() => setAddActionDialogOpen(false)}
            aria-label="close"
            sx={{justifySelf: 'end'}}
          >
            <Close/>
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{height: '100%'}}>
          <AddInvoiceProductForm handleAddInvoiceProduct={onInvoiceProductSubmit} invoiceType={invoice.type}/>
        </DialogContent>
      </Dialog>

      <Dialog
        fullScreen={fullScreen}
        open={editInvoiceProductDialogOpen}
        aria-labelledby="responsive-dialog-title"
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle id="responsive-dialog-2"
                     sx={{width: '100%', display: 'flex', justifyContent: 'space-between'}}>
          Update invoice products
          <IconButton
            edge="end"
            color="inherit"
            onClick={() => setEditInvoiceProductDialogOpen(false)}
            aria-label="close"
            sx={{justifySelf: 'end'}}
          >
            <Close/>
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{height: '100%'}}>
          <EditInvoiceProductForm product={selectedProduct} editInvoiceProductForm={onInvoiceProductEdit} invoiceType={invoice.type}/>
        </DialogContent>
      </Dialog>
    </Container>
  )
}

export default InvoiceDetailsPage;

