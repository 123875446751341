import {Controller, FieldErrors, FieldPath, FieldValues} from 'react-hook-form';
import {Box, InputAdornment, TextField, Typography} from '@mui/material';
import {RegisterOptions} from 'react-hook-form/dist/types/validator';

interface Props {
  control: any;
  name: string;
  displayText: string;
  rules?: Omit<
    RegisterOptions<FieldValues, FieldPath<any>>,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'
  >;
  errors?: FieldErrors;
  type?: string;
  variant?: 'outlined' | 'standard';
  disabled?: boolean;
  multiline?: boolean;
  adornment?: string;
  rows?: number;
  hint?: string;
}

const BasicFormControl = (props: Props) => {
  return (
    <Box sx={{width: '100%', mx: {xs: '0', md: '1rem'}}}>
      <Controller
        name={props.name}
        rules={props.rules}
        control={props.control}
        render={({field}) => (
          <TextField
            {...field}
            helperText={props.hint}
            sx={{width: '100%', my: '1rem'}}
            label={props.displayText}
            variant={props.variant || 'standard'}
            error={!!props.errors?.[props.name] || false}
            type={props.type || 'text'}
            disabled={props.disabled || false}
            multiline={props.multiline || false}
            rows={props.rows || 1}
            InputProps={{
              endAdornment: <InputAdornment position="end">{props.adornment}</InputAdornment>
            }}
          />
        )}
      />
      {props.errors?.[props.name] && (
        <Typography variant="caption" sx={{color: 'red'}}>
          {props.errors?.[props.name]?.message?.toString()}
        </Typography>
      )}
    </Box>
  );
};

export default BasicFormControl;
