import {Box, Card, CardContent, CardHeader, Typography} from "@mui/material";
import React from "react";

interface Props {
  titleText: string;
  icon: JSX.Element;
  content: JSX.Element;
}

const ReportsCard = (props: Props) => {
  return (
    <>
      <Card sx={{
        width: '200px',
        minHeight: '110px',
        background: '#D3D3D3',
        boxShadow: 3,
        my: '0.5rem',
        mx: '0.5rem'
      }}>
        <CardHeader sx={{color: 'white'}} title={
          <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
            <Typography
              sx={{color: 'white', fontWeight: 'bold', textShadow: '1px 1px 2px black'}}>{props.titleText}</Typography>
            {props.icon}
          </Box>
        }/>
        <CardContent sx={{paddingTop: 0}}>{props.content}</CardContent>
      </Card>
    </>
  )
}

export default ReportsCard;