import {FieldValues, useForm} from "react-hook-form";
import AutocompleteFormControl from "../form-helpers/AutocompleteFormControl";
import * as api from "../../api/api-client";
import {toast} from "react-toastify";
import {useState} from "react";
import {Box, Button} from "@mui/material";
import SelectFormControl from "../form-helpers/SelectFormControl";

const UserImages = () => {
  const [partners, setPartners] = useState<any>([]);
  const [file, setFile] = useState<any>();

  const {
    control,
    setValue,
    setError,
    clearErrors,
    formState: {errors},
    handleSubmit
  } = useForm();

  const onPartnerAutocompleteInput = (event: any) => {
    const {value} = event.target;

    if (value.length >= 3) {
      api.get(`/partners/user/autocomplete?searchKey=${value}`).then((res) => {
        setPartners(res);
      }).catch((err) => {
          toast(err, {type: 'error'});
          console.error(err);
        }
      )
    }
  }
  const onPartnerAutocompleteChange = (event: any, value: any) => {
    setValue('partner_id', value.partner_id);
  }

  const onImageSubmit = (data: FieldValues) => {
    const formData = new FormData();

    formData.append("image", file);
    formData.append("type", data.type);
    formData.append("partner_id", data.partner_id);

    api.post('/images', formData, {"Content-Type": "multipart/form-data"}).then((res) => {
      toast.success('Imaginea a fost adaugata!');
    }).catch((err) => {
      toast.error(err?.message);
    });
  }


  return (
    <>
      <form onSubmit={handleSubmit(onImageSubmit)}>
        <Box sx={{display: 'flex', flexDirection: 'column'}}>
          <Box sx={{display: 'flex', flexDirection: 'column', width: '95%'}}>
            <input
              style={{marginBottom: '1rem'}}
              onChange={e => setFile(e?.target?.files?.[0])}
              type="file"
              accept="image/*"
              required={true}
            ></input>

            <SelectFormControl control={control}
                               name='type'
                               selectOptions={[{label: 'LOGO', value: 'LOGO'}]}
                               rules={{
                                 required: {
                                   value: true,
                                   message: 'Campul este necesar'
                                 }
                               }}
                               errors={errors}
                               displayText='Tip'/>

            <AutocompleteFormControl control={control}
                                     name='partner_id'
                                     displayText='Partener'
                                     options={partners}
                                     rules={{
                                       required: {
                                         value: true,
                                         message: 'Campul este necesar'
                                       }
                                     }}
                                     errors={errors}
                                     optionLabelFunc={(partners: any) => partners?.partner_name || ''}
                                     onChangeHandler={onPartnerAutocompleteChange}
                                     onInputHandler={onPartnerAutocompleteInput}
            />
          </Box>
          <Button variant='contained' type='submit'>Submit</Button>
        </Box>
      </form>
    </>
  )
}

export default UserImages;