import { FieldValues, useForm } from "react-hook-form";
import { Box, Button } from "@mui/material";
import BasicFormControl from "../form-helpers/BasicFormControl";
import SelectFormControl from "../form-helpers/SelectFormControl";
import AutocompleteFormControl from "../form-helpers/AutocompleteFormControl";
import * as api from "../../api/api-client";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";

interface Props {
    onContactSubmit: (data: FieldValues) => void;
    existingContact?: any
    partner_id: number
}

const CreateContactForm = (props: Props) => {
    const {
        handleSubmit,
        formState: { errors },
        control,
        reset
    } = useForm({
        defaultValues: {
            contact_id: undefined,
            contact_email: "",
            phone: "",
            first_name: "",
            last_name: "",
            personal_identification_number: "",
            car_registration_number: "",
            department: "",
            partner_id: props.partner_id
        }
    });

    useEffect(() => {
        if (props.existingContact) {
          reset(props.existingContact);
        }
      }, [props.existingContact])

    const handleContactSubmit = (data: FieldValues) => {
        props.onContactSubmit(data);
    }

    return (
        <>
            <form onSubmit={handleSubmit(handleContactSubmit)}>
                <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
                    <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
                        <Box sx={{ display: "flex" }}>
                            <BasicFormControl control={control} name='first_name' displayText='Prenume' variant='outlined'
                                errors={errors}
                                rules={{
                                    required: { value: true, message: 'IBAN-ul este obligatoriu' }
                                }} />
                            <BasicFormControl control={control} name="last_name" displayText="Nume" variant='outlined' />
                        </Box>
                        <Box sx={{ display: "flex" }}>
                            <BasicFormControl control={control} name='contact_email' displayText='E-mail' variant='outlined' />
                            <BasicFormControl control={control} name="phone" displayText="Telefon" variant='outlined' />
                        </Box>
                    </Box>
                    <Button sx={{ my: 2, width: '100%' }} type="submit" variant="contained">Submit</Button>
                </Box>
            </form>
        </>
    )
}

export default CreateContactForm;