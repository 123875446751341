import {useEffect, useState} from "react";
import {Box} from "@mui/material";
import * as api from "../../api/api-client";
import {toast} from "react-toastify";
import {FieldValues} from "react-hook-form";
import Table from "../table/Table";
import {UserSettingsTableConstants} from "../../pages/user/UserSettingsTableConstants";
import DialogContainer from "../DialogContainer";
import CreateInvoiceSeries from "../forms/CreateInvoiceSeries";

const UserSeries = () => {
  const [userSeries, setUserSeries] = useState([]);
  const [isSeriesDialogOpen, setIsSeriesDialogOpen] = useState<boolean>(false);

  const actions = [
    {
      name: "changeDefaultSeries",
      displayName: "Seteaza ca serie implicita",
      action: changeDefaultSeries
    },
    {
      name: "deleteSeries",
      displayName:"Sterge serie",
      action: deleteSeries
    }
  ]

  useEffect(() => {
    api.get('/users/series').then((res) => {
      setUserSeries(res);
    }).catch((err) => {
      toast.error(err);
    });
  }, []);

  const addInvoiceSeries = () => {
    setIsSeriesDialogOpen(true);
  }

  const handleAddSeriesDialogState = (dialogState: boolean) => {
    setIsSeriesDialogOpen(dialogState);
  }

  const onSeriesInput = (data: FieldValues) => {
    api.post('/users/series', data).then((res) => {
      console.log(res);
      setIsSeriesDialogOpen(false);

      api.get('/users/series').then((res) => {
        setUserSeries(res);
      }).catch((err) => {
        toast.error(err);
      });

      toast.success('Invoice series added');
    }).catch((err) => {
      toast.error(err);
    })
  }

  function changeDefaultSeries(currentRow: any) {
    api.post('/users/series/change', currentRow).then((res) => {
      toast.success('Default invoice series changed');

      api.get('/users/series').then((res) => {
        setUserSeries(res);
      }).catch((err) => {
        toast.error(err);
      });
    }).catch((err) => {
      toast.error(err);
    })
  }

  function deleteSeries(currentRow: any) {
    api.remove('/users/series', {series_id: currentRow.user_invoice_series_id} as any).then((res) => {
      toast.success('Seria a fost stearsa');
      api.get('/users/series').then((res) => {
        setUserSeries(res);
      }).catch((err) => {
        toast.error(err.message);
      });
    }).catch((err) => {
      toast.error(err.message);
    });
  }

  return (
    <>
      <Box>
        <Table headers={UserSettingsTableConstants} rows={userSeries} handleAddActionClick={addInvoiceSeries}
               actions={actions}/>
      </Box>

      <DialogContainer isDialogOpen={isSeriesDialogOpen} dialogTitle='Adauga serie'
                       handleDialogState={handleAddSeriesDialogState}>
        <CreateInvoiceSeries onSeriesInput={onSeriesInput}/>
      </DialogContainer>
    </>
  )
}

export default UserSeries;