import {Container} from "@mui/material";
import {useEffect, useState} from "react";
import * as api from "../../api/api-client";
import {CreateEmployee, Employee} from "../../types/EmployeeDto";
import {toast} from "react-toastify";
import {Link} from "react-router-dom";
import Table from "../../components/table/Table";
import {EmployeeTableHeaders} from "./EmployeeTableConstants";
import DialogContainer from "../../components/DialogContainer";
import CreateEmployeeForm from "../../components/forms/CreateEmployeeForm";
import {FieldValues} from "react-hook-form";

const EmployeesPage = () => {
  const [employees, setEmployees] = useState<Employee[]>([]);
  const [addEmployeeDialogOpen, setAddEmployeeDialogOpen] = useState<boolean>(false)
  useEffect(() => {
    api.get('/employees').then((res: any) => {
      setEmployees(res);
    }).catch((err) => {
      toast.error(err);
    })
  }, []);

  const handleCreateEmployee = () => {
    setAddEmployeeDialogOpen(true);
  }

  const handleAddEmployeeDialogState = (dialogState: boolean) => {
    setAddEmployeeDialogOpen(dialogState);
  }

  const handleEmployeeInput = (data: FieldValues) => {
      api.post('/employees', data).then((res: any) => {
        toast.success('Employee successfully created!');
        setAddEmployeeDialogOpen(false);

        api.get('/employees').then((res: any) => {
          setEmployees(res);
        }).catch((err) => {
          toast.error(err);
        })

      }).catch((err) => {
        console.log(err);
        toast.error(err.message);
      });
  }

  const formatCell = (row: any, value: string) => {
    return <Link to={`${row.employee_id}`}>{value}</Link>
  }

  return (
    <>
      <Container sx={{height: '100%', margin: 0, marginTop: '3rem', maxWidth: '100vw !important'}}>
        <Table headers={EmployeeTableHeaders} rows={employees} getFormattedCell={formatCell}
               handleAddActionClick={handleCreateEmployee}></Table>
      </Container>

      <DialogContainer isDialogOpen={addEmployeeDialogOpen} dialogTitle='Adauga angajat'
                       handleDialogState={handleAddEmployeeDialogState}>
        <CreateEmployeeForm onEmployeeInput={handleEmployeeInput} />
      </DialogContainer>
    </>
  )
}

export default EmployeesPage;