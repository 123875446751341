import {Box, Container} from "@mui/material";
import {useEffect, useState} from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import Filters from "../../components/filters/Filters";
import Table from "../../components/table/Table";
import {KeyValuePair} from "../../types/CommonTypes";
import {FilterSettings} from "../../types/FilterTypes";
import {ProductTableHeaders} from "./ProductsTableConstants";
import * as api from "../../api/api-client";
import DialogContainer from "../../components/DialogContainer";
import CreateProductForm from "../../components/forms/CreateProductForm";
import {FieldValues} from "react-hook-form";
import {toast} from "react-toastify";

interface Product {
  product_id: number;
  product_name: string;
  quantity: number;
  purchase_price: number;
  selling_price: number;
  vat: number;
  created_at_utc: Date;
}

const ProductsPage = () => {
  const [addProductsDialogOpen, setAddProductsDialogOpen] = useState<boolean>(false);
  const [products, setProducts] = useState<Product[]>([]);
  const [queryParams, setQueryParams] = useState<string>();
  const [filters, setFilters] = useState<KeyValuePair>({} as KeyValuePair);
  const filtersSettings: FilterSettings[] = [
    {
      key: 'product_name',
      display: 'Name',
      type: 'text'
    },
    {
      key: 'quantity',
      display: 'Cantitate',
      type: 'number'
    },
    {
      key: 'created_at_from',
      display: 'Created from',
      type: 'date',
    },
    {
      key: 'created_at_to',
      display: 'Created to',
      type: 'date'
    },
    {
      key: 'selling_price',
      display: 'Pret vanzare',
      type: 'number'
    },
    {
      key: 'purchase_price',
      display: 'Pret',
      type: 'number'
    },
    {
      key: 'nc8Code',
      display: 'Cod NC',
      type: 'text'
    }
  ];

  const location = useLocation();

  useEffect(() => {
    if (queryParams !== location.search) {
      setQueryParams(location.search);
    }
  }, [location])

  useEffect(() => {
    getProducts();
  }, [queryParams]);

  useEffect(() => {
    if(filters) {
      navigate(`?${new URLSearchParams(filters).toString()}`);
    }
  }, [filters])

  const getProducts = () => {
    api.get(`/products${queryParams}`).then((res: any) => {
      res.forEach((resItem: any) => {
        resItem.nc8Code = resItem?.nc8Code?.code
      })
      setProducts(res);
    });
  }

  const navigate = useNavigate();

  const formatCell = (row: any, value: string) => {

    return <Link to={`${row.product_id}`}>{value}</Link>
  }

  const handleAddProductsDialogState = (dialogState: boolean) => {
    setAddProductsDialogOpen(dialogState);
  }

  const onProductInput = (data: FieldValues) => {
    api.post('/products', data).then((res) => {
      toast.success('Product successfully added');
      handleAddProductsDialogState(false);

      getProducts();
    }).catch((err) => {
      toast.error('Something went wrong!');
    })
  }

  const onFiltersChange = (filters: KeyValuePair) => {
    setFilters(filters);
  }

  return (
    <Container sx={{height: '100%', margin: 0, marginTop: '3rem', maxWidth: '100vw !important'}}>
      <Filters filters={filters} filtersSettings={filtersSettings} onFiltersSubmit={onFiltersChange}></Filters>
      <Box sx={{display: 'flex', flexDirection: 'column', width: '100%'}}>
        <Table headers={ProductTableHeaders} rows={products} getFormattedCell={formatCell} handleAddActionClick={() => handleAddProductsDialogState(true)}/>
      </Box>

      <DialogContainer isDialogOpen={addProductsDialogOpen} dialogTitle='Adauga produs' handleDialogState={handleAddProductsDialogState}>
        <CreateProductForm onProductInput={onProductInput} />
      </DialogContainer>
    </Container>
  )
}

export default ProductsPage;
