import React, {useEffect, useState,} from "react";
import Table from "../../components/table/Table";
import {Container} from "@mui/material";
import * as api from "../../api/api-client";
import {toast} from "react-toastify";
import {useLocation, useNavigate} from "react-router-dom";

import {DeliveryTableHeaders} from "./DeliveryTableConstants";
import {KeyValuePair} from "../../types/CommonTypes";
import {FilterSettings} from "../../types/FilterTypes";
import Filters from "../../components/filters/Filters";
import OrderReports from "../../components/order/OrderReports";
import jwtDecode from "jwt-decode";
import OrderDetailsReports from "../../components/order/OrderDetailsReports";


//const [user, setUser] = useState<any>();

const OrdersPage = () => {
  const [orders, setOrders] = useState<any>([]);
  const [filters, setFilters] = useState<KeyValuePair>({} as KeyValuePair);
  const [queryParams, setQueryParams] = useState<string>('');

  const [reset, setReset] = useState<number>(0);

  //const user = jwtDecode(localStorage.getItem('token') || '');

  const actions = [
    {
      name: 'deleteRow',
      displayName: 'Mergi la Comanda',
      action: handleOrderDetailsDelete
    },
    ];

  const filterable: FilterSettings[] = [
    {
      key: 'dropoff_county',
      display: 'Judet livrare',
      type: 'text'
    },
    {
      key: 'dropoff_date_from',
      display: 'Data livrare (de la)',
      type: 'date'
    },
    {
      key: 'dropoff_date_to',
      display: 'Data livrare (pana la)',
      type: 'date'
    },
  ]

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (queryParams !== location.search) {
      setQueryParams(location.search);
    }
  }, [location])


  useEffect(() => {
    api.get(`/delivery/details${queryParams}`).then((res) => {

      setOrders(res);
    }).catch((err) => {
      toast.error(err);
    })
  }, [queryParams, reset]);

  useEffect(() => {
    if (filters) {
      navigate(`?${new URLSearchParams(filters).toString()}`);
    }
  }, [filters])


  function handleOrderDetailsDelete(row: any) {
    navigate(`/orders/${row.order_id}`);

  }
  
  const onFilterSubmit = (filters: KeyValuePair) => {
    setFilters(filters);
  }

  return (
    <Container sx={{height: '100%', margin: 0, marginTop: '3rem', maxWidth: '100vw !important'}}>
      <OrderDetailsReports content={orders}/>

      <Filters filters={filters} filtersSettings={filterable} onFiltersSubmit={onFilterSubmit}/>

      <Table headers={DeliveryTableHeaders}
             rows={orders} 
             actions={actions}/>

    </Container>
  )
}

export default OrdersPage;