import { FC } from 'react';
import { Document, Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer';

const styles = StyleSheet.create({
	container: {
		display: 'flex',
		flexDirection: 'row',
		marginLeft: '50pt',
		marginTop: '20pt',
		width: '100%',
	},
	logoContainer: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		width: '30%',
	},
	logo: {
		width: '100%',
	},
	textContainer: {
		marginLeft: '50pt',
	},
	textRow: {
		display: 'flex',
		flexDirection: 'row',
	},
	text: {
		fontSize: '12pt',
	},
	textBold: {
		fontSize: '12pt',
		fontWeight: 900,
	},
	pageContainer: {
		paddingVertical: '40pt'
	},
	headerContainer: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		marginTop: '28pt',
		marginBottom: '20pt',
		width: '100%',
	},
	headerText: {
		fontSize: '28pt',
		marginBottom: '10pt',
	},
	bodyText: {
		fontSize: '16pt',
	},
	containerTable: {
		display: 'flex',
		width: '100%',
		marginVertical: '10pt',
		flexDirection: 'column',
	},
	tableRow: {
		flexDirection: 'row',
		alignItems: 'center',
		fontSize: '10pt',
	},
	tableRowHeader: {
		flexDirection: 'row',
		alignItems: 'center',
		fontSize: '12pt',
		fontWeight: 'bold',
		borderBottom: '1pt solid #000'
	},
	tableCell: {
		flex: 1,
		padding: '2pt',
		borderLeft: '1pt solid #000',
		textAlign: 'center'
	},
	tableCellBig: {
		flex: 2,
		padding: '2pt',
	},
	tableCellBigEnd: {
		flex: 2,
		padding: '2pt',
		borderLeft: '1pt solid #000',
		textAlign: 'center'
	},
	headerCell: {
		fontWeight: 'bold',
	},
});
interface Props {
	repairEstimate: any,
	logo: any
}

const RepairEstimateTemplate: FC<Props> = ({ repairEstimate, logo }) => {
	return <>
		<Document>
			<Page size="A4" wrap style={styles.pageContainer}>
				{repairEstimate && (
					<View>
						<View style={styles.container}>
							<View style={styles.logoContainer}>
								<Image style={styles.logo} src={logo} />
							</View>
							<View style={styles.textContainer}>
								<Text style={styles.textBold}>{repairEstimate?.name}</Text>
								<View style={styles.textRow}>
									<Text style={styles.textBold}>CUI:</Text>
									<Text style={styles.textBold}>{repairEstimate?.cui} {repairEstimate.registerNumber}</Text>
								</View>
								<Text style={styles.textBold}>{repairEstimate?.address}</Text>
								<View style={styles.textRow}>
									<Text style={styles.textBold}>Mobil: </Text>
									<Text style={styles.textBold}>{repairEstimate?.phone}</Text>
								</View>
								<View style={styles.textRow}>
									<Text style={styles.textBold}>e-mail: </Text>
									<Text style={styles.textBold}>{repairEstimate?.email}</Text>
								</View>
							</View>
						</View>
						<View style={styles.headerContainer}>
							<Text style={styles.headerText}>Deviz</Text>
							<Text style={styles.bodyText}>Nr. deviz: {repairEstimate?.deviz_id}</Text>
						</View>
						<View style={styles.containerTable}>
							<View style={styles.tableRowHeader}>
								<Text style={styles.tableCellBig}>
									{repairEstimate && repairEstimate?.auto_fleet_id ? 'Nr Masina' : 'Nume firma'}
								</Text>
								<Text style={styles.tableCell}>Data emitere</Text>
								<Text style={styles.tableCellBigEnd}>Denumire produs</Text>
								<Text style={styles.tableCell}>Pret</Text>
								<Text style={styles.tableCellBigEnd}>Observatii</Text>
							</View>
							<View style={styles.tableRow}>
								<Text style={styles.tableCellBig}>{repairEstimate?.reg_id ?? repairEstimate?.name}</Text>
								<Text style={styles.tableCell}>{repairEstimate?.date}</Text>
								<Text style={styles.tableCellBigEnd}>{repairEstimate?.denumire}</Text>
								<Text style={styles.tableCell}>{repairEstimate?.pret}</Text>
								<Text style={styles.tableCellBigEnd}>{repairEstimate?.infos}</Text>
							</View>
						</View>
					</View>
				)}
			</Page>
		</Document>
	</>
}

export default RepairEstimateTemplate;