import { Close } from '@mui/icons-material';
import { Dialog, DialogTitle, IconButton, useMediaQuery, useTheme } from '@mui/material';
import { FC, SetStateAction } from 'react';
import CreateRepairEstimateForm, { CategoryOption } from '../../components/forms/CreateRepairEstimateForm';

interface Props {
	createAutoFleetDialogOpen: boolean
	setCreateAutoFleetDialogOpen: (action: SetStateAction<boolean>) => void
	categoryOptions: CategoryOption[]
	onAddRepairEstimateSuccess: (message: string) => void
}

const CreateRepairEstimateDialog: FC<Props> = ({createAutoFleetDialogOpen, setCreateAutoFleetDialogOpen, categoryOptions, onAddRepairEstimateSuccess}) => {
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

	return <>
		<Dialog
			fullScreen={fullScreen}
			open={createAutoFleetDialogOpen}
			fullWidth
			maxWidth='lg'>
			<DialogTitle
				id='create-repair-estimate-dialog'
				sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
				Adauga deviz
				<IconButton
					edge='end'
					color='inherit'
					onClick={() => setCreateAutoFleetDialogOpen(false)}
					aria-label='close'
					sx={{ justifySelf: 'end' }}>
					<Close />
				</IconButton>
			</DialogTitle>
			<DialogTitle sx={{ height: '100%' }}>
				<CreateRepairEstimateForm categoryOptions={categoryOptions} onAddSuccess={onAddRepairEstimateSuccess} />
			</DialogTitle>
		</Dialog>
	</>
}

export default CreateRepairEstimateDialog;