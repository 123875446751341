import List from "@mui/material/List";
import {Box, Button, ListItem} from "@mui/material";
import {useState} from "react";

interface Props {
  onMenuItemClick: (selected: string) => void;
  menuItems: { label: string, value: string }[];
  children: JSX.Element;
}

const HorizontalMenu = (props: Props) => {
  const [selected, setSelected] = useState<string>('');

  const onMenuItemClick = (row: any) => {
    setSelected(row.value);
    props.onMenuItemClick(row.value);
  }

  return (
    <>
      <List role='menu' sx={{display: 'flex', flexDirection: {xs: 'column', md: 'row'}}}>
        {props.menuItems.map((menuItem: any) => (
          <ListItem sx={{width: '100%'}} key={menuItem.value}>
            <Button sx={{width: '100%'}} variant={selected === menuItem.value ? 'contained' : 'text'}
                    onClick={(ev: any) => onMenuItemClick(menuItem)}>{menuItem.label}</Button>
          </ListItem>
        ))}
      </List>
      <Box sx={{width: '100%', mx: 2}}>
        {props.children}
      </Box>
    </>
  )
}

export default HorizontalMenu;