export const InvoiceProductTableHeaders = [
  {
    id: "product_name",
    label: "Name",
    width: 100
  },
  {
    id: "quantity",
    label: "Cantitate",
    width: 100,
  },
  {
    id: "purchase_price",
    label: "Pret achizitie",
    width: 100,
  },
  {
    id: "vat",
    label: "TVA",
    width: 100,
  },
  {
    id: "created_at_utc",
    label: "Creat",
    width: 250,
    type: "date",
  },
];
