import {Accordion, AccordionDetails, AccordionSummary, Box, TextField, Typography} from "@mui/material";
import {FC, ReactElement, useState} from "react";
import {Controller, useForm} from "react-hook-form";
import {CreatePartnerDto} from "../../types/CreatePartnerType";
import Button from "../Button";
import CheckboxFormControl from "../form-helpers/CheckboxFormControl";
import SelectFormControl from "../form-helpers/SelectFormControl";

interface Props {
  onPartnerInput: (partner: any) => void;
}

interface ApiFetchedDataType {
  name: string,
  trrn: string,
  vat_payer: boolean,
  vat_collection: boolean,
  county: string,
  address: string,
}

const CreatePartnerForm: FC<Props> = (props): ReactElement => {
  const [tin, setTin] = useState<string>("");

  const {
    register,
    handleSubmit,
    formState: {errors},
    control,
    setValue,
  } = useForm();

  const onPartnerInput = (data: any) => {
    const objToSend: CreatePartnerDto = {
      address_point: {
        address: data.address_point,
        county: data.county,
        city: data.city,
        country: data.country,
        nickname: data.nickname
      },
      contact: {
        car_registration_number: data.car_registration_number,
        contact_email: data.contact_email,
        department: data.department,
        first_name: data.first_name,
        last_name: data.last_name,
        personal_identification_number: data.personal_identification_number,
        phone: data.phone
      },
      bank_account: {
        iban: data.iban,
        bank_name: data.bank_name,
        currency: data.currency
      },
      credit: Number(data.credit),
      credit_exceedance_percentage: Number(data.credit_exceedance_percentage),
      invoice_deadline_days: Number(data.invoice_deadline_days),
      name: data.name,
      remaining_credit: data.remaining_credit,
      trade_register_registration_number: data.trade_register_registration_number,
      unique_identification_number: data.unique_identification_number,
      vat_collection: data.vat_collection || false,
      vat_payer: data.vat_payer || false,
      vat_split: data.vat_split || false,
      address: data.address,
      is_user_partner: data.is_user_partner,
      type: data.type
    }

    props.onPartnerInput(objToSend);
  }

  const getPartnerInformationByTin = async () => {
    if (tin) {
      try {
        const response = await (await fetch(`https://api.openapi.ro/api/companies/${tin}`, {
          headers: {
            "x-api-key": "VzAEBCXbsz_qzkw1xny3Ny9WjvQCzxbXzJrjvA3qf75hDRsWfg"
          }
        }))?.json();

        let vatCollection = false;

        if (response.tva_la_incasare.length) {
          vatCollection = !response.tva_la_incasare.some((element: any) => element.tip === 'D')
        }

        setValue("name", response.denumire.toUpperCase());
        setValue("trade_register_registration_number", response.numar_reg_com.toUpperCase());
        setValue("vat_payer", !!response.tva.toUpperCase());
        setValue("vat_collection", vatCollection);
        setValue("county", response.judet.toUpperCase());
        setValue("address", response.adresa.split(',').slice(0, 2).join('').toUpperCase());
      } catch (err) {
        console.log(err);
      }
    }
  }

  const onTinChange = (ev: any) => {
    setTin(ev.target.value);
  }

  return (
    <form onSubmit={handleSubmit(onPartnerInput)}>
      <Box sx={{display: 'flex', alignIetms: 'center', width: '100%', justifyContent: 'center'}}>
        <TextField
          fullWidth
          sx={{my: '1rem'}}
          label='CUI'
          helperText={errors.tin ? 'CUI-ul este necesar' : ''}
          error={!!errors.tin}
          inputProps={{...register('unique_identification_number', {required: true})}}
          onChange={onTinChange}
        />

        <Button sx={{ml: '1rem', my: '16px'}} disabled={!tin} title="Cauta CUI" onClick={getPartnerInformationByTin}/>
      </Box>

      <Controller
        name="name"
        control={control}
        defaultValue=""
        rules={{required: true}}
        render={({field}) => (
          <TextField
            {...field}
            sx={{width: '100%', my: '1rem'}}
            label='Nume'
            helperText={errors.name ? 'Numele este necesar' : ''}
            error={!!errors.name}
          />
        )}
      />

      <Controller
        name='trade_register_registration_number'
        control={control}
        defaultValue=""
        rules={{required: true}}
        render={({field}) => (
          <TextField
            {...field}
            sx={{width: '100%', my: '1rem'}}
            label='Numar registru comertului'
            helperText={errors.trade_register_registration_number ? 'Numarul registru comertului este necesar' : ''}
            error={!!errors.trade_register_registration_number}/>
        )}
      />

      <Controller
        name='address'
        control={control}
        defaultValue=""
        rules={{required: true}}
        render={({field}) => (
          <TextField
            {...field}
            sx={{width: '100%', my: '1rem'}}
            label='Addresa'
            helperText={errors.address ? 'Addresa necesara' : ''}
            error={!!errors.address}/>
        )}
      />

      <Controller
        name="credit"
        defaultValue=""
        control={control}
        rules={{pattern: /[0-9]/}}
        render={({field}) => (
          <TextField
            {...field}
            sx={{width: '100%', my: '1rem'}}
            label='Credit'
            helperText={errors.credit ? 'Creditul trebuie sa fie un numar' : ''}
            error={!!errors.credit}
          />
        )}
      />

      <Controller
        name="credit_exceedance_percentage"
        defaultValue=""
        control={control}
        rules={{pattern: /\b([0-9]|[1-9][0-9]|100)\b/}}
        render={({field}) => (
          <TextField
            {...field}
            sx={{width: '100%', my: '1rem'}}
            label='Procent credit'
            helperText={errors.credit_exceedance_percentage ? 'Valoarea trebuie as fie un numar nu mai mare de 100' : ''}
            error={!!errors.credit_exceedance_percentage}
          />
        )}
      />

      <Box sx={{display: 'flex'}}>
        <CheckboxFormControl control={control} name='vat_payer' displayText='Platitor TVA'/>
        <CheckboxFormControl control={control} name='vat_split' displayText='Split TVA'/>
        <CheckboxFormControl control={control} name='vat_collection' displayText='TVA la incasare'/>
      </Box>


      <Controller
        name="invoice_deadline_days"
        defaultValue=""
        control={control}
        rules={{pattern: /[0-9]/}}
        render={({field}) => (
          <TextField
            {...field}
            sx={{width: '100%', my: '1rem'}}
            label='Termen de plata (zile)'
            helperText={errors.invoice_deadline_days ? 'Termenul de plata trebuie sa fie un numar' : ''}
            error={!!errors.invoice_deadline_days}
          />
        )}
      />

      <Box sx={{display: 'flex'}}>
        <SelectFormControl control={control} name='type' selectOptions={
          [{label: 'Client', value: 'Client'}, {label: 'Prospect', value: 'Prospect'}, {
            label: 'Transportator',
            value: 'Transportator'
          }]
        } displayText='Tip partener'/>

      </Box>

      <Accordion
        sx={{my: 2}}
      >
        <AccordionSummary>
          <Typography sx={{width: '33%', flexShrink: 0}}>Addresa</Typography>
          <Typography color='text.secondary'>Adauga o adresa acestui partner</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Controller
            name="county"
            defaultValue=""
            control={control}
            render={({field}) => (
              <TextField
                {...field}
                sx={{width: '100%', my: '1rem'}}
                label='Judet'
              />
            )}
          />
          <TextField
            sx={{width: '100%', my: '1rem'}}
            label='Tara'
            inputProps={{...register('country')}}
          />
          <TextField
            sx={{width: '100%', my: '1rem'}}
            label='Oras'
            inputProps={{...register('city')}}
          />
          <Controller
            name="address_point"
            defaultValue=""
            control={control}
            render={({field}) => (
              <TextField
                {...field}
                sx={{width: '100%', my: '1rem'}}
                label='Addresa'
              />
            )}
          />
          <Controller
            name="nickname"
            defaultValue=""
            control={control}
            render={({field}) => (
              <TextField
                {...field}
                sx={{width: '100%', my: '1rem'}}
                label='Nickname'
              />
            )}
          />

        </AccordionDetails>
      </Accordion>

      <Accordion sx={{my: 2}}>
        <AccordionSummary>
          <Typography sx={{width: '33%', flexShrink: 0}}>Cont Bancar</Typography>
          <Typography color='text.secondary'>Adauga un cont bancar acestui partner</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Controller
            name="iban"
            defaultValue=""
            control={control}
            render={({field}) => (
              <TextField
                {...field}
                sx={{width: '100%', my: '1rem'}}
                label='IBAN'
              />
            )}
          />
          <Controller
            name="bank_name"
            defaultValue=""
            control={control}
            render={({field}) => (
              <TextField
                {...field}
                sx={{width: '100%', my: '1rem'}}
                label='Nume banca'
              />
            )}
          />
          <Controller
            name="currency"
            defaultValue=""
            control={control}
            render={({field}) => (
              <TextField
                {...field}
                sx={{width: '100%', my: '1rem'}}
                label='Moneda'
              />
            )}
          />
        </AccordionDetails>
      </Accordion>

      <Accordion sx={{my: 2}}>
        <AccordionSummary>
          <Typography sx={{width: '33%', flexShrink: 0}}>Persoana de contact</Typography>
          <Typography color='text.secondary'>Adauga o persoana de contact acestui partner</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Controller
            name="first_name"
            defaultValue=""
            control={control}
            render={({field}) => (
              <TextField
                {...field}
                sx={{width: '100%', my: '1rem'}}
                label='Nume'
              />
            )}
          />
          <Controller
            name="last_name"
            defaultValue=""
            control={control}
            render={({field}) => (
              <TextField
                {...field}
                sx={{width: '100%', my: '1rem'}}
                label='Prenume'
              />
            )}
          />
          <Controller
            name="personal_identification_number"
            defaultValue=""
            control={control}
            render={({field}) => (
              <TextField
                {...field}
                sx={{width: '100%', my: '1rem'}}
                label='CNP'
              />
            )}
          />
          <Controller
            name="car_registration_number"
            defaultValue=""
            control={control}
            render={({field}) => (
              <TextField
                {...field}
                sx={{width: '100%', my: '1rem'}}
                label='Numar inmatriculare'
              />
            )}
          />
          <Controller
            name="department"
            defaultValue=""
            control={control}
            render={({field}) => (
              <TextField
                {...field}
                sx={{width: '100%', my: '1rem'}}
                label='Departament'
              />
            )}
          />
          <Controller
            name="phone"
            defaultValue=""
            control={control}
            render={({field}) => (
              <TextField
                {...field}
                sx={{width: '100%', my: '1rem'}}
                label='Telefon'
              />
            )}
          />
          <Controller
            name="contact_email"
            defaultValue=""
            control={control}
            rules={{pattern: /^\S+@\S+$/i}}
            render={({field}) => (
              <TextField
                {...field}
                sx={{width: '100%', my: '1rem'}}
                label='Email'
                helperText={errors.contact_email ? 'Aresa de email invalida' : ''}
                error={!!errors.contact_email}
              />
            )}
          />

        </AccordionDetails>
      </Accordion>

      <Button color='primary' sx={{width: '100%', mt: '1rem'}} type='submit' title='Submit'/>

    </form>
  )
}

export default CreatePartnerForm;