import {Container} from "@mui/material";
import React, {useEffect, useState} from "react";
import DeleteConfirmationDialog from "../../components/DeleteConfirmationDialog";
import {BankRegisterTableConstants} from "./BankRegisterTableConstants";
import {Link, useLocation, useNavigate} from "react-router-dom";
import * as api from "../../api/api-client";
import {toast} from "react-toastify";
import Table from "../../components/table/Table";
import DialogContainer from "../../components/DialogContainer";
import {FieldValues} from "react-hook-form";
import CreateBankRegisterForm from "../../components/forms/CreateBankRegisterForm";
import {FilterSettings} from "../../types/FilterTypes";
import {KeyValuePair} from "../../types/CommonTypes";
import Filters from "../../components/filters/Filters";

const BankRegisterPage = () => {
  const [bankRegisters, setBankRegisters] = useState<any>([]);
  const [createBankRegisterDialogOpen, setCreateBankRegisterDialogOpen] = useState(false);
  const [rowDeleteRequested, setRowDeleteRequested] = useState<any>(undefined);
  const [filters, setFilters] = useState<KeyValuePair>({} as KeyValuePair);
  const [queryParams, setQueryParams] = useState<string>('');

  const actions: any[] = [
    // {
    //     name: 'deleteBankRegisterRow',
    //     displayName: 'Delete row',
    //     action: handleDeleteRowRequest
    // }
  ];

  const navigate = useNavigate();

  const filterable: FilterSettings[] = [
    {
      key: 'iban',
      display: 'IBAN',
      type: 'text',
    },
    {
      key: 'currency',
      display: 'Valuta',
      type: 'dropdown',
      dropdownValues: [
        {label: 'RON', value: 'RON'},
        {label: 'EUR', value: 'EUR'}
      ]
    },
  ]

  const location = useLocation();

  useEffect(() => {
    if (queryParams !== location.search) {
      setQueryParams(location.search);
    }
  }, [location]);

  useEffect(() => {
    api.get(`/bankregister${queryParams}`).then(res => {
      setBankRegisters(res.map((register: any) => ({
        ...register,
        partner: register.partner.name
      })));
    }).catch(err => {
      toast.error(err);
    })
  }, [queryParams])

  useEffect(() => {
    if (filters) {
      navigate(`?${new URLSearchParams(filters).toString()}`);
    }
  }, [filters])

  const formatCell = (row: any, value: string) => {
    return <Link to={`${row.bank_register_id}`}>{value}</Link>
  }

  function handleDeleteRowRequest(row: any) {
    setRowDeleteRequested(row);
  }

  const rowDeleteCanceled = (row: any) => {
    setRowDeleteRequested(null);
  }

  const handleDeleteRow = () => {
    api.remove(`/bankregister/${rowDeleteRequested.bank_register_id}`).then((res) => {
      toast.success(res.message);
      setRowDeleteRequested(undefined);
      api.get(`/bankregister`).then((res: any) => {
        setBankRegisters(res.map((register: any) => ({
          ...register,
          partner: register.partner.name
        })));
      })
    }).catch((err) => {
      toast.error(err);
    })
  }

  const handleBankAccountCreate = (data: FieldValues) => {
    api.post("/bankregister", data).then(res => {
      setCreateBankRegisterDialogOpen(false);
      toast.success(res.message);

      api.get("/bankregister").then(res => {
        setBankRegisters(res);
      }).catch(err => {
        toast.error(err);
      });
    }).catch(err => {
      toast.error(err.message);
    });
  }

  const onFilterSubmit = (filters: KeyValuePair) => {
    setFilters(filters);
  }

  return <Container sx={{height: '100%', margin: 0, marginTop: '3rem', maxWidth: '100vw !important'}}>
    <Filters filters={filters} filtersSettings={filterable} onFiltersSubmit={onFilterSubmit}/>
    <Table
      headers={BankRegisterTableConstants}
      rows={bankRegisters}
      getFormattedCell={formatCell}
      handleAddActionClick={() => setCreateBankRegisterDialogOpen(true)}
      actions={actions}
    />

    {rowDeleteRequested &&
        <DeleteConfirmationDialog rowDeleteRequested={rowDeleteRequested}
                                  deleteConfirmationText={rowDeleteRequested.bank_register_id}
                                  handleDeleteRow={handleDeleteRow} rowDeleteCanceled={rowDeleteCanceled}/>
    }

    <DialogContainer
      dialogTitle="Adauga registru cont bancar"
      isDialogOpen={createBankRegisterDialogOpen}
      handleDialogState={setCreateBankRegisterDialogOpen}
    >
      <CreateBankRegisterForm onBankRegisterSubmit={handleBankAccountCreate}/>
    </DialogContainer>
  </Container>;
};

export default BankRegisterPage;