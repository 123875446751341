import {FieldValues, useForm} from "react-hook-form";
import {Box, Button, useMediaQuery, useTheme} from "@mui/material";
import AutocompleteFormControl from "../form-helpers/AutocompleteFormControl";
import {useEffect, useState} from "react";
import {toast} from "react-toastify";
import * as api from "../../api/api-client";
import BasicFormControl from "../form-helpers/BasicFormControl";
import SelectFormControl from "../form-helpers/SelectFormControl";

interface Props {
  onNoticeInput: (data: FieldValues) => void;
}

const CreateNoticeForm = (props: Props) => {
  const [vehicles, setVehicles] = useState<any>([]);
  const [vehiclesLoaded, setVehiclesLoaded] = useState<boolean>(false);
  const [defaultVehicle, setDefaultVehicle] = useState<any>({});
  const [addresses, setAddresses] = useState<any>([]);
  const [invoiceProducts, setInvoiceProducts] = useState<any[]>([]);
  const [userVehicle, setUserVehicle] = useState<any>({});
  const [products, setProducts] = useState<any>([]);
  const [currentProductQuantity, setCurrentProductQuantity] = useState<string>('');
  const [newAddress, setNewAddress] = useState<boolean>(false);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const productHeaders = [
    {
      id: 'product_name',
      width: 100,
      label: 'Nume',
    },
    {
      id: 'quantity',
      width: 100,
      label: 'Cantitate',
    },
  ];

  const {
    handleSubmit,
    formState: {errors},
    control,
    setValue,
    getValues,
    reset,
    watch
  } = useForm({
    defaultValues: {
      reg_no: '',
      pickup_address: '',
      drop_off_address: '',
      product_name: '',
      quantity: '',
      product_id: '',
      unit_of_measure: 'TO'
    }
  });

  const pickUpAddressControl = watch('pickup_address');
  const dropOffAddressControl = watch('drop_off_address');

  useEffect(() => {
    if (newAddress) {
      toast.info('O adresa noua a fost creata');
    }
  }, [pickUpAddressControl, dropOffAddressControl]);

  useEffect(() => {
    api.get('/user/vehicle').then((res) => {
      if (res) {
        setVehicles([res]);
      }
    }).catch((err) => {
      toast.error(err.message);
    }).finally(() => {
      setVehiclesLoaded(true);
    });
  }, []);

  useEffect(() => {
    if (vehicles && vehicles.length > 0) {

      reset({
        reg_no: vehicles[0]?.reg_no
      });

    }
  }, [vehicles])


  const onNoticeInput = (data: FieldValues) => {
    data.type = 'notice';
    data.status = 'unpaid';
    data.currency = 'RON';

    props.onNoticeInput(data);
  }

  const onAddressInput = (event: any) => {
    const {value} = event.target;

    if (value.length >= 3) {
      api.get(`/partners/address/autocomplete?searchKey=${value}`).then((res) => {

        if (res.length === 0) {
          setAddresses([{nickname: value}])

        } else {
          setAddresses(res);
        }

      }).catch((err) => {
        toast.error(err);
      });
    }
  }

  const onVehicleInput = (event: any) => {
    const {value} = event.target;

    if (value.length >= 3) {
      api.get('/autofleet/options').then((res) => {
        setVehicles(res);
      }).catch((err) => {
        toast.error(err.message);
      });
    }
  }

  const onVehicleChange = (event: any, value: any) => {
    setValue('reg_no', value?.reg_no);
  }

  const onPickupChange = (event: any, value: any) => {
    setValue('pickup_address', value);
    setNewAddress(true);
  }

  const onDropoffChange = (event: any, value: any) => {
    setValue('drop_off_address', value);
    setNewAddress(true);
  }

  const onProductAutocompleteInput = (event: any) => {
    const {value} = event.target;

    if (value.length >= 3) {
      api.get(`/products?product_name=${value}`).then((res) => {
        setProducts(res);
      }).catch((err) => {
        toast(err, {type: 'error'})
      });
    }
  }

  const onProductAutocompleteChange = (event: any, value: any) => {
    if (!value) return;

    setValue('product_id', value?.product_id);
    setValue('product_name', value?.product_name);
    setCurrentProductQuantity(value?.quantity.toString());
  }

  // const onInvoiceProductSubmit = (data: FieldValues) => {
  //
  //   const existingProduct = products.find((product: any) => product.product_name === data.product_name);
  //
  //   if (existingProduct) {
  //     existingProduct.quantity = Number(existingProduct.quantity) + Number(data.quantity);
  //
  //     setInvoiceProducts(products);
  //   } else {
  //     setInvoiceProducts((prevState: any) => {
  //       return [...prevState, {
  //         product_name: data.product_name,
  //         quantity: data.quantity,
  //         product_id: data.product.product_id
  //       }]
  //     })
  //   }
  //
  //   addInvoiceDialogState(false);
  // }

  const onAddressBlur = (event: any) => {
    const {name, value} = event.target;

    if (addresses.length <= 0) {
      setValue(name, {nickname: value});
    }
  }

  return (
    <>
      <form onSubmit={handleSubmit(onNoticeInput)}>
        <Box sx={{display: "flex", flexDirection: "column", width: '100%'}}>
          <Box sx={{display: 'flex'}}>
            {vehiclesLoaded &&
                <AutocompleteFormControl control={control}
                                         name='reg_no'
                                         displayText='Numar de inmatriculare'
                                         options={vehicles}
                                         onChangeHandler={onVehicleChange}
                                         onInputHandler={onVehicleInput}
                                         optionLabelFunc={(vehicles) => vehicles?.reg_no || ""}
                                         errors={errors}
                                         rules={{
                                           required: {value: true, message: 'Campul este obligatoriu'}
                                         }}
                                         defaultValue={{
                                           reg_no: vehicles?.[0]?.reg_no || ''
                                         }}
                />
            }
          </Box>
          <Box sx={{display: 'flex', flexDirection: {xs: 'column', md: 'row'}}}>
            <AutocompleteFormControl control={control}
                                     name='pickup_address'
                                     displayText='Locatie preluare'
                                     options={addresses}
                                     onChangeHandler={onPickupChange}
                                     onInputHandler={onAddressInput}
                                     onBlurHandler={onAddressBlur}
                                     optionLabelFunc={(addresses) => addresses?.nickname || ""}
                                     errors={errors}
                                     rules={{
                                       required: {value: true, message: 'Campul este obligatoriu'}
                                     }}
            />
            <AutocompleteFormControl control={control}
                                     name='drop_off_address'
                                     displayText='Locatie livrare'
                                     options={addresses}
                                     onChangeHandler={onDropoffChange}
                                     onInputHandler={onAddressInput}
                                     optionLabelFunc={(addresses) => addresses?.nickname || ""}
                                     errors={errors}
                                     rules={{
                                       required: {value: true, message: 'Campul este obligatoriu'}
                                     }}
            />
          </Box>

          <Box sx={{display: 'flex', flexDirection: {xs: 'column', md: 'row'}}}>
            <AutocompleteFormControl control={control}
                                     name='product_name'
                                     displayText='Nume produs'
                                     options={products}
                                     optionLabelFunc={(products) => products?.product_name || ''}
                                     onInputHandler={onProductAutocompleteInput}
                                     onChangeHandler={onProductAutocompleteChange}
                                     errors={errors}
                                     rules={{
                                       required: {value: true, message: 'Campul este obligatoriu'}
                                     }}
            />

            <BasicFormControl control={control} name='quantity' displayText='Cantitate'
                              hint={`IN STOC: ${currentProductQuantity || '-'}` || ''}
                              errors={errors}
                              rules={{
                                required: {value: true, message: 'Campul este obligatoriu'}
                              }}
            />

            <SelectFormControl sx={{width: '100%', marginTop: '0.5rem'}} control={control} name='unit_of_measure'
                               selectOptions={[
                                 {label: 'TO', value: 'TO'},
                                 {label: 'MC', value: 'MC'}
                               ]} defaultValue={{label: 'TO', value: 'TO'}} displayText='U.M.'/>
          </Box>
          {/*<Box sx={{display: 'flex', flexDirection: 'column', width: '100%', mt: '2rem'}}>*/}
          {/*  <Typography variant='h6'>Product list</Typography>*/}
          {/*  <Table headers={productHeaders} rows={productRows} handleAddActionClick={openAddInvoiceProductDialog}/>*/}
          {/*</Box>*/}

          <Button type='submit' variant='contained' sx={{mt: '2rem'}}>Submit</Button>
        </Box>
      </form>

      {/*<DialogContainer isDialogOpen={addActionDialogOpen} dialogTitle='Adauga produs'*/}
      {/*                 handleDialogState={addInvoiceDialogState}>*/}
      {/*  <AddNoticeProductForm onSubmit={onInvoiceProductSubmit}/>*/}
      {/*</DialogContainer>*/}
    </>
  )

}

export default CreateNoticeForm;