const getMenuOptionFromStr = (str: string) => ({label: str, value: str});

const autofleetServiceList = [
	'Leasing',
	'Casco',
	'RCA',
	'Diurna Sofer',
	'RO vigneta',
	'Hu vigneta',
	'Cauciucuri',
	'Autostrăzi',
	'Asigurare CMR',
	'Revizii'
];

const srlAutoServiceList = [
	'Chirie',
	'Întreținere',
	'Digi',
	'Asibox',
	'Curieri',
	'CargoTrack',
	'Depozite',
	'Bugetul de stat',
	'Contribuții asig muncă',
	'TVA',
	'Burse',
	'Salarii angajați',
	'Bonuri',
	'Consumabile',
];

export const otherExpensesOption = getMenuOptionFromStr('Alte cheltuieli');

export const serviceLists = {
	autofleet: autofleetServiceList?.map(getMenuOptionFromStr),
	partner: srlAutoServiceList?.map(getMenuOptionFromStr),
};

export const currencyOptions = ['RON', 'EUR'].map(getMenuOptionFromStr); 

export const defaultValues = {
	auto_fleet_id: '',
	partner_id: '',
	deviz_id: '',
	date: '',
	denumire: '',
	infos: '',
	pret_fara_tva: '',
	cota_tva: '19',
	currency: 'RON'
  };
