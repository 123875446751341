export const DeliveryTableHeaders = [
    {
        id: 'type',
        label: 'Tip',
        width: 100
      },
      {
        id: 'county',
        label: 'Judet',
        width: 200
      },
      {
        id: 'location',
        label: 'Oras/Cod/Tara',
        width: 100
      },
      {
        id: 'date_from',
        label: 'De la',
        type: 'date',
        width: 100
      },
      {
        id: 'date_to',
        label: 'La',
        type: 'date',
        width: 100
      },
      {
        id: 'metri_podea_patrati',
        label: 'Metri patrati',
        type: 'number',
        width: 100
      },

  ]
  
  export const OrderDeliveryInformation = [
  
  ]
  
  