import React, {useEffect, useState} from "react";
import {
    Box,
    Container,
    Toolbar
} from "@mui/material";
import {useForm} from "react-hook-form";
import BasicFormControl from "../../components/form-helpers/BasicFormControl";
import {useParams} from "react-router-dom";

import * as api from "../../api/api-client";
import {toast} from "react-toastify";
import {OperationsTableConstants} from "../cash-register/OperationsTableConstants";
import Table from "../../components/table/Table";
import Filters from "../../components/filters/Filters";

const BankRegisterDetailsPage = () => {
    const [loading, setLoading] = useState(true);
    const [operations, setOperations] = useState<any>([]);

    const {
        control,
        reset
    } = useForm({
        defaultValues: {
            iban: "",
            partner: "",
            balance: "",
            currency: ""
        }
    });

    const {id} = useParams();

    useEffect(() => {
        api.get(`/bankregister/${id}`).then((res: any) => {
            reset({
                iban: res?.iban,
                partner: res?.partner.name,
                balance: res?.balance,
                currency: res?.currency
            })

            setLoading(false);
        }).catch((err) => {
            toast.error(err);
        });

        api.get(`/bankregister/op/${id}`).then(res => {
            const mappedOperations = res.map((op: any) => ({
                ...op,
                buyer_partner: op.buyer_partner.name,
                seller_partner: op.seller_partner.name
            }));

            setOperations(mappedOperations);
        }).catch(err => {
            toast.error(err);
        });
    }, []);

    return (
        <Container sx={{height: '100%', margin: 0, marginTop: '3rem', maxWidth: '100vw !important'}}>
            <Toolbar sx={{width: '100%', paddingLeft: '0px !important', paddingRight: '0px !important'}}>

            </Toolbar>
            {!loading && <Box>
                <Box display="flex" width={"100%"}>
                    <BasicFormControl control={control} disabled displayText="IBAN" name="iban" />
                    <BasicFormControl control={control} disabled displayText="Partener" name="partner" />
                </Box>
                <Box display="flex" width={"100%"}>
                    <BasicFormControl control={control} disabled displayText="Sold" name="balance" />
                    <BasicFormControl control={control} disabled displayText="Valuta" name="currency" />
                </Box>
            </Box>}

            <Table headers={OperationsTableConstants} rows={operations} />
        </Container>
    )
}

export default BankRegisterDetailsPage;