import KarimSignature from "../../assets/karim.jpg";
import KarimLogo from "../../assets/logo-karim.png";
import {Document, Image, Page, StyleSheet, Text, View} from "@react-pdf/renderer";
import {reversePercentage} from "../../utils/price";

interface Props {
  invoiceDetails: any;
}

const styles
  = StyleSheet.create({
  borderText: {},
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '90%',
    marginHorizontal: 'auto',
    marginTop: 20,
    border: '5px solid gray',
    paddingVertical: 5
  },
  textNormal: {
    paddingVertical: 3,
    fontSize: 10,
    fontWeight: "thin",
    fontFamily: "Roboto"
  },
  textNormalNoPadding: {
    fontSize: 10,
    fontWeight: "thin",
    fontFamily: "Roboto"
  },
  textBoldNoPadding: {
    fontSize: 10,
    fontWeight: 800,
    fontFamily: 'Roboto-Bold',
  },
  borderTextBold: {
    fontSize: 10,
    fontWeight: 800,
    fontFamily: 'Roboto-Bold',
    paddingVertical: 1
  },
  logo: {
    display: 'flex', justifyContent: 'center', alignItems: 'center', width: '27%'
  },
  flex: {
    display: 'flex'
  },
  signature: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '15%',
    marginLeft: 20,
    marginTop: 20,
    marginBottom: 20
  },
  borderColumn: {
    width: '40%', border: '0.5px solid black'
  },
  borderValue: {
    width: '60%',
    border: '0.5px solid black'
  },
  title: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    marginTop: 10,
    marginBottom: 10
  },
  titleText: {
    fontFamily: 'Times-Bold',
    fontWeight: 'bold',
    fontSize: 20
  },
  clientInformation: {
    flexDirection: 'column',
    width: '90%',
    marginTop: 10,
    marginBottom: 20,
    marginHorizontal: 'auto'
  },
  pageContainer: {
    maxHeight: '100%',
    flexDirection: 'column',
    width: '90%',
    paddingTop: 20,
    paddingBottom: 120
  },
  centered: {},
  truckDetails: {
    display: 'flex',
    flexDirection: 'column',
    width: '90%',
    marginVertical: 15,
    marginHorizontal: 'auto',
    flexGrow: 0.5
  },
  transportInfo: {
    display: 'flex',
    flexDirection: 'column',
    width: '90%',
    marginLeft: 30,
    marginRight: 10,
  },
  footerSignature: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '90%',
    marginTop: 10,
    marginBottom: 10,
    marginLeft: 10,
    marginRight: 10,
  },
  importantText: {width: '90%', marginLeft: 30, marginVertical: 10},
  footerContainer: {width: '90%', margin: 20, flexGrow: 1}
});

const TmsTransportTemplate = (props: Props) => {
  const dateDiff = (date_from: string, date_to: string) => {
    // return new Date(date_fro)
    const parsedFrom = new Date(date_from).setHours(0, 0, 0, 0);
    const parsedTo = new Date(date_to).setHours(0, 0, 0, 0);

    return parsedFrom.valueOf() !== parsedTo.valueOf();
  }

  return (
    <Document style={{display: 'none'}}>
      <Page size='A4' wrap style={styles.pageContainer}>

        <View style={styles.header}>

          <Image style={{width: '30%', height: '100%', margin: 'auto'}} src={KarimLogo}/>

          <View style={{marginLeft: 10, width: '50%'}}>
            <Text style={styles.textBoldNoPadding}>Karim Spedition Logistik S.R.L.</Text>
            <View style={styles.flex}>
              <Text style={styles.textBoldNoPadding}>CUI:RO 42581537, J5/845/2020</Text>
            </View>
            <Text style={styles.textBoldNoPadding}>Strada Sovata 35, PB15, ap.4, Oradea, Bihor,
              Romania</Text>
            <View style={styles.flex}>
              <Text style={styles.textBoldNoPadding}>Mobil:+40757574981 / +40751325311 </Text>
            </View>
            <View style={styles.flex}>
              <Text style={styles.textBoldNoPadding}>e-mail: office@karim-spedition.com</Text>
            </View>
          </View>

        </View>

        <View style={styles.title}>
          <Text style={styles.titleText}>Comanda de transport
            nr. {props.invoiceDetails?.series}-{props.invoiceDetails?.number} din {new Date(props.invoiceDetails?.created_at_utc).toLocaleDateString('ro-RO')}</Text>
        </View>

        <View style={styles.clientInformation}>
          <View style={{display: 'flex', flexDirection: 'row'}}>
            <View style={styles.borderColumn}>
              <Text style={styles.borderTextBold}>Transportator:</Text>
            </View>
            <View style={styles.borderValue}>
              <Text style={styles.textNormal}>
                {props?.invoiceDetails?.transporter?.name}
              </Text>
            </View>
          </View>
          <View style={{display: 'flex', flexDirection: 'row'}}>
            <View style={styles.borderColumn}>
              <Text style={styles.borderTextBold}>CUI
                :</Text>
            </View>
            <View style={styles.borderValue}>
              <Text style={styles.textNormal}>
                {props?.invoiceDetails?.transporter?.unique_identification_number}
              </Text>
            </View>
          </View>
          <View style={{display: 'flex', flexDirection: 'row'}}>
            <View style={styles.borderColumn}>
              <Text style={styles.borderTextBold}>ADRESA:</Text>
            </View>
            <View style={styles.borderValue}>
              <Text style={styles.textNormal}>
                {props?.invoiceDetails?.transporter?.address}
              </Text>
            </View>
          </View>
          <View style={{display: 'flex', flexDirection: 'row'}}>
            <View style={styles.borderColumn}>
              <Text style={styles.borderTextBold}>PERSOANA DE CONTACT:</Text>
            </View>
            <View style={styles.borderValue}>
              <Text
                style={styles.textNormal}>{`${props?.invoiceDetails?.transporter_contact || '-'}`}
              </Text>
            </View>
          </View>
        </View>

        <View style={styles.transportInfo}>
          <Text style={{
            fontFamily: 'Roboto',
            fontSize: 12
          }}>DETALII CAMION / MARFA</Text>

          <Text style={styles.textBoldNoPadding}>Marfa : {props.invoiceDetails?.package_info}</Text>
          <Text style={styles.textBoldNoPadding}>Nr. auto
            : {props?.invoiceDetails?.car_reg_number || '-'}</Text>
          <Text style={styles.textBoldNoPadding}>Sofer / Tel.
            Sofer: {props?.invoiceDetails?.driver_info || '-'}</Text>
          <Text
            style={styles.textBoldNoPadding}>Observatii: {props?.invoiceDetails?.remarks_transporter}</Text>
        </View>

        {props?.invoiceDetails?.OrderDetails?.map((orderDetailsItem: any) => (
          <>
            {orderDetailsItem.type === 'PICKUP' &&
                <View style={styles.truckDetails} wrap={false}>
                    <Text style={{
                      fontFamily: 'Roboto',
                      fontSize: 16,
                      marginLeft: 30
                    }}>Incarcare</Text>
                    <View style={{display: 'flex', flexDirection: 'row'}}>
                        <View style={styles.borderColumn}>
                            <Text style={styles.borderTextBold}>Data
                                / Ora
                                :</Text>
                        </View>
                        <View style={styles.borderValue}>
                            <Text style={styles.textNormal}>
                              {`${new Date(orderDetailsItem.date_from).toLocaleDateString('RO-ro')}${dateDiff(orderDetailsItem.date_from, orderDetailsItem.date_to) ? `/ ${new Date(orderDetailsItem.date_to).toLocaleDateString('RO-ro')}` : ''} `}
                            </Text>
                        </View>
                    </View>
                    <View style={{display: 'flex', flexDirection: 'row'}}>
                        <View style={styles.borderColumn}>
                            <Text style={styles.borderTextBold}>Firma
                                :</Text>
                        </View>
                        <View style={styles.borderValue}>
                            <Text style={styles.textNormal}>
                              {orderDetailsItem?.company}
                            </Text>
                        </View>
                    </View>
                    <View style={{display: 'flex', flexDirection: 'row'}}>
                        <View style={styles.borderColumn}>
                            <Text style={styles.borderTextBold}>Adresa
                                :</Text>
                        </View>
                        <View style={styles.borderValue}>
                            <Text style={styles.textNormal}>
                              {orderDetailsItem?.address}
                            </Text>
                        </View>
                    </View>
                    <View style={{display: 'flex', flexDirection: 'row'}}>
                        <View style={styles.borderColumn}>
                            <Text style={styles.borderTextBold}>Oras/Cod/Tara
                                :</Text>
                        </View>
                        <View style={styles.borderValue}>
                            <Text style={styles.textNormal}>
                              {orderDetailsItem?.location}
                            </Text>
                        </View>
                    </View>
                    <View style={{display: 'flex', flexDirection: 'row'}}>
                        <View style={styles.borderColumn}>
                            <Text style={styles.borderTextBold}>Observatii:</Text>
                        </View>
                        <View style={styles.borderValue}>
                            <Text style={styles.textNormal}>
                              {orderDetailsItem.remarks || '-'}
                            </Text>
                        </View>
                    </View>
                    <View style={{display: 'flex', flexDirection: 'row'}}>
                        <View style={styles.borderColumn}>
                            <Text style={styles.borderTextBold}>Referinta:</Text>
                        </View>
                        <View style={styles.borderValue}>
                            <Text style={styles.textNormal}>
                              {orderDetailsItem?.reference || '-'}
                            </Text>
                        </View>
                    </View>
                </View>
            }

            {orderDetailsItem.type === 'DROPOFF' &&
                <View style={styles.truckDetails} wrap={false}>
                    <Text style={{
                      fontFamily: 'Roboto',
                      fontSize: 16,
                      marginLeft: 30
                    }}>Descarcare</Text>
                    <View style={{display: 'flex', flexDirection: 'row'}}>
                        <View style={styles.borderColumn}>
                            <Text style={styles.borderTextBold}>Data / Ora :</Text>
                        </View>
                        <View style={styles.borderValue}>
                            <Text style={styles.textNormal}>
                              {`${new Date(orderDetailsItem.date_from).toLocaleDateString('RO-ro')}${dateDiff(orderDetailsItem.date_from, orderDetailsItem.date_to) ? `/ ${new Date(orderDetailsItem.date_to).toLocaleDateString('RO-ro')}` : ''} `}
                            </Text>
                        </View>
                    </View>
                    <View style={{display: 'flex', flexDirection: 'row'}}>
                        <View style={styles.borderColumn}>
                            <Text style={styles.borderTextBold}>Firma :</Text>
                        </View>
                        <View style={styles.borderValue}>
                            <Text style={styles.textNormal}>
                              {orderDetailsItem?.company}
                            </Text>
                        </View>
                    </View>
                    <View style={{display: 'flex', flexDirection: 'row'}}>
                        <View style={styles.borderColumn}>
                            <Text style={styles.borderTextBold}>Adresa :</Text>
                        </View>
                        <View style={styles.borderValue}>
                            <Text style={styles.textNormal}>
                              {orderDetailsItem?.address}
                            </Text>
                        </View>
                    </View>
                    <View style={{display: 'flex', flexDirection: 'row'}}>
                        <View style={styles.borderColumn}>
                            <Text style={styles.borderTextBold}>Oras/Cod/Tara :</Text>
                        </View>
                        <View style={styles.borderValue}>
                            <Text style={styles.textNormal}>
                              {orderDetailsItem?.location}
                            </Text>
                        </View>
                    </View>
                    <View style={{display: 'flex', flexDirection: 'row'}}>
                        <View style={styles.borderColumn}>
                            <Text style={styles.borderTextBold}>Observatii:</Text>
                        </View>
                        <View style={styles.borderValue}>
                            <Text style={styles.textNormal}>
                              {orderDetailsItem.remarks}
                            </Text>
                        </View>
                    </View>
                    <View style={{display: 'flex', flexDirection: 'row'}}>
                        <View style={styles.borderColumn}>
                            <Text style={styles.borderTextBold}>Referinta:</Text>
                        </View>
                        <View style={styles.borderValue}>
                            <Text style={styles.textNormal}>
                              {orderDetailsItem?.reference || '-'}
                            </Text>
                        </View>
                    </View>
                </View>
            }
          </>
        ))}

        <View>
          <View style={styles.importantText}>
            <Text style={{
              fontFamily: 'Times-Bold',
              fontSize: '16'
            }}>Pret
              convenit: {parseFloat((Number(props.invoiceDetails?.transporter_price).toFixed(2)))}
              {' ' + props.invoiceDetails.transporter_currency}
              {props.invoiceDetails?.transporter_vat > 0 ? ' + TVA' : ''} </Text>
          </View>

        </View>

        <View wrap break={props.invoiceDetails?.OrderDetails.length < 3}
              style={{flexGrow: 1, width: '90%', marginLeft: 20, marginTop: 20}}>
          <Text style={{
            fontFamily: 'Roboto',
            fontWeight: 800,
            textDecoration: 'underline',
          }}>Termen si conditii de plata: </Text>

          <View style={{marginLeft: 30, marginTop: 10}}>
            <Text style={styles.borderTextBold}>1. Plata se va efectua
              la {props.invoiceDetails?.transporter?.invoice_deadline_days} de zile
              de la primirea documentelor in original solicitate. </Text>

            <Text style={styles.borderTextBold}>2. Factura de transport va fi insotita obligatoriu de minim 2
              exemplare originale ale documentului de transport tip CMR semnat si parafat de catre destinatarul
              marfurilor (cu data si ora), de comanda de transport confirmata de catre Transportator, DVI, DVE, T1,
              nota
              de livrare, aviz de insotire marfa, etc. </Text>

            <Text style={styles.borderTextBold}>3. Factura de transport si documentele in original aferente
              transportului trebuie trimise in maxim 7 zile de la data descarcarii. In caz contar, ne rezervam
              dreptul
              de
              a prelungii termenul de plata la 90 zile. </Text>

            <Text style={styles.borderTextBold}>4. Factura de transport trebuie sa contina in mod obligatoriu
              numarul si data comenzii noastre de transport, in caz contrar aceasta va fi returnata. </Text>

            <Text style={styles.borderTextBold}>5. Comanda neconfirmata in maxim 2 ore se considera
              acceptata. </Text>

            <Text style={styles.borderTextBold}>6. Penalizari : Transportatorul isi asuma toata responsabilitatea
              in ceea ce priveste transportul aferent comenzii avand toate documentele necesare pt efectuarea
              lui.Actualizarile pe tot parcursul transportului vor fi comunicate din 12 in 12 ore. </Text>

            <Text style={styles.borderTextBold}>7. Daca se intarzie la descarcare sau timpul de tranzit nu este
              respectat factura nu va fi achitata </Text>

            <Text style={styles.borderTextBold}>8. DACA NU SE RESPECTA OBSERVATIILE DE LA INCARCARE/ DESCARCARE SE
              VA PENALIZA CU 50E FIECARE GRESEALA </Text>
          </View>
        </View>

        <View wrap style={{flexGrow: 1, width: '90%', marginTop: 10, marginLeft: 10}}>
          <Text style={{marginLeft: 10, fontSize: 12, marginVertical: 2}}>Conditii generale:</Text>

          <View style={{marginLeft: 20}}>
            <Text style={styles.textNormal}>Transportul din prezenta comandă va fi efectuat în conformitate cu
              prevederile convenţiilor şi acordurilor naţionale şi internaţionale în vigoare TIR (daca se foloseste
              Carnet TIR),
              CMR, AETR, ADR (unde este cazul),ATA(unde este cazul)</Text>

            <Text style={styles.textNormal}>1.Transportul se execută cu scrisoare de trăsură CMR, Document “T” sau
              Carnet

              TIR,

              factură comercială, certificat de conformitate, certificat de calitate, precum şi alte documente
              necesare

              transportului, toate în original. Transportatorul răspunde de consecinţele pierderii acestora.</Text>

            <Text style={styles.textNormal}>2.Transportatorul va avea asigurare CMR minim 20.000 euro, care să
              acopere

              valoarea mărfii.</Text>

            <Text style={styles.textNormal}>3. În cazul în care marfa este însoţită de documentul EUR 1, acesta va
              trebui

              ştampilat obligatoriu la o vamă în ţara în care a încărcat. În caz contrar şoferul va suna pentru a i se

              confirma că nu este necesar EUR 1.</Text>

            <Text style={styles.textNormal}>4.În cazul în care marfa transportată nu circulă sub acoperire TIR, se
              va
              anexa

              o

              copie a declaraţiei de tranzit „T” confirmată de vama de destinaţie pe lângă CMR-ul confirmat fără

              rezerve.</Text>

            <Text style={styles.textNormal}>5. Reţinerea camionului la încărcare/ descărcare şi/sau vămuire va fi
              anunţată

              imediat la <b>Karim Spedition Logistik S.R.L</b>, iar la depăşirea termenului de 24/48 ore se va
              solicita

              înscrierea

              în CMR a timpului de staţionare cu semnătura şi ştampila expeditorului/destinatarului.;</Text>

            <Text style={styles.textNormal}>6. Şoferul va asista obligatoriu la încărcarea mărfii şi va verifica
              repartiţia greutăţii pe axe. Şoferul va confrunta datele din comandă/ documente cu ceea ce se încarcă.
              În
              cazul în care apar difernţe se va anunţa imediat <b>Karim Spedition Logistik S.R.L.</b></Text>

            <Text style={styles.textNormal}>7 În cazul în care în comanda/contract de transport se specifică
              “încărcătura exclusiva” se interzice încărcarea în camion/van a altor mărfuri. În caz contrar,
              transportatorul va fi penalizat cu 500 € plus contravaloarea daunelor produse în detrimentul <b>Karim
                Spedition Logistik S.R.L</b></Text>

            <Text style={styles.textNormal}>8. Camionul/van-ul va fi în condiţii optime de transport pentru a putea
              asigura

              integritatea mărfii pe tot parcursul transportului (va feri marfa de udare, umezire, etc)</Text>

            <Text style={styles.textNormal}>9. Se interzice transbordarea mărfii sau intermedierea acesteia de către
              alt

              transportator sau casă de expediţii fără acceptul nostru. Încălcarea condiţiilor din acest paragraf se

              penalizeaza cu de două ori contravaloarea transportului.</Text>

            <Text style={styles.textNormal}>10. Karim Spedition Logistik S.R.L nu este răspunzător de nici o
              depăşire de

              greutate totală pe axe. Orice neconcordanţă cu privire la cantitatea mărfii se va aduce la cunoştinţă

              înainte

              de încărcarea în camion/van.;</Text>

            <Text style={styles.textNormal}>11. În cazul în care nu se respectă oricare din condiţiile din această

              comandă/contract ( termen de încărcare, termen de livrare, loc vămuire export/import, operaţiunii de

              neutralizare, etc...) sau al conventiei CMR şi acestea conduc la pagube pe care clientul lui Karim
              Spedition

              Logistik S.R.L. le va factura acestuia, transportatorul va suporta la rândul lui aceste pagube. În
              celelalte

              cazuri de întârziere la încărcare/ descărcare nejustificate se penalizează cu 100 € pe ora.
              NEPREZENTAREA
              LA

              INCĂRCARE SE PENALIZEAZA CU 120% DIN TARIFUL DE TRANSPORT.</Text>

            <Text style={styles.textNormal}>12. În cazul în care Comanda /Contract de Transport nu este confirmata
              prin

              semnatura si trimisa noi o vom considera acceptata impreuna cu toti terminii impusi.</Text>

            <Text style={styles.textNormal}>13. Este interzisă prezentarea acestei Comenzi / Contract de Transport
              unei
              alte

              părţi, iar orice altă încercare de a contacta clientul se consideră incălcarea clauzei de
              confidenţialitate

              şi

              se va penaliza de către Karim Spedition Logistik S.R.L. cu 100.000 €..</Text>

            <Text style={styles.textNormal}>14.Orice problemă apărută în timpul desfăşurări transportului din
              prezenta

              Comandă/ Contract de Transport, indiferent de natura ei, va fi adusă imediat la cunoştinţă firmei <b>Karim

                Spedition Logistik S.R.L.</b></Text>

            <Text style={styles.textNormal}>15. Durata transportului nu trebuie sa fie mai mare decat termenul
              specificat
              la

              rubrica « Timp de tranzit/Descarcare Data/ora « . In caz de neprezentare la descarcare pana la ora
              stabilita

              in acest contract , contravaloarea cursei nu va fi platita transportatorului, iar penalizarile
              solicitate
              de

              catre clientul nostru se vor factura transportatorului.</Text>

            <Text style={styles.textNormal}>16. Litigiile de orice formă care decurg din/sau în legătură cu această
              Comandă / Contract de Transport, dacă nu vor fi rezolvate pe cale amiabilă, se vor soluţiona de către
              instanţele de judecată competente.</Text>

            <Text style={styles.textNormal}>17. Competenta de solutionare a litigiilor: instanţa competentă material
              din Oradea.</Text>

            <Text style={styles.textNormal}>
              18. Karim Spedition Logistik S.R.L declara ca , pe parcursul activitatii noastre, vom respecta legea de
              reglementare a salariului minim pe economie valabil pe teritoruiul Romaniei ordonanţa privind
              obligaţiile
              de declarare prevăzute de legea privind salariul minim pe economie, de legea privind delegarea
              angajaţilor,
              precum de legea privind leasingul forţei de muncă, precum ordonanţa privind modificarea obligaţiei cu
              privire la înregistrării programului de lucru, adoptată în temeiul legii privind salariul minim pe
              economie
              şi al legii privind delegarea angajaţilor .</Text>

            <Text style={styles.textNormal}>19. Prezenta Comandă de Transport este intocmită, executată şi
              interpretată

              conform legislaţiei române şi internaţionale în vigoare şi guvernată de acestea. In cazul in care actele

              originale nu sunt primite in cel mult 7 zile calendaristice de la data descarcarii, termenul de plata va
              fi

              extins la 90 de zile.
            </Text>

            <Text style={styles.textNormal}> 20. Factura va fi emisa in ambele monede: EUR si RON – la cursul BNR din
              ziua
              facturarii</Text>

            <Text style={{
              fontSize: 14,
              fontWeight: 800,
              fontFamily: 'Roboto-Bold',
              paddingVertical: 1
            }}>Adresa corespondenta: Strada Uzinelor 60, Oradea, jud. Bihor, cod postal 410605</Text>
          </View>
        </View>

        <View>
          <View style={styles.footerSignature}>
            <View style={{justifyContent: 'flex-start'}}>
              <Text style={styles.textNormal}>Cu Stima ,</Text>
              <Text style={styles.textNormal}>Echipa Karim Spedition Logistik,</Text>
              {/*<Image style={{width: "80%"}} src={KarimSignature}/>*/}
            </View>
            <View style={{width: '100%', textAlign: 'right'}}>
              <Text style={styles.textNormal}>Confirmare Transportator: </Text>
              <Text style={styles.textNormal}>Semnatura si stampila</Text>
            </View>
          </View>
        </View>

        <Image style={{position: 'absolute', bottom: 30, marginLeft: 30, width: '16%', height: '12%'}} fixed
               src={KarimSignature}/>
      </Page>
    </Document>


  )
}

export default TmsTransportTemplate;
