import {Box, Typography} from "@mui/material";
import ReportsCard from "../reports-card/ReportsCard";
import {Description, DriveEta, EmojiTransportation, MonetizationOn, ReceiptLong} from "@mui/icons-material";
import React, {useEffect, useState} from "react";

interface Props {
  content: any
}

interface ProductTransportDescription {
  unit_of_measure: string;
  quantity: number;
}

const NoticeReports = (props: Props) => {
  const [totalNotices, setTotalNotices] = useState<number>(0);
  const [productTransportDescriptions, setProductTransportDescriptions] = useState<ProductTransportDescription[]>([]);

  useEffect(() => {
    setTotalNotices(props?.content?.length);
  }, [props.content]);

  useEffect(() => {
    let mc: ProductTransportDescription = {unit_of_measure: 'MC', quantity: 0};
    let to: ProductTransportDescription = {unit_of_measure: 'TO', quantity: 0};

    props?.content?.forEach((notice: any) => {
      const invoiceProduct = notice.InvoiceProducts?.[0];

      if (invoiceProduct?.unit_of_measure === 'MC') mc.quantity += Number(invoiceProduct.quantity);
      if (invoiceProduct?.unit_of_measure === 'TO') to.quantity += Number(invoiceProduct.quantity);
    });

    mc.quantity = parseFloat(mc.quantity?.toFixed(2));
    to.quantity = parseFloat(to.quantity?.toFixed(2));

    setProductTransportDescriptions([to, mc]);
  }, [props.content])

  return <Box sx={{
    display: 'flex',
    flexWrap: 'wrap'
  }}>
    <ReportsCard titleText='Transporturi' icon={<EmojiTransportation/>} content={<>
      {productTransportDescriptions.map((productTransportDescription: ProductTransportDescription, index: number) => (
        <Box sx={{display: 'flex'}} key={`${productTransportDescription.unit_of_measure}-${productTransportDescription.quantity}-${index}`}>
          <Typography color='green' fontWeight='bold'
                      sx={{marginRight: '0.5rem'}}>{productTransportDescription.quantity}</Typography>
          <Typography color='white' fontWeight='bold'
                      sx={{textShadow: '1px 1px 2px black'}}>{productTransportDescription.unit_of_measure}</Typography>
        </Box>
      ))}
    </>}/>
    <ReportsCard titleText='Numar avize' icon={<ReceiptLong/>} content={<Box sx={{display: 'flex'}}>
      <Typography color='green' fontWeight='bold'
                  sx={{
                    marginRight: '0.5rem',
                  }}>{totalNotices}</Typography>
      <Typography color='white' fontWeight='bold'
                  sx={{
                    marginRight: '0.5rem',
                    textShadow: '1px 1px 2px black',
                    fontSize: '1rem'
                  }}>
        {totalNotices === 1 ? 'aviz' : 'avize'}
      </Typography>
    </Box>}/>
  </Box>
}

export default NoticeReports;