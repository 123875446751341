export const OrderTableHeaders = [
  {
    id: ['series', 'number'],
    label: 'Numar',
    width: 50,
    type: 'href',
    composite: true
  },
  {
    id: "buyer",
    label:"Client",
    width: 100
  },
  {
    id: "transporter",
    label: "Transportator",
    width: 100
  },
  {
    id: ['client_price', 'client_currency'],
    composite: true,
    label: "Pret client",
    width: 100
  },
  {
    id: ['car_reg_number'],
    label: "Masina",
    width: 150
  },
  {
    id: ['package_info'],
    label: "Detalii marfa",
    width: 150
  },
  {
    id: 'pickup_address',
    label:'Locatie preluare',
    width: 250
  },
  {
    id: 'dropoff_address',
    label:'Locatie livrare',
    width: 250
  },
  {
    id: 'pickup',
    label: 'Data preluare',
    width: 100,
    type: 'date'
  },
  {
    id: 'dropoff_date',
    label: 'Data livrare',
    width: 100,
    type: 'date'
  },
  {
    id: "created_at_utc",
    label: "Data creare",
    width: 100,
    type: 'date'
  },
  {
    id: "invoice_generated",
    label: "Factura emisa",
    width: 50,
    type: ''
  }
]

export const OrderDeliveryInformation = [

]

