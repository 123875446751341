import { useCallback, useEffect, useMemo, useState } from 'react';
import { Container, Dialog, DialogContent, DialogTitle, IconButton, useMediaQuery, useTheme } from '@mui/material';
import Table, { ActionItem } from '../../components/table/Table';
import { RepairEstimateTableConstants } from './RepairEstimateTableConstants';
import { Link, useNavigate } from 'react-router-dom';
import * as api from '../../api/api-client';
import { toast } from 'react-toastify';
import DeleteConfirmationDialog from '../../components/DeleteConfirmationDialog';
import { KeyValuePair } from '../../types/CommonTypes';
import { FilterSettings } from '../../types/FilterTypes';
import Filters from '../../components/filters/Filters';
import { AutofleetOption, CategoryOption, PartnerOption } from '../../components/forms/CreateRepairEstimateForm';
import CreateRepairEstimateDialog from './CreateReapairEstimateDialog';
import { Close } from '@mui/icons-material';
import PdfTemplate from '../../components/pdf-templates/PdfTemplate';
import RepairEstimateTemplate from '../../components/pdf-templates/RepairEstimateTemplate';
import { otherExpensesOption, serviceLists, currencyOptions } from './constants';
import RepairEstimateReports from '../../components/repair-estimate-reports/RepairEstimateReports';

export interface IRepairEstimate {
	name: string,
	auto_fleet_id?: string,
	partner_id?: string,
	deviz_id: string,
	date: string,
	denumire: string,
	infos?: string,
	pret_fara_tva: string,
	cota_tva: string,
	currency: string
}

// Filters to add
const filterable = (categoryOptions: CategoryOption[]): FilterSettings[] => ([
	{
		key: 'created_from',
		display: 'Creat din',
		type: 'date',
	},
	{
		key: 'created_to',
		display: 'Creat pana in',
		type: 'date',
	},
	{
		key: 'pret_from',
		display: 'Pret minim',
		type: 'number',
	},
	{
		key: 'pret_to',
		display: 'Pret maxim',
		type: 'number',
	},
	{
		key: 'currency',
		display: 'Moneda',
		type: 'dropdown',
		dropdownValues: currencyOptions
	},
	{
		key: 'infos',
		display: 'Observatii',
		type: 'text',
	},
	{
		key: 'denumire',
		display: 'Denumire',
		type: 'dropdown',
		dropdownValues: Object.values(serviceLists).reduce((acc, curr) => acc.concat(...curr)).concat(otherExpensesOption)
	},
	{
		key: 'id',
		display: 'Firma / Masina',
		type: 'dropdown',
		dropdownValues: categoryOptions.map(
			({ auto_fleet_id, partner_id, reg_no, name }) => (
				{
					label: reg_no ?? name as string,
					value: auto_fleet_id ? `auto_fleet_id:${auto_fleet_id}` : `partner_id:${partner_id}`
				}
			))
	}
]);

const RepairEstimatePage = () => {
	const [repairEstimates, setRepairEstimates] = useState<IRepairEstimate[]>([]);
	const [totalEuroAndRon, setTotalEuroAndRon] = useState<any>({});
	const [categoryOptions, setCategoryOptions] = useState<CategoryOption[]>([]);
	const [createAutoFleetDialogOpen, setCreateAutoFleetDialogOpen] = useState<boolean>(false);
	const [rowDeleteRequested, setRowDeleteRequested] = useState<IRepairEstimate | undefined>(undefined);
	const [rowPdfRequested, setRowPdfRequested] = useState<any>();
	const [reset, setReset] = useState<number>(0);
	const [queryParams, setQueryParams] = useState<string>('');
	const [filters, setFilters] = useState<any>('');
	const [open, setOpen] = useState<boolean>(false);
	const [logo, setLogo] = useState('');
	const navigate = useNavigate();

	const handleDialog = async (row: IRepairEstimate) => {
		const response = (await api.get('/partners/info')) as { message: PartnerOption };
		const id = response.message.partner_id;
		api.get(`/images?partner_id=${id}&type=LOGO`).then((res) => {
			setLogo(URL.createObjectURL(res));
		}).catch((err) => {
			toast.error(err?.message);
		});

		api.get(`/partners/deviz-pdf-data/${id}`)
			.then((res) => {
				setRowPdfRequested({ ...row, ...res })
			}).catch((err) => {
				console.log(err);
				toast.error(err);
			})
		setOpen(true);
	}

	const actions: ActionItem<IRepairEstimate>[] = useMemo(() => ([
		{
			name: 'deleteRepairEstimate',
			displayName: 'Sterge',
			action: setRowDeleteRequested,
		},
		{
			name: 'editRepairEstimate',
			displayName: 'Editeaza',
			action: (curr) => navigate(`${(curr as IRepairEstimate)?.deviz_id}`)
		},
		{
			name: 'pdfRepairEstimate',
			displayName: 'Operatii PDF',
			action: handleDialog
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	]), []);

	const rowDeleteCanceled = () => {
		setRowDeleteRequested(undefined);
	};

	const handleDeleteRow = useCallback(async () => {
		try {
			const res = await api.remove(`/deviz/${rowDeleteRequested?.deviz_id}`)
			setRowDeleteRequested(undefined);
			setReset(prev => prev + 1);
			toast.success(res.message as string);
		} catch (err) {
			const { error } = err as { error: string };
			toast.error(error);
		}
	}, [rowDeleteRequested]);

	const onAddRepairEstimateSuccess = useCallback((message: string) => {
		setCreateAutoFleetDialogOpen(false);
		toast.success(message);
		setReset(prev => prev + 1);
	}, []);

	useEffect(() => {
		(async function getOptions() {
			try {
				const autofleet = (await api.get('/autofleet/options')) as AutofleetOption[];
				const res = (await api.get('/partners/info')) as { message: PartnerOption };
				const tempOptions: CategoryOption[] = autofleet;
				res?.message?.partner_id && res?.message?.name && tempOptions.push(res?.message);
				setCategoryOptions(tempOptions);
			} catch (err) {
				const { error } = err as { error: string };
				toast.error(error);
			}
		})();
	}, []);

	useEffect(() => {
		(async function getRepairEstimates() {
			try {
				const response = (await api.get(`/deviz?${queryParams}`))?.message;
				const repairEstimateResponse = await response?.devize  as IRepairEstimate[];
				const repairEstimates = repairEstimateResponse.map(item => {
					item.deviz_id = item.deviz_id.toString();
					return item;
				})
				setTotalEuroAndRon(response);
				setRepairEstimates(repairEstimates);
			} catch (err) {
				const { error } = err as { error: string };
				toast.error(error);
			}
		})()
	}, [queryParams, reset]);

	useEffect(() => {
		setQueryParams(new URLSearchParams(filters).toString());
		navigate(`?${new URLSearchParams(filters).toString()}`);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filters]);

	const formatCell = (row: IRepairEstimate, value: string, fieldName: string | string[]) => {
		return <Link to={`${row.deviz_id}`}>{value}</Link>;
	};

	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

	return (
		<Container sx={{ height: '100%', margin: 0, marginTop: '3rem', maxWidth: '100vw !important' }}>
			<RepairEstimateReports content={totalEuroAndRon}/>
			<Filters
				filters={filters}
				filtersSettings={filterable(categoryOptions || [])}
				onFiltersSubmit={setFilters}
			/>
			<Table
				headers={RepairEstimateTableConstants}
				rows={repairEstimates}
				getFormattedCell={formatCell}
				handleAddActionClick={() => setCreateAutoFleetDialogOpen(true)}
				actions={actions}
			/>
			{rowDeleteRequested && (
				<DeleteConfirmationDialog
					rowDeleteRequested={rowDeleteRequested}
					deleteConfirmationText={rowDeleteRequested.deviz_id}
					handleDeleteRow={handleDeleteRow}
					rowDeleteCanceled={rowDeleteCanceled}
				/>
			)}
			<CreateRepairEstimateDialog
				categoryOptions={categoryOptions}
				createAutoFleetDialogOpen={createAutoFleetDialogOpen}
				setCreateAutoFleetDialogOpen={setCreateAutoFleetDialogOpen}
				onAddRepairEstimateSuccess={onAddRepairEstimateSuccess}
			/>
			<Dialog fullScreen={fullScreen} open={open} fullWidth maxWidth="md">
				<DialogTitle
					id='repair-estimate-pdf-dialog'
					sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}
				>
					<IconButton
						edge="end"
						color="inherit"
						onClick={() => setOpen(false)}
						aria-label="close"
						sx={{ justifySelf: 'end' }}
					>
						<Close />
					</IconButton>
				</DialogTitle>
				<DialogContent sx={{ height: '100%' }}>
					<PdfTemplate invoiceData={rowPdfRequested}
						exportName={`Deviz-${rowPdfRequested?.deviz_id}-${rowPdfRequested?.name}`}
						handleDialogOpenState={(newS: boolean) => setOpen(newS)}>
						{rowPdfRequested && logo && <RepairEstimateTemplate repairEstimate={rowPdfRequested as IRepairEstimate} logo={logo} />}
					</PdfTemplate>
				</DialogContent>
			</Dialog>
		</Container>
	);
};

export default RepairEstimatePage;
