import {CreateInvoiceDto} from "../../types/CreateInvoiceType";
import {FieldValues, useForm} from "react-hook-form";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import * as api from "../../api/api-client";
import {useEffect, useState} from "react";
import {toast} from "react-toastify";
import Table from "../table/Table";
import AddInvoiceProductForm from "./AddInvoiceProductForm";
import {Add, Close} from "@mui/icons-material";
import CreatePartnerForm from "./CreatePartnerForm";
import DatePickerFormControl from "../form-helpers/DatePickerFormControl";
import SelectFormControl from "../form-helpers/SelectFormControl";
import AutocompleteFormControl from "../form-helpers/AutocompleteFormControl";
import BasicFormControl from "../form-helpers/BasicFormControl";

interface Props {
  onInvoiceInput: (invoice: CreateInvoiceDto) => void;
}

interface PartnerAutocompleteModel {
  partner_id: number;
  partner_name: string;
}

const CreateIssuedInvoiceForm = (props: Props) => {
  const [addActionDialogOpen, setAddActionDialogOpen] = useState<boolean>(false);
  const [addPartnerDialogOpen, setAddPartnerDialogOpen] = useState<boolean>(false);
  const [partners, setPartners] = useState<PartnerAutocompleteModel[]>([]);
  const [clients, setClients] = useState<PartnerAutocompleteModel[]>([]);
  const [productRows, setProductRows] = useState<any[]>([]);
  const [invoiceProducts, setInvoiceProducts] = useState<any[]>([]);
  const [series, setSeries] = useState<any[]>([])

  const productHeaders = [
    {
      id: 'product_name',
      width: 100,
      label: 'Name',
    },
    {
      id: 'quantity',
      width: 100,
      label: 'Quantity',
    },
    {
      id: 'selling_price',
      width: 100,
      label: 'Selling price',
    },
  ];

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const {
    handleSubmit,
    formState: {errors},
    control,
    setValue,
    watch
  } = useForm({
      defaultValues: {
        invoice_series: '',
        invoice_number: '',
        partner: '',
        client: '',
        created_at: '',
        deadline_at: null,
        currency: '',
      }
    }
  );

  const watchSeries = watch('invoice_series');

  useEffect(() => {
    api.get('/users/series').then((res) => {
      const orderSeries = res.filter((resItem: any) => resItem.invoice_type === 'issued');
      setSeries(orderSeries.map((orderSerie: any) => {
        return {label: orderSerie.series, value: orderSerie.series}
      }));
    }).catch((err) => {
      toast.error(err);
    })
  }, []);

  useEffect(() => {
    if (watchSeries) {
      api.post("/invoices/series", {series: watchSeries, type: 'issued'}).then((res: any) => {
        setValue('invoice_number', res.number);
      }).catch((err) => {
        console.error(err)
      })
    }
  }, [watchSeries])

  useEffect(() => {
    const productRows = invoiceProducts.map((invoiceProduct) => ({
      product_name: invoiceProduct.product_name,
      quantity: invoiceProduct.quantity,
      selling_price: Number(invoiceProduct.selling_price)
    }))

    setProductRows(productRows)
  }, [invoiceProducts])

  const onPartnerAutocompleteInput = (event: any) => {
    const {value} = event.target;

    if (value.length >= 3) {
      api.get(`/partners/autocomplete?searchKey=${value}`).then((res) => {
        setPartners(res);
      }).catch((err) => {
          toast(err, {type: 'error'});
          console.error(err);
        }
      )
    }
  }

  const onClientAutocompleteInput = (event: any) => {
    const {value} = event.target;

    if (value.length >= 3) {
      api.get(`/partners/user/autocomplete?searchKey=${value}`).then((res) => {
        setClients(res);
      }).catch((err) => {
          toast(err, {type: 'error'});
          console.error(err);
        }
      )
    }
  }

  const openAddInvoiceProductDialog = () => {
    setAddActionDialogOpen(true);
  }

  const onClientAutocompleteChange = (event: any, value: any) => {
    setValue('client', value?.partner_id);
  }

  const onPartnerAutocompleteChange = (event: any, value: any) => {
    setValue('partner', value?.partner_id);
  }

  const onInvoiceInput = (data: FieldValues) => {
    const date = new Date((data.created_at.toString()));
    console.log(date);
    const invoice: CreateInvoiceDto = {
      buyer_id: data.partner,
      created_at_utc: date,
      type: 'issued',
      number: data.invoice_number,
      client_id: data.client,
      deadline_at_utc: data.deadline_at,
      series: data.invoice_series,
      status: 'unpaid',
      products: invoiceProducts,
      currency: data.currency
    }

    props.onInvoiceInput(invoice);
  }

  const onInvoiceProductSubmit = (data: FieldValues) => {
    if (data.type === 'service') {
      api.post('/products', data).then((res) => {
        toast.success('Product successfully added');

        setAddActionDialogOpen(false);
        const existingProduct = invoiceProducts.find((invoiceProduct) => invoiceProduct?.product_name === data.product_name);

        if (existingProduct) {

          const newProducts = invoiceProducts.map((invoiceProduct) => {
            if (invoiceProduct.product_name === existingProduct.product_name) {
              invoiceProduct.quantity = Number(existingProduct.quantity) + Number(data.quantity);
              return invoiceProduct;
            }

            return invoiceProduct;
          })

          setInvoiceProducts(newProducts);

          return;
        }

        setInvoiceProducts(prevState => [...prevState, {
          product_id: res.product_id,
          product_name: data.product_name,
          quantity: data.quantity,
          selling_price: data.purchase_price
        }])
      }).catch((err) => {
        toast.error(err);
      })

    } else {
      api.post('/products/quantity/reserve', {product_name: data.product_name, quantity: data.quantity}).then((res) => {
        toast.success('Product successfully added');

        setAddActionDialogOpen(false);
        const existingProduct = invoiceProducts.find((invoiceProduct) => invoiceProduct?.product_name === res.product_name);

        if (existingProduct) {

          const newProducts = invoiceProducts.map((invoiceProduct) => {
            if (invoiceProduct.product_name === existingProduct.product_name) {
              invoiceProduct.quantity = Number(existingProduct.quantity) + Number(data.quantity);
              return invoiceProduct;
            }

            return invoiceProduct;
          })

          setInvoiceProducts(newProducts);

          return;
        }

        setInvoiceProducts(prevState => [...prevState, {
          product_id: data.product_id,
          product_name: data.product_name,
          quantity: data.quantity,
          selling_price: data.purchase_price
        }])

      }).catch((err) => {
        toast.error(err);
      })

    }
  }

  const handleAddPartner = (data: FieldValues) => {
    api.post('/partners', data).then((res) => {
      toast.success('Partner successfully added');
    }).catch((err) => {
      toast.error(err?.message);
    })
  }

  const handleSeriesChange = (ev: any, onChange: (ev: any) => void) => {
    onChange(ev);

    if (ev.target.value.length >= 2) {
      api.post("/invoices/series", {series: ev.target.value.toUpperCase(), type: 'issued'}).then((res: any) => {
        setValue('invoice_number', res.number);
      }).catch((err) => {
        console.error(err)
      })
    }
  }

  return (
    <>
      <form onSubmit={handleSubmit(onInvoiceInput)}>
        <Box sx={{display: 'flex', width: '100%', flexDirection: 'column'}}>
          <Box sx={{display: 'flex'}}>
            <SelectFormControl control={control}
                               name='invoice_series'
                               selectOptions={series}
                               displayText='Serie'
                               rules={{required: {value: true, message: 'Seria este obligatorie'}}}
            />

            <BasicFormControl control={control} name='invoice_number' displayText='Numar' disabled={true}/>
          </Box>

          <Box sx={{display: "flex"}}>
            <AutocompleteFormControl control={control}
                                     name='client'
                                     displayText='Furnizor'
                                     options={clients}
                                     optionLabelFunc={(clients) => clients?.partner_name || ""}
                                     onChangeHandler={onClientAutocompleteChange}
                                     onInputHandler={onClientAutocompleteInput}
                                     rules={{
                                       required: {
                                         value: true, message: 'Furnizorul este obligatoriu.'
                                       }
                                     }}
                                     errors={errors}
            />

            <Tooltip title="Adauga o firma (furnizor)">
              <Button onClick={() => setAddPartnerDialogOpen(true)}>
                <Add/>
              </Button>
            </Tooltip>
          </Box>


          <Box sx={{display: "flex"}}>
            <AutocompleteFormControl control={control}
                                     name='partner'
                                     displayText='Cumparator'
                                     options={partners}
                                     optionLabelFunc={(partners) => partners?.partner_name || ""}
                                     onChangeHandler={onPartnerAutocompleteChange}
                                     onInputHandler={onPartnerAutocompleteInput}
                                     defaultValue={""}
                                     rules={{
                                       required: {
                                         value: true, message: 'Cumparatorul este obligatoriu.'
                                       }
                                     }}
                                     errors={errors}
            />

            <Tooltip title="Adauga o firma (cumparator)">
              <Button onClick={() => setAddPartnerDialogOpen(true)}>
                <Add/>
              </Button>
            </Tooltip>
          </Box>

          <Box sx={{display: 'flex'}}>
            <DatePickerFormControl control={control} name='created_at' displayText='Data emitere'/>

            <DatePickerFormControl control={control} name='deadline_at' displayText='Data scadenta'/>

            <SelectFormControl control={control} name='currency'
                               selectOptions={[{label: 'EURO', value: 'EUR'}, {label: 'RON', value: 'RON'}]}
                               displayText='Valuta'
                               rules={{required: {value: true, message: 'Valuta este obligatorie'}}}
                               errors={errors}/>
          </Box>

          {errors.deadline_at &&
              <Typography variant='caption' sx={{color: 'red'}}>{errors.deadline_at?.message?.toString()}</Typography>}

          <Box sx={{display: 'flex', flexDirection: 'column', width: '100%'}}>
            <Typography variant='h6'>Lista produse</Typography>
            <Table headers={productHeaders} rows={productRows} handleAddActionClick={openAddInvoiceProductDialog}/>
          </Box>


        </Box>
        <Button sx={{my: 2, width: '100%'}} type="submit" variant="contained">Submit</Button>
      </form>

      <Dialog
        fullScreen={fullScreen}
        open={addActionDialogOpen}
        aria-labelledby="responsive-dialog-title"
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle id="responsive-dialog-title"
                     sx={{width: '100%', display: 'flex', justifyContent: 'space-between'}}>
          Add invoice products
          <IconButton
            edge="end"
            color="inherit"
            onClick={() => setAddActionDialogOpen(false)}
            aria-label="close"
            sx={{justifySelf: 'end'}}
          >
            <Close/>
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{height: '100%'}}>
          <AddInvoiceProductForm handleAddInvoiceProduct={onInvoiceProductSubmit} invoiceType='issued'/>
        </DialogContent>
      </Dialog>

      <Dialog
        fullScreen={fullScreen}
        open={addPartnerDialogOpen}
        aria-labelledby="responsive-dialog-title"
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle id="responsive-dialog-title"
                     sx={{width: '100%', display: 'flex', justifyContent: 'space-between'}}>
          Add new partner
          <IconButton
            edge="end"
            color="inherit"
            onClick={() => setAddPartnerDialogOpen(false)}
            aria-label="close"
            sx={{justifySelf: 'end'}}
          >
            <Close/>
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{height: '100%'}}>
          <CreatePartnerForm onPartnerInput={handleAddPartner}/>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default CreateIssuedInvoiceForm;