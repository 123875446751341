import {
  Box,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import Table from "../../components/table/Table";
import { InvoiceTableHeaders } from "./InvoiceTableConstants";
import { Link, useLocation, useNavigate } from "react-router-dom";
import * as api from "../../api/api-client";
import { toast } from "react-toastify";
import React, { useEffect, useState } from "react";
import { Close } from "@mui/icons-material";
import PdfTemplate from "../../components/pdf-templates/PdfTemplate";
import DeleteConfirmationDialog from "../../components/DeleteConfirmationDialog";
import CreateIssuedInvoiceForm from "../../components/forms/CreateIssuedInvoiceForm";
import { FieldValues } from "react-hook-form";
import DialogContainer from "../../components/DialogContainer";
import CreateReceiptForm from "../../components/forms/CreateReceiptForm";
import CreateOperationForm from "../../components/forms/CreateOperationForm";
import { FilterSettings } from "../../types/FilterTypes";
import { KeyValuePair } from "../../types/CommonTypes";
import Filters from "../../components/filters/Filters";
import InvoiceVariantPdfTemplate from "../../components/pdf-templates/InvoiceVariantPdfTemplate";


const IssuedInvoicesPage = () => {
  const [invoices, setInvoices] = useState<any[]>([]);
  const [selectedInvoice, setSelectedInvoice] = useState<any>({});
  const [issueReceiptDialogOpen, setIssueReceiptDialogOpen] = useState(false);
  const [issueOperationDialogOpen, setIssueOperationDialogOpen] = useState(false);
  const [exportToPdfDialogOpen, setExportToPdfDialogOpen] = useState<boolean>(false);
  const [rowDeleteRequested, setRowDeleteRequested] = useState<any>(undefined);
  const [createInvoiceDialogOpen, setCreateInvoiceDialogOpen] = useState<boolean>(false);
  const [filters, setFilters] = useState<KeyValuePair>({} as KeyValuePair);
  const [queryParams, setQueryParams] = useState<string>();
  const [reset, setReset] = useState<number>(0);
  const [logo, setLogo] = useState<any>();
  const [user, setUser] = useState<any>();
  const [indexIncarcare, setIndexIncarcare] = useState<Record<string, string>>();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const actions: any[] = [
    {
      name: "issueReceipt",
      displayName: "Emite chitanta",
      action: handleIssueReceipt
    },
    {
      name: "createOperation",
      displayName: "Incaseaza cu OP",
      action: handleIssueOperation
    },
    {
      name: 'handleExportToPdf',
      displayName: 'Operatii PDF',
      action: handleExportToPdf
    },
    {
      name: 'deleteInvoiceRow',
      displayName: 'Delete row',
      action: handleDeleteRowRequest
    },
    {
      name: 'sendEFactura',
      displayName: 'Trimite e-Factura',
      action: handleEFactura
    }
  ]

  const filterable: FilterSettings[] = [
    {
      key: 'series',
      display: 'Serie',
      type: 'text',
    },
    {
      key: 'number',
      display: 'Numar',
      type: 'number'
    },
    {
      key: 'created_from',
      display: 'Data emitere (de la)',
      type: 'date'
    },
    {
      key: 'created_to',
      display: 'Data emitere (pana la)',
      type: 'date'
    },
    {
      key: 'status',
      display: 'Status',
      type: 'text'
    }
  ]

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (queryParams !== location.search) {
      console.log(location.search.split('?'))
      setQueryParams(location.search);
    }
  }, [location]);

  useEffect(() => {
    api.get(`/invoices${queryParams || '?'}&type=issued`).then((res: any) => {
      const temp = (res as any[]).map(current => ({
        ...current,
        buyer: current.buyer?.name,
        client: current.client?.name,
        order_reference_number: current.order_reference?.series + current.order_reference?.number || undefined,
        ...(indexIncarcare && !!indexIncarcare[current.invoice_id] && { id_e_factura: indexIncarcare[current.invoice_id] }),
      }))
      console.log({ temp });
      setInvoices(temp);
    })
  }, [queryParams, reset, indexIncarcare]);

  useEffect(() => {
    if (filters) {
      navigate(`?${new URLSearchParams(filters).toString()}`);
    }
  }, [filters])

  function handleIssueReceipt(row: any) {
    setSelectedInvoice(row);
    setIssueReceiptDialogOpen(true);
  }

  async function handleEFactura(row: any) {
    api.put(`/invoices/send/${row.invoice_id}`, {
      classifiedTaxCategory: "S",
      taxPercent: "19"
    }).then((res: any) => {
      setIndexIncarcare(res.responseMessage);
      toast.success("e-Factura generat cu succes");
    }).catch((err) => {
      const error: string = `${err}`;
      toast.error(error);
    })
  }

  function handleIssueOperation(row: any) {
    setSelectedInvoice(row);

    setIssueOperationDialogOpen(true);
  }

  async function handleExportToPdf(row: any) {
    await Promise.all([
      api.get(`/invoices/${row.invoice_id}`).then((res: any) => {
        setSelectedInvoice(res);

        api.get(`/images?partner_id=${res.invoice.client_id}&type=LOGO`).then((res) => {
          setLogo(URL.createObjectURL(res));
        }).catch((err) => {
          toast.error(err?.message);
        });
      }).catch((err) => {
        toast.error(err);
      }),
      api.get('/users/current').then((res) => {
        setUser(res);
      }).catch((err) => {
        toast.error(err);
      })
    ]).then(() => {
      setExportToPdfDialogOpen(true);
    })
  }

  function handleDeleteRowRequest(row: any) {
    setRowDeleteRequested(row);
  }

  const handleDeleteRow = () => {
    api.remove(`/invoices/${rowDeleteRequested.invoice_id}`).then((res) => {
      toast.success(res.message);
      setReset(reset + 1);
    }).catch((err) => {
      toast.error(err.message);
    }).finally(() => {
      setRowDeleteRequested(undefined);
    })
  }

  const rowDeleteCanceled = (row: any) => {
    setRowDeleteRequested(null);
  }

  const openCreateInvoiceDialog = () => {
    setCreateInvoiceDialogOpen(true);
  }

  const handleCreateInvoice = (data: FieldValues) => {
    api.post('/invoices', data).then((res) => {
      toast.success('Factura adaugata cu success');
      setCreateInvoiceDialogOpen(false);
      setReset(reset + 1);
    }).catch((err) => {
      toast.error(err.message || 'Ceva n-a mers bine!');
    })
  }

  const handleCreateReceipt = (data: FieldValues) => {
    api.post("/receipt", data).then(res => {
      toast.success('Chitanta a fost emisa cu succes.');
      setReset(reset + 1);
    }).catch(err => {
      toast.error(err.message);
    });
  }

  const handleCreateOperation = (data: FieldValues) => {
    api.post("/operation", data).then(res => {
      toast.success('Documentul a fost emis cu succes.');
      setReset(reset + 1);
      setIssueOperationDialogOpen(false);
    }).catch(err => {
      toast.error(err.message);
    })
  }

  const formatCell = (row: any, value: string, fieldName: string | string[]) => {
    if (fieldName.includes('series')) {
      fieldName = 'series';
    }

    switch (fieldName) {
      case 'series': {
        console.log('here');
        return <Link to={`/invoices/${row.invoice_id}`}>{value}</Link>
      }
      case 'order_reference_number': {
        return <Link to={`/orders/${row.order_reference?.order_id}`}>{value}</Link>
      }
      default: {
        break;
      }
    }

  }

  const onFilterSubmit = (filters: KeyValuePair) => {
    setFilters(filters);
  }

  return (
    <Container sx={{ height: '100%', margin: 0, marginTop: '3rem', maxWidth: '100vw !important' }}>
      <Filters filters={filters} filtersSettings={filterable} onFiltersSubmit={onFilterSubmit} />
      <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <Table headers={InvoiceTableHeaders}
          rows={invoices}
          actions={actions}
          handleExportToPdf={handleExportToPdf}
          getFormattedCell={formatCell}
          handleAddActionClick={openCreateInvoiceDialog}
        ></Table>
      </Box>

      <DialogContainer
        dialogTitle="Incaseaza factura"
        isDialogOpen={issueReceiptDialogOpen}
        handleDialogState={setIssueReceiptDialogOpen}
      >
        <CreateReceiptForm invoice={selectedInvoice} onReceiptSubmit={handleCreateReceipt} paymentType="INCASARE" />
      </DialogContainer>

      <DialogContainer
        dialogTitle="Incaseaza factura"
        isDialogOpen={issueOperationDialogOpen}
        handleDialogState={setIssueOperationDialogOpen}
      >
        <CreateOperationForm invoice={selectedInvoice} onReceiptSubmit={handleCreateOperation} paymentType="INCASARE" />
      </DialogContainer>

      <Dialog
        fullScreen={fullScreen}
        open={createInvoiceDialogOpen}
        aria-labelledby="responsive-dialog-title"
        fullWidth
        maxWidth="md"
      >
        <DialogTitle id="responsive-dialog-title"
          sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant='h5'>Adauga o factura</Typography>
          <IconButton
            edge="end"
            color="inherit"
            onClick={() => setCreateInvoiceDialogOpen(false)}
            aria-label="close"
            sx={{ justifySelf: 'end' }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ height: '100%' }}>
          <CreateIssuedInvoiceForm onInvoiceInput={handleCreateInvoice} />
        </DialogContent>
      </Dialog>

      <Dialog
        fullScreen={fullScreen}
        open={exportToPdfDialogOpen}
        aria-labelledby="responsive-dialog-title"
        fullWidth
        maxWidth="md"
      >
        <DialogTitle id="responsive-dialog-title"
          sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
          <IconButton
            edge="end"
            color="inherit"
            onClick={() => setExportToPdfDialogOpen(false)}
            aria-label="close"
            sx={{ justifySelf: 'end' }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ height: '100%' }}>
          <PdfTemplate invoiceData={selectedInvoice}
            exportName={`Factura-${selectedInvoice?.invoice?.series}-${selectedInvoice?.invoice?.number}`}
            handleDialogOpenState={(value) => setExportToPdfDialogOpen(value)}>
            <InvoiceVariantPdfTemplate invoiceData={selectedInvoice} logo={logo} user={user} />

          </PdfTemplate>
        </DialogContent>
      </Dialog>

      <DeleteConfirmationDialog rowDeleteRequested={rowDeleteRequested}
        deleteConfirmationText={rowDeleteRequested?.number + rowDeleteRequested?.series}
        handleDeleteRow={handleDeleteRow} rowDeleteCanceled={rowDeleteCanceled} />

    </Container>
  )
}

export default IssuedInvoicesPage;
