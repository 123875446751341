import {FieldValues, useForm} from "react-hook-form";
import BasicFormControl from "../form-helpers/BasicFormControl";
import {toast} from "react-toastify";
import {Box, Button} from "@mui/material";
import SelectFormControl from "../form-helpers/SelectFormControl";
import * as api from "../../api/api-client";
import AutocompleteFormControl from "../form-helpers/AutocompleteFormControl";
import {useEffect, useState} from "react";

interface Props {
  onUserSubmit: (data: FieldValues) => void;
}

const CreateUserForm = (props: Props) => {
  const [vehicles, setVehicles] = useState<any>([]);
  const [partners, setPartners] = useState<any>([]);

  const {
    handleSubmit,
    formState: {errors},
    control,
    setValue
  } = useForm();

  useEffect(() => {
    api.get('/partners/user').then((res) => {
      setValue('partner', res?.[0]);
      setPartners(res);
    }).catch((err) => {
      toast.error(err);
    })
  }, [])

  const handleUserSubmit = (data: FieldValues) => {
    if (data.password != data.passwordCheck) {
      toast('Parolele nu corespund', {type: 'error'});
      return;
    }

    props.onUserSubmit(data);
  }

  const onVehicleInput = (event: any) => {
    const {value} = event.target;

    if (value.length >= 3) {
      api.get('/autofleet/options').then((res) => {
        setVehicles(res);
      }).catch((err) => {
        toast.error(err.message);
      });
    }
  }

  const onVehicleChange = (event: any, value: any) => {
    setValue('vehicle', value);
  }

  return (
    <form onSubmit={handleSubmit(handleUserSubmit)}>

      <Box sx={{display: 'flex', flexDirection: {xs: 'column', md: 'row'}}}>
        <BasicFormControl control={control}
                          name='email'
                          displayText='Email'
                          errors={errors}
                          variant='outlined'
                          rules={{
                            required: {value: true, message: 'Email-ul este obligatoriu.'},
                            pattern: /^\S+@\S+$/i
                          }}
        />
        <BasicFormControl control={control}
                          name='last_name'
                          displayText='Nume'
                          errors={errors}
                          variant='outlined'
                          rules={{
                            required: {value: true, message: 'Numele este obligatoriu.'}
                          }}/>
        <BasicFormControl control={control}
                          name='first_name'
                          displayText='Prenume'
                          errors={errors}
                          variant='outlined'
                          rules={{
                            required: {value: true, message: 'Prenumele este obligatoriu.'}
                          }}/>
        <BasicFormControl control={control}
                          name='phone'
                          displayText='Telefon'
                          errors={errors}
                          variant='outlined'
        />
      </Box>
      <Box sx={{display: 'flex', flexDirection: {xs: 'column', md: 'row'}}}>
        <BasicFormControl control={control}
                          name='id_card_series'
                          displayText='Serie CI'
                          errors={errors}
                          variant='outlined'
        />
        <BasicFormControl control={control}
                          name='id_card_number'
                          displayText='Numar CI'
                          errors={errors}
                          variant='outlined'
        />
        <BasicFormControl control={control}
                          name='id_card_issued_by'
                          displayText='Emitent CI'
                          errors={errors}
                          variant='outlined'
        />
        <SelectFormControl control={control} name='role'
                           selectOptions={[{label: 'Administrator', value: '1'}, {
                             label: 'Operator',
                             value: '2'
                           }, {label: 'Sofer', value: '3'}]}
                           displayText='Rol'
                           rules={{required: {value: true, message: 'Rolul este obligatoriu'}}}
                           errors={errors}/>
      </Box>

      <Box sx={{display: 'flex', flexDirection: {xs: 'column', md: 'row'}}}>
        <>
        {partners?.length > 0  &&
            <AutocompleteFormControl control={control}
                                     name='partner'
                                     displayText='Firma'
                                     options={[partners[0]]}
                                     optionLabelFunc={(partners) => partners?.name || ""}
                                     errors={errors}
                                     rules={{
                                       required: {value: true, message: 'Campul este obligatoriu'}
                                     }}
                                     defaultValue={{
                                       partner_id: partners?.[0].partner_id || '',
                                       name: partners?.[0].name || ''
                                     }}
            />
        }
        </>
        <AutocompleteFormControl control={control}
                                 name='vehicle'
                                 displayText='Numar de inmatriculare'
                                 options={vehicles}
                                 onChangeHandler={onVehicleChange}
                                 onInputHandler={onVehicleInput}
                                 optionLabelFunc={(vehicles) => vehicles?.reg_no || ""}
                                 errors={errors}
                                 defaultValue={{
                                   auto_fleet_id: vehicles?.[0]?.auto_fleet_id || '',
                                   reg_no: vehicles?.[0]?.reg_no || ''
                                 }}
        />
      </Box>
      <Box sx={{display: 'flex', flexDirection: {xs: 'column', md: 'row'}}}>
        <BasicFormControl control={control}
                          name='password'
                          displayText='Parola'
                          errors={errors}
                          variant='outlined'
                          type="password"
                          rules={{
                            required: {value: true, message: 'Parola este obligatorie.'}
                          }}
        />
        <BasicFormControl control={control}
                          name='passwordCheck'
                          displayText='Reintrodu Parola'
                          errors={errors}
                          variant='outlined'
                          type="password"
                          rules={{
                            required: {value: true, message: 'Parola este obligatorie.'}
                          }}
        />
      </Box>

      <Button type='submit' variant='contained' sx={{width: '100%', my: 2}}>Submit</Button>

    </form>
  )
}

export default CreateUserForm;
