import {Box, Button} from "@mui/material";
import {FieldValues, useForm} from "react-hook-form";
import BasicFormControl from "../form-helpers/BasicFormControl";
import CheckboxFormControl from "../form-helpers/CheckboxFormControl";
import SelectFormControl from "../form-helpers/SelectFormControl";

interface Props {
  onSeriesInput: (series: FieldValues) => void;
}

const CreateInvoiceSeries = (props: Props) => {
  const {
    handleSubmit,
    formState: {errors},
    control,
    setValue
  } = useForm({
    defaultValues: {
      invoice_type: '',
      series: '',
      default: true
    }
  })

  const onSeriesDataSubmit = (data: FieldValues) => {
    props.onSeriesInput(data);
  }

  return (
    <form onSubmit={handleSubmit(onSeriesDataSubmit)}>
      <Box sx={{display: "flex", flexDirection: "column", width: '100%'}}>
        <Box sx={{display: 'flex', flexDirection: 'column', width: '95%'}}>
          <SelectFormControl control={control}
                             name='invoice_type'
                             displayText='Tip serie'
                             selectOptions={[
                               {label: 'Factura emisa', value: 'issued'},
                               {label: 'Aviz', value: 'notice'},
                               {label: 'Comanda', value: 'order'},
                               {label: 'Chitanta', value: 'receipt'}
                             ]}
                             rules={{
                               required: {
                                 value: true,
                                 message: 'Tipul seriei este obligatoriu'
                               },
                               pattern: {
                                 value: /notice|issued|order|receipt/,
                                 message: 'Tipul seriei este incorect'
                               }
                             }} errors={errors}

          />

          <BasicFormControl control={control} name='series' displayText='Series'
                            variant='outlined'
                            rules={{
                              required: {
                                value: true,
                                message: 'Series is required'
                              }
                            }} errors={errors}/>
          <CheckboxFormControl control={control} name='default' displayText='Set as default'
                               rules={{
                                 required: {
                                   value: true,
                                   message: 'Set as default value is required'
                                 }
                               }} errors={errors}
          />
        </Box>
        <Button type='submit' variant='contained'>Submit</Button>
      </Box>
    </form>
  )
}

export default CreateInvoiceSeries;