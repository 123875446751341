import {Controller, FieldErrors, FieldPath, FieldValues} from "react-hook-form";
import {Box, Checkbox, FormControl, FormControlLabel, Typography} from "@mui/material";
import {RegisterOptions} from "react-hook-form/dist/types/validator";

interface Props {
  control: any;
  name: string;
  displayText: string;
  rules?: Omit<RegisterOptions<FieldValues, FieldPath<any>>, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>;
  errors?: FieldErrors;
  type?: string;
  variant?: 'outlined' | 'standard';
  disabled?: boolean;
  defaultValue?: boolean;
}

const CheckboxFormControl = (props: Props) => {
  return (
    <Box sx={{width: '100%', mx: {xs: '0', md: '1rem'}}}>
      <FormControl>
        <FormControlLabel label={props.displayText} control={
          <Controller
            name={props.name}
            rules={props.rules}
            control={props.control}
            render={({field}) => (
              <Checkbox
                {...field}
                disabled={props.disabled || false}
                checked={field.value || false}
              />
            )}
          />
        }/>
      </FormControl>
      {
        props.errors?.[props.name] &&
          <Typography variant='caption'
                      sx={{color: 'red'}}>{props.errors?.[props.name]?.message?.toString()}</Typography>
      }
    </Box>
  )
}

export default CheckboxFormControl;
