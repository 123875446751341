import {FieldValues, useForm} from "react-hook-form";
import * as api from "../../api/api-client";
import {toast} from "react-toastify";
import {useEffect} from "react";
import {Box, Button} from "@mui/material";
import BasicFormControl from "../form-helpers/BasicFormControl";

const UserPersonalInformation = () => {

  useEffect(() => {
    api.get('/users/current').then((res) => {
      reset(res);
    }).catch((err) => {
      toast.error(err);
    });
  }, []);

  const {
    handleSubmit,
    formState: {errors},
    control,
    reset,
  } = useForm({
    defaultValues: {
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      id_card_series: '',
      id_card_number: '',
      id_card_issued_by: '',
    }
  });

  const onSubmit = (data: FieldValues) => {
    api.put('/users/current', data).then((res) => {
      toast.success('Informatiile au fost actualizate cu succes!');
    }).catch((err) => {
      toast.error(err?.message);
    });
  }


  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box sx={{display: 'flex', flexDirection: 'column'}}>
          <Box sx={{display: 'flex', flexDirection: 'column', width: '95%'}}>
            <BasicFormControl control={control} name='email' displayText='Email'
                              errors={errors}
                              rules={{required: {value: true, message: 'Email-ul este obligatoriu.'},
                                pattern: /^\S+@\S+$/i}}
                              />
            <BasicFormControl control={control} name='last_name' displayText='Nume'
                              errors={errors}
                              rules={{
                                required: {value: true, message: 'Numele este obligatoru.'}
                              }}/>
            <BasicFormControl control={control} name='first_name' displayText='Prenume'
                              errors={errors}
                              rules={{
                                required: {value: true, message: 'Prenumele este obligatoru.'}
                              }}/>
            <BasicFormControl control={control} name='phone' displayText='Telefon'/>
            <BasicFormControl control={control} name='id_card_series' displayText='Serie CI'/>
            <BasicFormControl control={control} name='id_card_number' displayText='Numar CI'/>
            <BasicFormControl control={control} name='id_card_issued_by' displayText='Emitent CI'/>

          </Box>

          <Button variant='contained' type='submit'>Salveaza</Button>
        </Box>
      </form>
    </>
  )
}

export default UserPersonalInformation;
