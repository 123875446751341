export const EmployeeTableHeaders = [
  {
    id: ['first_name', 'last_name'],
    label: 'Name',
    width: 100,
    type: 'href',
    composite: true
  },
  {
    id: 'nationality',
    label: 'Nationality',
    width: 100,
  },
  {
    id: 'town',
    label: 'Town',
    width: 100
  },
  {
    id: 'address',
    label: 'Address',
    width: 100
  },
  {
    id: 'phone',
    label: 'Phone',
    width: 100
  },
  {
    id: 'email',
    label: 'Email',
    width: 100
  }
]