import {Box, Button, Container, Toolbar,} from "@mui/material";
import React, {useState} from "react";
import * as api from "../../api/api-client"
import {toast} from "react-toastify";
import DialogContainer from "../DialogContainer";
import AddRecipientEmailForm from "../forms/AddRecipientEmailForm";
import {FieldValues} from "react-hook-form";
import ReactPDF, {Document, Page, PDFViewer, StyleSheet} from "@react-pdf/renderer";
import {saveAs} from "file-saver";
import pdf = ReactPDF.pdf;

interface Props {
  invoiceData: any;
  children?: any;
  exportName: string;
  handleDialogOpenState?: (value: boolean) => void;
}

const styles = StyleSheet.create({
  pageContainer: {
    flexDirection: 'column',
    width: '90%',
    marginTop: 40,
    marginBottom: 40
  },
});

const PdfTemplate = (props: Props) => {
  const [isEmailDialogOpen, setEmailDialogOpen] = useState<boolean>(false);
  const [emailInfo, setEmailInfo] = useState<any>();

  const savePdf = () => {
    pdf(props.children).toBlob().then((blob: any) => {
      saveAs(blob, props.exportName)
    })
  }
  const generateHtmlReport = (emailInfo: any) => {

    const myPdf
      = pdf(props.children);

    myPdf.toBlob().then((res: Blob) => {
      let base64data = '';

      const reader = new FileReader();

      reader.readAsDataURL(res);
      reader.onloadend = () => {
        base64data = reader.result as any;

        const clientId = props?.invoiceData.invoice?.client_id || props?.invoiceData.client?.partner_id
        const emailToSend = {
          partner_id: clientId,
          receiverEmailAddress: emailInfo,
          attachmentName: props.exportName,
          attachments: base64data
        }

        api.post('/email/send', emailToSend).then((res) => {
          toast.success(res.message);

        }).catch((err) => {
          toast.error(err);
        }).finally(() => {
          props.handleDialogOpenState?.(false);
          setEmailDialogOpen(false);
        })
      }
    })
    // const resFile = new File([res], "pdfname.pdf", {lastModified: (new Date()).getTime()});
  }

  const handleEmailDialogState = (dialogState: boolean) => {
    setEmailDialogOpen(dialogState);
  }

  const handleRecipientSubmit = (data: FieldValues) => {
    setEmailInfo(data.recipient_email);
    generateHtmlReport(data.recipient_email);
  }

  return (
    <>
      <Toolbar>
        <Button onClick={() => savePdf()}>Descarca PDF</Button>
        <Button onClick={() => handleEmailDialogState(true)}>Trimite email</Button>
      </Toolbar>

      <Container sx={{height: '100%', margin: 0}}>
        <Box id='pdfContainer'>
          <Document style={{display: 'none'}}>
            <Page size='A4' wrap style={styles.pageContainer}>
              {props.children}
            </Page>
          </Document>
        </Box>

        <PDFViewer
          showToolbar={false}
          width='100%'
          height='800px'
          style={{width: '100%'}}
          children={props.children}/>
      </Container>

      <DialogContainer isDialogOpen={isEmailDialogOpen} dialogTitle='Adauga adresa destinatarului'
                       handleDialogState={(value) => handleEmailDialogState(value)}>
                        
        <AddRecipientEmailForm onRecipientSubmit={handleRecipientSubmit}/>

        

      </DialogContainer>
    </>
  )
}

export default PdfTemplate;