import {Box, Typography} from "@mui/material";
import ReportsCard from "../reports-card/ReportsCard";
import {MonetizationOn, ReceiptLong} from "@mui/icons-material";
import React, {useEffect, useState} from "react";

interface Props {
  content: any
}


const OrderReports = (props: Props) => {
  const [metri, setMetri] = useState<number>(0);
  const [totalOrders, setTotalOrders] = useState<number>(0);
 

  useEffect(() => {
    setTotalOrders(props?.content?.length);
   
  }, [props.content]);

  useEffect(() => {
    let metriPatrati: number = 0


    props?.content?.forEach((orderItem: any) => {
        metriPatrati += Number(orderItem.metri_podea_patrati);
    });

   

    setMetri(metriPatrati);
  }, [props.content])

  return <Box sx={{
    display: 'flex',
    flexWrap: 'wrap', justifyContent: {xs:'space-evenly', md: 'space-between'}}}>
    <ReportsCard titleText='Metri Podea' icon={<MonetizationOn/>} content={<>
     
        <Box sx={{display: 'flex'}}>
          <Typography color='green' fontWeight='bold'
                      sx={{marginRight: '0.5rem'}}>{metri}</Typography>
        
        </Box>
   
    </>}/>
    <ReportsCard titleText='Numar comenzi' icon={<ReceiptLong/>} content={<Box sx={{display: 'flex'}}>
      <Typography color='green' fontWeight='bold'
                  sx={{
                    marginRight: '0.5rem',
                  }}>{totalOrders}</Typography>
      <Typography color='white' fontWeight='bold'
                  sx={{
                    marginRight: '0.5rem',
                    textShadow: '1px 1px 2px black',
                    fontSize: '1rem'
                  }}>
        {totalOrders === 1 ? 'comanda' : 'comenzi'}
      </Typography>
    </Box>}/>
   
  </Box>
}

export default OrderReports;