import {  useEffect, useMemo, useState } from 'react';
import {
	Checkbox,
	Container,
	FormControlLabel,
	useMediaQuery,
	useTheme
} from '@mui/material';
import Table, { ActionItem } from '../../components/table/Table';
import { Link, useNavigate } from 'react-router-dom';
import * as api from '../../api/api-client';
import { toast } from 'react-toastify';
import { FilterSettings } from '../../types/FilterTypes';
import Filters from '../../components/filters/Filters';
import { AutofleetOption, CategoryOption, PartnerOption } from '../../components/forms/CreateRepairEstimateForm';
import {AutoProfitLossTableConstants} from "./AutoProfitLossTableConstants";
import {Box} from "@mui/system";
import Button from "../../components/Button";
import {saveAs} from "file-saver";
import ProfitLossReports from "./ProfitLossReports";

export interface IAutoProfitLoss {
	car: string,
	date?: string,
	description?: string,
	currency: string,
	profit: number,
	loss: number
}

const filterable = (categoryOptions: CategoryOption[]): FilterSettings[] => ([
	{
		key: 'date_from',
		display: 'Perioada din',
		type: 'date',
	},
	{
		key: 'date_to',
		display: 'Perioada pana in',
		type: 'date',
	},
	{
		key: 'id',
		display: 'Vehicul',
		type: 'dropdown',
		dropdownValues: categoryOptions.map(
			({ auto_fleet_id, partner_id, reg_no, name }) => (
				{
					label: reg_no ?? name as string,
					value: auto_fleet_id ? `auto_fleet_id:${auto_fleet_id}` : `partner_id:${partner_id}`
				}
			))
	}
]);

const AutoProfitLossPage = () => {
	const [rows, setRows] = useState<IAutoProfitLoss[]>([]);
	const [totalEuroAndRon, setTotalEuroAndRon] = useState<any>(undefined);
	const [categoryOptions, setCategoryOptions] = useState<CategoryOption[]>([]);
	const [reset, setReset] = useState<number>(0);
	const [queryParams, setQueryParams] = useState<string>('');
	const [filters, setFilters] = useState<any>('');
	const navigate = useNavigate();
	const [groupByCarRegNumber, setGroupByCarRegNumber] = useState<boolean>(true);
	const [groupByDate, setGroupByDate] = useState<boolean>(false);
	const [groupByDescription, setGroupByDescription] = useState<boolean>(false);

	useEffect(() => {
		(async function getOptions() {
			try {
				const autofleet = (await api.get('/autofleet/options')) as AutofleetOption[];
				const tempOptions: CategoryOption[] = autofleet;
				setCategoryOptions(tempOptions);
			} catch (err) {
				const { error } = err as { error: string };
				toast.error(error);
			}
		})();
	}, []);

	useEffect(() => {
		(async function getAutoProfitLoss() {
			try {
				const response = (await api.get(`/auto-profit-loss?${queryParams}`))?.message;

				setRows(response.rows);
				setTotalEuroAndRon(response.totals);
			} catch (err) {
				const { error } = err as { error: string };
				toast.error(error);
			}
		})()
	}, [queryParams, reset, ]);

	useEffect(() => {
		setQueryParams(new URLSearchParams({...filters, groupByCarRegNumber, groupByDate, groupByDescription},).toString());
		navigate(`?${new URLSearchParams(filters).toString()}`);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filters, groupByCarRegNumber, groupByDate, groupByDescription]);



	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

	async function exportPDF() {
		const response = (await api.get(`/auto-profit-loss/pdf?${queryParams}`, true));
		saveAs(response, "Raport-profit-pierderi.pdf");
	}

	return (
		<Container sx={{ height: '100%', margin: 0, marginTop: '3rem', maxWidth: '100vw !important' }}>
			<ProfitLossReports content={totalEuroAndRon}/>
			<Filters
				filters={filters}
				filtersSettings={filterable(categoryOptions || [])}
				onFiltersSubmit={setFilters}
				expanded={true}
			/>

			<Box sx={{ display: "flex", flexDirection: "row", width: '100%', alignItems:"center", marginTop: "20px" }}>
				<Box sx={{ display: "flex", flexDirection: "row", marginRight: "10px" }}>
				Grupeaza dupa:
				</Box>
				<FormControlLabel disabled onChange={(event, checked) => setGroupByCarRegNumber(checked)}
								  control={<Checkbox defaultChecked={groupByCarRegNumber}/>} label="Nr masina"/>
				<FormControlLabel onChange={(event, checked) => setGroupByDate(checked)}
								  control={<Checkbox defaultChecked={groupByDate}/>} label="Data"/>
				<FormControlLabel onChange={(event, checked) => setGroupByDescription(checked)}
								  control={<Checkbox defaultChecked={groupByDate}/>} label="Descriere"/>
				<Box sx={{ display: "flex", flexDirection: "row", marginRight: "10px" }}>
					<Button title={'Exporta PDF'} onClick={exportPDF}></Button>
				</Box>
			</Box>
			<Table
				headers={AutoProfitLossTableConstants}
				rows={rows}
			/>
		</Container>
	);
};

export default AutoProfitLossPage;
