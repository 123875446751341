
import React, {useEffect, useState} from "react";

import {Container} from "@mui/material";

import DeleteConfirmationDialog from "../../components/DeleteConfirmationDialog";
import {Link, useLocation, useNavigate} from "react-router-dom";
import * as api from "../../api/api-client";
import {toast} from "react-toastify";
import Table from "../../components/table/Table";
//import {ReceiptTableConstants} from "./ReceiptTableConstants";
import Filters from "../../components/filters/Filters";
import {KeyValuePair} from "../../types/CommonTypes";
import {FilterSettings} from "../../types/FilterTypes";

const ReceiptPage = () => {
    const [receipts, setReceipts] = useState<any[]>([]);
    const [rowDeleteRequested, setRowDeleteRequested] = useState<any>(undefined);
    const [queryParams, setQueryParams] = useState<string>();
    const [filters, setFilters] = useState<KeyValuePair>({} as KeyValuePair);

    const navigate = useNavigate();

    const filtersSettings: FilterSettings[] = [
        {
            key: 'series',
            display: 'Serie',
            type: 'text',
        },
        {
            key: 'number',
            display: 'Numar',
            type: 'number'
        },
        {
            key: 'created_from',
            display: 'Data emitere (de la)',
            type: 'date'
        },
        {
            key: 'created_to',
            display: 'Data emitere (pana la)',
            type: 'date'
        }
    ];
}
export const OperationsTableConstants = [
    {
        id: 'document_type',
        label: 'Tip document',
        width: 100
    },
    {
        id: 'document_number',
        label: 'Nr. document',
        width: 100
    },
    {
        id: ["series", "series_number"],
        label: "Serie / Nr.",
        width: 100,
        composite: true
    },
    {
        id: 'buyer_partner',
        label: 'Cumparator',
        width: 100
    },
    {
        id: 'seller_partner',
        label: 'Vanzator',
        width: 100
    },
    {
        id: 'total_price',
        label: 'Pret total',
        width: 100
    },
    {
        id: 'payment_type',
        label: 'Tip operatie',
        width: 100
    },
    {
        id: 'issued_date',
        label: 'Data emiterii',
        width: 100
    }
]