import {Container, Dialog, DialogContent, DialogTitle, IconButton, useMediaQuery, useTheme} from "@mui/material";
import Table from "../../components/table/Table";
import {UsersTableConstants} from "./UsersTableConstants";
import {Link, useLocation, useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {Close} from "@mui/icons-material";
import {FieldValues} from "react-hook-form";
import * as api from "../../api/api-client";
import {toast} from "react-toastify";
import DeleteConfirmationDialog from "../../components/DeleteConfirmationDialog";
import {KeyValuePair} from "../../types/CommonTypes";
import {FilterSettings} from "../../types/FilterTypes";
import Filters from "../../components/filters/Filters";
import CreateUserForm from "../../components/forms/CreateUserForm";
import {Roles} from "../../common/enums/roles";

const UsersPage = () => {
    const [users, setUsers] = useState<any>([]);
    const [createUserDialogOpen, setCreateUserDialogOpen] = useState<boolean>(false);
    const [rowDeleteRequested, setRowDeleteRequested] = useState<any>(undefined);
    const [reset, setReset] = useState<number>(0);
    const [queryParams, setQueryParams] = useState<string>('');
    const [filters, setFilters] = useState<KeyValuePair>({} as KeyValuePair);

    const actions: any[] = [
        {
            name: 'deleteInvoiceRow',
            displayName: 'Delete row',
            action: handleDeleteRowRequest
        }
    ]

    const navigate = useNavigate();

    const filterable: FilterSettings[] = [
        {
            key: 'email',
            display: 'email',
            type: 'text'
        }
    ];

    const location = useLocation();

    useEffect(() => {
        if (queryParams !== location.search) {
            setQueryParams(location.search);
        }
    }, [location]);

    useEffect(() => {
        api.get(`/users${queryParams}`).then((res) => {
            res.forEach((item: any) => {
                if (item.userRoles && item.userRoles.length > 0)
                    item.role = Roles[item.userRoles[0].role];
            });
            setUsers(res);
        }).catch((err) => {
            toast.error(err);
        })
    }, [queryParams, reset])

    useEffect(() => {
        if (filters) {
            navigate(`?${new URLSearchParams(filters).toString()}`);
        }
    }, [filters])

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    function handleDeleteRowRequest(row: any) {
        setRowDeleteRequested(row);
    }

    const handleDeleteRow = () => {
        api.remove(`/users/${rowDeleteRequested.id}`).then((res) => {
            toast.success(res.message);
            setRowDeleteRequested(undefined);
            setReset(reset + 1);
        }).catch((err) => {
            toast.error(err);
        })
    }

    const rowDeleteCanceled = (row: any) => {
        setRowDeleteRequested(null);
    }

    const formatCell = (row: any, value: string) => {
        return <Link to={`${row.id}`}>{value}</Link>
    }

    const getCellColor = (rowKey: string, index: number) => {
        return "";
    }

    const handleUserCreation = (data: FieldValues) => {
        api.post('/users', data).then((res) => {
            setCreateUserDialogOpen(false);
            toast.success('Utilizatorul a fost adaugat');
            setReset(reset + 1);
        }).catch((err) => {
            toast.error(err.error);
        });
    }

    const onFilterSubmit = (filters: KeyValuePair) => {
        setFilters(filters);
    }

    return (
        <Container sx={{height: '100%', margin: 0, marginTop: '3rem', maxWidth: '100vw !important'}}>
            <Filters filters={filters} filtersSettings={filterable} onFiltersSubmit={onFilterSubmit}/>

            <Table headers={UsersTableConstants}
                   rows={users}
                   getFormattedCell={formatCell}
                   handleAddActionClick={() => setCreateUserDialogOpen(true)}
                   actions={actions}
                   getCellColor={getCellColor}
            />

            {rowDeleteRequested &&
                <DeleteConfirmationDialog rowDeleteRequested={rowDeleteRequested}
                                          deleteConfirmationText={rowDeleteRequested.email}
                                          handleDeleteRow={handleDeleteRow} rowDeleteCanceled={rowDeleteCanceled}/>
            }

            <Dialog
                fullScreen={fullScreen}
                open={createUserDialogOpen}
                aria-labelledby="responsive-dialog-title"
                fullWidth
                maxWidth="lg"
            >
                <DialogTitle id="responsive-dialog-2"
                             sx={{width: '100%', display: 'flex', justifyContent: 'space-between'}}>
                    Adauga utilizator
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={() => setCreateUserDialogOpen(false)}
                        aria-label="close"
                        sx={{justifySelf: 'end'}}
                    >
                        <Close/>
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{height: '100%'}}>
                    <CreateUserForm onUserSubmit={handleUserCreation}/>
                </DialogContent>
            </Dialog>
        </Container>
    );
}

export default UsersPage;
