import {FieldValues, useForm} from "react-hook-form";
import {Box, Button, Container, Grid} from "@mui/material";
import BasicFormControl from "../form-helpers/BasicFormControl";
import SelectFormControl from "../form-helpers/SelectFormControl";
import AutocompleteFormControl from "../form-helpers/AutocompleteFormControl";
import * as api from "../../api/api-client";
import {toast} from "react-toastify";
import {useEffect, useState} from "react";
import DatePickerFormControl from "../form-helpers/DatePickerFormControl";
import {CashRegisterDto} from "../../types/CashRegisterDto";

interface Props {
    onReceiptSubmit: (data: FieldValues) => void;
    invoice: any
    paymentType: "INCASARE" | "PLATA"
}

const currencyTypes = [
    {
        label: "RON",
        value: "RON"
    },
    {
        label: "EUR",
        value: "EUR"
    }
];

const CreateReceiptForm = (props: Props) => {
    const [partners, setPartners] = useState<any>([]);
    const [buyerPartner, setBuyerPartner] = useState<any>();
    const [series, setSeries] = useState<any>([]);
    const [cashRegisters, setCashRegisters] = useState<any>([]);

    const {
        handleSubmit,
        formState: {errors},
        control,
        setValue,
        getValues,
        watch
    } = useForm({
        defaultValues: {
            invoice_id: props.invoice.invoice_id,
            buyer_partner_id: props.invoice.buyer_id,
            cash_register_id: "",
            seller_partner_id: props.invoice.client_id,
            currency: props.invoice.currency,
            total_price: Number(props.invoice.total_price_incl_vat - props.invoice.total_paid_price).toFixed(2),
            vat_price: "",
            series: "",
            series_number: "",
            description: "",
            issued_date: new Date().toDateString(),
            payment_type: props.paymentType,
        },
    });

    const seriesWatch = watch("series");
    const issuedDateWatch = watch("issued_date");
    const totalPriceWatch = watch("total_price");

    useEffect(() => {
        api.get("/users/series").then(res => {
            const userSeries = res.filter((series: any) => series.invoice_type === "receipt")
                .map((series: any) => ({
                    value: series.series,
                    label: series.series
                }));

            setSeries(userSeries);
        }).catch(err => {
            toast("Could not get data", {type: "error"});
        });

        api.get(`/invoices/${props.invoice.invoice_id}`)
            .then(res => {
                setBuyerPartner(res.invoice.buyer);
            })
            .catch(err => {
                toast(err.message, {type: "error"});
            });

        api.get(`/cashregister?partner_id=${props.invoice.client_id}&currency=${props.invoice.currency}`)
            .then(res => {
                setCashRegisters(res.map((cashRegister: CashRegisterDto) => ({
                    label: `${cashRegister.name} (${cashRegister.currency})`,
                    value: cashRegister.cash_register_id
                })))
            })
            .catch(err => {
                toast(err.message, { type: "error" })
            });
    }, [props.invoice.invoice_id, props.invoice.client_id]);

    useEffect(() => {
        api.post("/receipt/series", {
            series: seriesWatch,
        }).then(res => {
            setValue("series_number", res.number);
        }).catch(err => {
            toast(err.message, { type: "error" });
        });
    }, [seriesWatch]);

    useEffect(() => {
        setValue("issued_date", new Date(issuedDateWatch).toDateString());
    }, [issuedDateWatch]);

    useEffect(() => {
        const formValues = getValues();

        if(formValues.currency === "RON") {
            setValue("vat_price", Number(parseFloat(totalPriceWatch) * 19 / 100).toFixed(2));
        } else {
            setValue("vat_price", "0");
        }
    }, [totalPriceWatch]);

    const onPartnerAutocompleteInput = (event: any) => {
        const {value} = event.target;

        if (value.length >= 3) {
            api.get(`/partners/user/autocomplete?searchKey=${value}`).then((res) => {
                setPartners(res);
            }).catch((err) => {
                    toast(err, {type: 'error'});
                    console.error(err);
                }
            )
        }
    }

    const onPartnerAutocompleteChange = (event: any, value: any) => {
        setValue('buyer_partner_id', value.partner_id);
    }

    const handleReceiptSubmit = (data: FieldValues) => {
        props.onReceiptSubmit(data);
    }

    return (
        <form onSubmit={handleSubmit(handleReceiptSubmit)}>
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                <Box sx={{ display: 'flex', width: '100%' }}>
                    <SelectFormControl control={control} name='series' displayText='Serie' variant='outlined' errors={errors}
                                       selectOptions={series}
                                       rules={{
                                           required: {value: true, message: 'Seria este obligatorie'}
                                       }}/>
                    <BasicFormControl control={control} name="series_number" displayText="Nr chitanta" variant="outlined"
                                      disabled={true} />
                    <DatePickerFormControl control={control} name="issued_date" displayText="Data chitanta" variant='outlined' />
                </Box>

                <Box sx={{ display: 'flex', width: '100%' }}>
                    {buyerPartner && <AutocompleteFormControl
                                        control={control}
                                         name='buyer_partner_id'
                                         displayText='Platitor'
                                         defaultValue={{
                                             partner_id: buyerPartner?.partner_id,
                                             partner_name: buyerPartner?.name
                                         } || ""}
                                         options={partners}
                                         optionLabelFunc={(partners) => partners?.partner_name || ""}
                                         onInputHandler={onPartnerAutocompleteInput}
                                         onChangeHandler={onPartnerAutocompleteChange}
                                         variant='outlined'
                                         rules={{
                                             required: {value: true, message: 'Firma este obligatorie.'}
                                         }} />
                    }

                    <SelectFormControl control={control} name='cash_register_id' displayText='Casa' variant='outlined' errors={errors}
                                       selectOptions={cashRegisters}
                                       rules={{
                                           required: {value: true, message: 'Casa este obligatorie'}
                                       }}/>
                </Box>

                <Box sx={{ display: 'flex', width: '100%'}}>
                    <BasicFormControl multiline rows={4} control={control} name="description" displayText="Detalii plata" variant='outlined' />
                </Box>

                <Box sx={{ display: 'flex', width: '100%'}}>
                    <BasicFormControl control={control} type="number" name="total_price" displayText="Valoare" variant='outlined' errors={errors}
                                      adornment={props.invoice.currency}
                                      rules={{
                                          required: {value: true, message: 'Valoarea este obligatorie.'},
                                          validate: (field) => parseFloat(field) < parseFloat(props.invoice.total_price_incl_vat) - parseFloat(props.invoice.total_paid_price) + 0.001
                                                    || "Valoarea platita trebuie sa fie mai mica decat suma totala."
                                      }}/>

                    <BasicFormControl control={control} type="number" name="vat_price" displayText="Din care TVA" variant='outlined'
                                      disabled
                                      adornment={props.invoice.currency}/>
                </Box>
            </Box>
            <Button sx={{my: 2, width: '100%'}} type="submit" variant="contained">Submit</Button>
        </form>

    )
}

export default CreateReceiptForm;