import { FC, useCallback, useMemo, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { Box, Button, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import SelectFormControl from '../form-helpers/SelectFormControl';
import DatePickerFormControl from '../form-helpers/DatePickerFormControl';
import BasicFormControl from '../form-helpers/BasicFormControl';
import * as api from '../../api/api-client';
import { toast } from 'react-toastify';
import { currencyOptions, defaultValues, otherExpensesOption, serviceLists } from '../../pages/repair-estimate/constants';

interface Props {
	onAddSuccess: (message: string) => void;
	categoryOptions: CategoryOption[];
}

export type AutofleetOption = { auto_fleet_id?: string; reg_no?: string };
export type PartnerOption = { partner_id?: string; name?: string };
export type CategoryOption = AutofleetOption & PartnerOption;

const CreateRepairEstimateForm: FC<Props> = ({ onAddSuccess, categoryOptions }) => {
	const [optionSelected, setOptionSelected] = useState<string>('');
	const categorySelected: CategoryOption | undefined = useMemo(
		() => categoryOptions?.find((el) => el.reg_no === optionSelected || el.name === optionSelected),
		[optionSelected, categoryOptions]
	);
	const { handleSubmit, control } = useForm({defaultValues});

	const handleAutoFleetSubmit = useCallback(
		async (data: FieldValues) => {
			try {
				const { auto_fleet_id, partner_id } = categorySelected || {};
				const { deviz_id, ...restData} = data;
				const res = await api.post('/deviz', {
					...restData,
					...{ auto_fleet_id, partner_id },
				});
				onAddSuccess(res?.message as string);
			} catch (err) {
				const { error } = err as { error: string };
				toast.error(error);
			}
		},
		[categorySelected, onAddSuccess]
	);

	return (
		<form onSubmit={handleSubmit(handleAutoFleetSubmit)}>
			<Box sx={{ display: 'flex', flexDirection: { sm: 'column', md: 'row' }, alignItems: {md: 'center'} }}>
				{/* Category Selection */}
				<FormControl sx={{ width: '100%' }} variant='standard'>
					<InputLabel id='select-label'>Categorie</InputLabel>
					<Select
						value={optionSelected}
						onChange={(e) => setOptionSelected(e.target.value)}>
						{categoryOptions?.map((el, i) => (
							<MenuItem value={el.reg_no ?? el.name} key={`category-item-${i}`}>{el.reg_no ?? el.name}</MenuItem>
						))}
					</Select>
				</FormControl>
				<DatePickerFormControl
					disabled={!categorySelected}
					control={control}
					name='date'
					displayText='Data'
					variant='standard'
				/>
				<SelectFormControl
					disabled={!categorySelected}
					name={'denumire'}
					displayText='Denumire'
					control={control}
					selectOptions={
						!optionSelected ? [] : serviceLists[categorySelected?.partner_id ? 'partner' : 'autofleet'].concat(otherExpensesOption)
					}
					variant='standard'
				/>
				<BasicFormControl
					disabled={!categorySelected}
					type='number'
					control={control}
					name='pret_fara_tva'
					displayText='Pret'
				/>
				<BasicFormControl
					disabled={!categorySelected}
					type='number'
					control={control}
					name='cota_tva'
					displayText='Cota TVA'
				/>
				<SelectFormControl
					disabled={!categorySelected}
					name={'currency'}
					control={control}
					displayText='Moneda'
					selectOptions={currencyOptions}
					variant='standard'
				/>
				<BasicFormControl
					disabled={!categorySelected}
					control={control}
					name='infos'
					displayText='Observatii'
				/>
			</Box>
			<Button type='submit' variant='contained' sx={{ width: '100%', my: 2 }}>
				Submit
			</Button>
		</form>
	);
};

export default CreateRepairEstimateForm;
