import {Box, Typography} from "@mui/material";
import ReportsCard from "../reports-card/ReportsCard";
import {Description, MonetizationOn, ReceiptLong} from "@mui/icons-material";
import React, {useEffect, useState} from "react";

interface Props {
  content: any
}

interface Profit {
  currency: string;
  amount: number;
}

const OrderReports = (props: Props) => {
  const [profit, setProfit] = useState<Profit[]>([]);
  const [totalOrders, setTotalOrders] = useState<number>(0);
  const [numberOfInvoicedOrders, setNumberOfInvoicedOrders] = useState<number>(0);

  useEffect(() => {
    setTotalOrders(props?.content?.length);
    setNumberOfInvoicedOrders((props.content?.filter((order: any) => order.invoice_generated === true)).length);
  }, [props.content]);

  useEffect(() => {
    let ronProfit: Profit = {currency: 'RON', amount: 0};
    let eurProfit: Profit = {currency: 'EUR', amount: 0};

    props?.content?.map((orderItem: any) => {
      orderItem.profit_currency === 'RON' ? ronProfit.amount += Number(orderItem.profit) : eurProfit.amount += Number(orderItem.profit);
    });

    eurProfit.amount = parseFloat(eurProfit.amount?.toFixed(2));
    ronProfit.amount = parseFloat(ronProfit.amount?.toFixed(2));

    setProfit([eurProfit, ronProfit]);
  }, [props.content])

  return <Box sx={{
    display: 'flex',
    flexWrap: 'wrap', justifyContent: {xs:'space-evenly', md: 'space-between'}}}>
    <ReportsCard titleText='Profit' icon={<MonetizationOn/>} content={<>
      {profit.map((profitItem: Profit) => (
        <Box sx={{display: 'flex'}}>
          <Typography color='green' fontWeight='bold'
                      sx={{marginRight: '0.5rem'}}>{profitItem.amount}</Typography>
          <Typography color='white' fontWeight='bold'
                      sx={{textShadow: '1px 1px 2px black'}}>{profitItem.currency}</Typography>
        </Box>
      ))}
    </>}/>
    <ReportsCard titleText='Numar comenzi' icon={<ReceiptLong/>} content={<Box sx={{display: 'flex'}}>
      <Typography color='green' fontWeight='bold'
                  sx={{
                    marginRight: '0.5rem',
                  }}>{totalOrders}</Typography>
      <Typography color='white' fontWeight='bold'
                  sx={{
                    marginRight: '0.5rem',
                    textShadow: '1px 1px 2px black',
                    fontSize: '1rem'
                  }}>
        {totalOrders === 1 ? 'comanda' : 'comenzi'}
      </Typography>
    </Box>}/>
    <ReportsCard titleText='Facturi generate' icon={<Description/>} content={
      <Box sx={{display: 'flex'}}>
        <Typography color='#1976d2' fontWeight='bold'
                    sx={{
                      marginRight: '0.5rem',
                    }}>{numberOfInvoicedOrders}</Typography>
        <Typography color='white' fontWeight='bold'
                    sx={{
                      marginRight: '0.5rem',
                      textShadow: '1px 1px 2px black',
                      fontSize: '1rem'
                    }}>
          facturi generate
        </Typography>
      </Box>
    }/>
  </Box>
}

export default OrderReports;