import {useState} from "react";
import {Autocomplete, Box, TextField,} from "@mui/material";
import {Controller, FieldValues, useForm} from "react-hook-form";
import * as api from "../../api/api-client";
import {toast} from "react-toastify";
import CreateProductForm from "./CreateProductForm";
import {ProductDto} from "../../types/ProductDto";

interface Props {
  invoiceType?: 'proforma' | 'issued' | 'received';
  handleAddInvoiceProduct: (data: FieldValues) => void;
}

const AddInvoiceProductForm = (props: Props) => {
  const [products, setProducts] = useState<any>([]);
  const [productName, setProductName] = useState<string>("");
  const [product, setProduct] = useState<ProductDto>({} as ProductDto);
  const [productInfo, setProductInfo] = useState<string[]>([]);
  const [resetTrigger, setResetTrigger] = useState<number>(0);

  const {
    control,
    setValue,
    setError,
    clearErrors
  } = useForm();

  const onProductAutocompleteInput = (event: any) => {
    const {value} = event.target;

    if (value.length >= 3) {
      api.get(`/products?product_name=${value}`).then((res) => {
        if (res.length === 0) {
          setProductName(value);
        } else {
          setProducts(res);
          setProductInfo(res.map((resItem: ProductDto) => resItem.product_name))
        }
      }).catch((err) => {
        toast(err, {type: 'error'})
      })
    }
  }

  const onProductAutocompleteChange = (event: any, value: string | null) => {
    if (!value) {
      setResetTrigger((trigger) => trigger+ 1);
      setProductName('');
      setProduct({} as ProductDto);
      return;
    }

    setValue('product', value);
    setProductName(value || '');
    setProduct(products.find((product: ProductDto) => product.product_name === value) || {} as ProductDto);
  }

  const onProductCreate = (data: any) => {
    data.product_name = productName;

    props.handleAddInvoiceProduct(data);
  }

  return (
    <>
      <Box sx={{display: "flex", flexDirection: "row"}}>
        <Controller
          render={(field) => (
            <Autocomplete
              {...field}
              options={productInfo}
              onInput={onProductAutocompleteInput}
              onChange={onProductAutocompleteChange}
              value={productName}
              sx={{width: '100%'}}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{width: '100%', my: '1rem'}}
                  variant="outlined"
                  label='Product'
                />
              )}
              // onChange={(_, data) => props.onChange!(data)}
            />
          )}
          name="product_name"
          control={control}
        />

      </Box>

      <CreateProductForm onProductInput={onProductCreate} product={product} disableNameInput={true}
                         resetTrigger={resetTrigger} invoiceType={props.invoiceType}/>

    </>
  )
}

export default AddInvoiceProductForm;
