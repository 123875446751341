import {FieldValues, useForm} from "react-hook-form";
import {Box, Button} from "@mui/material";
import BasicFormControl from "../form-helpers/BasicFormControl";
import SelectFormControl from "../form-helpers/SelectFormControl";
import AutocompleteFormControl from "../form-helpers/AutocompleteFormControl";
import * as api from "../../api/api-client";
import {toast} from "react-toastify";
import {useState} from "react";

interface Props {
    onCashRegisterSubmit: (data: FieldValues) => void;
}

const currencyTypes = [{
    label: "RON",
    value: "RON"
},
    {
        label: "EUR",
        value: "EUR"
    }];

const CreateCashRegisterForm = (props: Props) => {
    const [partners, setPartners] = useState<any>([]);

    const {
        handleSubmit,
        formState: {errors},
        control,
        setValue
    } = useForm({
        defaultValues: {
            name: "",
            balance: 0,
            currency: "RON",
            partner_id: ""
        }
    });

    const onPartnerAutocompleteInput = (event: any) => {
        const {value} = event.target;

        if (value.length >= 3) {
            api.get(`/partners/user/autocomplete?searchKey=${value}`).then((res) => {
                setPartners(res);
            }).catch((err) => {
                    toast(err, {type: 'error'});
                    console.error(err);
                }
            )
        }
    }

    const onPartnerAutocompleteChange = (event: any, value: any) => {
        setValue('partner_id', value.partner_id);
    }

    const handleCashRegisterSubmit = (data: FieldValues) => {
        props.onCashRegisterSubmit(data);
    }

    return (
        <>
            <form onSubmit={handleSubmit(handleCashRegisterSubmit)}>
                <Box sx={{display: "flex", flexDirection: "column", width: "100%"}}>
                    <Box sx={{display: "flex", flexDirection: "column", width: "95%"}}>
                        <BasicFormControl control={control} name='name' displayText='Nume' variant='outlined'
                                          errors={errors}
                                          rules={{
                                              required: {value: true, message: 'Numele este obligatoriu'}
                                          }}/>
                        <AutocompleteFormControl control={control}
                                                 name='partner_id'
                                                 displayText='Firma'
                                                 options={partners}
                                                 optionLabelFunc={(partners) => partners?.partner_name || ""}
                                                 onInputHandler={onPartnerAutocompleteInput}
                                                 onChangeHandler={onPartnerAutocompleteChange}
                                                 variant='outlined'
                                                 rules={{
                                                     required: {value: true, message: 'Firma este obligatorie.'}
                                                 }}
                        />
                        <BasicFormControl control={control} name='balance' displayText='Sold' variant='outlined'
                                          errors={errors}
                                          type="number"
                                          rules={{
                                              required: {value: true, message: 'Soldul este obligatoriu'}
                                          }}/>
                        <SelectFormControl control={control} name='currency' displayText='Valuta' variant='outlined'
                                           errors={errors}
                                           selectOptions={currencyTypes}
                                           rules={{
                                               required: {value: true, message: 'Valuta este obligatorie'}
                                           }}/>
                    </Box>
                    <Button sx={{my: 2, width: '100%'}} type="submit" variant="contained">Submit</Button>
                </Box>
            </form>
        </>
    )
}

export default CreateCashRegisterForm;