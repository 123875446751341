import {useEffect, useState} from "react";
import {UserPartnerEmailType} from "../../types/UserTypes";
import * as api from "../../api/api-client";
import {toast} from "react-toastify";
import jwtDecode from "jwt-decode";
import {useNavigate} from "react-router-dom";
import {Box} from "@mui/material";
import Table from "../table/Table";
import {UserEmailTableConstants, UserSettingsTableConstants} from "../../pages/user/UserSettingsTableConstants";
import DialogContainer from "../DialogContainer";
import CreateUserPartnerEmailForm from "../forms/CreateUserPartnerEmailForm";
import {FieldValues} from "react-hook-form";

const UserPartnerEmail = () => {
  const [userPartners, setUserPartners] = useState<UserPartnerEmailType[]>([]);
  const [addUserEmailDialogOpen, setAddUserEmailDialogOpen] = useState<boolean>(false);
  const [user, setUser] = useState();

  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');

    if (!token) {
      navigate('/login')
    }

    const userId = (jwtDecode(token!) as any)._id;

    setUser(userId);

    api.get(`/users/email`).then((res) => {
      setUserPartners(res.map((resItem: any) => ({
        partner_name: resItem.partner_name,
        partner_id: resItem.partner_id,
        partner_email: resItem.partner_email,
        smtp: resItem.smtp,
        user_id: resItem.user_id
      })))
    }).catch((err) => {
      toast.error(err.message);
    })
  }, []);

  const handleAddUserEmailDialogState = (dialogState: boolean) => {
    setAddUserEmailDialogOpen(dialogState)
  }

  const onUserPartnerEmailSubmit = (data: FieldValues) => {
    data.user_id = user;

    api.post('/users/email', data).then((res) => {
      toast.success(res.message);
      handleAddUserEmailDialogState(false);

      api.get(`/users/email`).then((res) => {
        setUserPartners(res.map((resItem: any) => ({
          partner_name: resItem.partner_name,
          partner_id: resItem.partner_id,
          partner_email: resItem.partner_email,
          smtp: resItem.smtp,
          user_id: resItem.user_id
        })))
      }).catch((err) => {
        toast.error(err.message);
      })
    }).catch((err) => {
      toast.error(err.message);
    })
  }

  return (
    <>
      <Box>
        <Table headers={UserEmailTableConstants}
               rows={userPartners}
               handleAddActionClick={() => handleAddUserEmailDialogState(true)}
        />
      </Box>

      <DialogContainer isDialogOpen={addUserEmailDialogOpen} dialogTitle='Adauga o adresa de email'
                       handleDialogState={handleAddUserEmailDialogState}>
        <CreateUserPartnerEmailForm onUserPartnerEmailSubmit={onUserPartnerEmailSubmit}/>
      </DialogContainer>
    </>
  )
}


export default UserPartnerEmail;