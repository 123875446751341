import { EmployeeTimesheet, TimesheetEntry } from "../types/TimesheetDto";

export const isWeekend = (date = new Date()) => {
  return date.getDay() === 6 || date.getDay() === 0;
};

export const getDaysInMonth = (month: number, year: number) => {
  var date = new Date(year, month, 1);
  var days = [];
  while (date.getMonth() === month) {
    days.push(new Date(date));
    date.setDate(date.getDate() + 1);
  }
  return days;
};

export const calculateTotalHours = (timesheetEntries: TimesheetEntry[], date: Date) => {
  return timesheetEntries
    .filter((entry) => new Date(entry.date).getMonth() === date.getMonth())
    .reduce((acc, current) => {
      const workedHours = parseInt(current.hours_worked);

      if (!isNaN(workedHours)) return acc + workedHours;
      else return acc;
    }, 0);
};

export const calculateIllDaysCount = (timesheetEntries: TimesheetEntry[], currentDate: Date) => {
  return timesheetEntries.filter(
    (entry) => entry.hours_worked === "BO" && new Date(entry.date).getMonth() === currentDate.getMonth(),
  ).length;
};

export const calculateHolidaysCount = (timesheetEntries: TimesheetEntry[], currentDate: Date) => {
  return timesheetEntries.filter(
    (entry) => entry.hours_worked === "CO" && new Date(entry.date).getMonth() === currentDate.getMonth(),
  ).length;
};

export const calculateSupplementaryHours = (
  employee: EmployeeTimesheet,
  timesheetEntries: TimesheetEntry[],
  currentDate: Date,
) => {
  const totalRequiredHours = getDaysInMonth(currentDate.getMonth(), currentDate.getFullYear()).reduce(
    (acc, current) => {
      const timesheetEntry = timesheetEntries.find(
        (entry) => new Date(entry.date).toDateString() === current.toDateString(),
      );
      const isDayRequired = !isWeekend(current) && !isNaN(parseInt(timesheetEntry?.hours_worked || "0"));

      if (isDayRequired) return acc + employee.working_hours;
      else return acc;
    },
    0,
  );

  return calculateTotalHours(timesheetEntries, currentDate) - totalRequiredHours < 0
    ? 0
    : calculateTotalHours(timesheetEntries, currentDate) - totalRequiredHours;
};

export const months = [
  { label: "Ianuarie", value: "0" },
  { label: "Februarie", value: "1" },
  { label: "Martie", value: "2" },
  { label: "Aprilie", value: "3" },
  { label: "Mai", value: "4" },
  { label: "Iunie", value: "5" },
  { label: "Iulie", value: "6" },
  { label: "August", value: "7" },
  { label: "Septembrie", value: "8" },
  { label: "Octombrie", value: "9" },
  { label: "Noiembrie", value: "10" },
  { label: "Decembrie", value: "11" },
];

export const years = [
  { label: "2022", value: "2022" },
  { label: "2023", value: "2023" },
];
