import { IconButton, Tooltip, } from "@mui/material";
import * as XLSX from "xlsx";
import DownloadIcon from '@mui/icons-material/Download';

export interface ExcelHeader {
  id: string | string[],
  label: string
}

interface Props {
  excelData: any;
  excelHeaders: ExcelHeader[];
  exportName: string;
}

const XlsxTemplate = (props: Props) => {

  const onDownload = () => {
    let rows = props?.excelData.map((row: any) => {
      let obj: any = {};
      for (let field of props?.excelHeaders) {
        obj[field.label] = typeof (field.id) == 'string' ? row[field.id] : [row[field.id[0]], row[field.id[1]]].join(" ") || "-";
      }
      return obj;
    });

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(rows);
    ws['!cols'] = props?.excelHeaders.map((col: any) => {
      if (col.width === 1) {
        return { wch: 23 }
      } else if (col.width === -1) {
        return { wch: 5 }
      } else if (col.width === -2) {
        return { wch: 3 }
      } else {
        return { wch: 15 }
      }
    });

    XLSX.utils.book_append_sheet(wb, ws, "Sheet");
    XLSX.writeFile(wb, props?.exportName + ".xlsx");
  }

  return (
    <>
      <Tooltip title="Descarca Excel">
        <IconButton onClick={onDownload} color="primary">
          <DownloadIcon />
        </IconButton>
      </Tooltip>
    </>
  )
}

export default XlsxTemplate;