export const UsersTableConstants = [
  {
    id: 'email',
    label: 'Email',
    width: 100,
    type: 'href'
  },
  {
    id: 'last_name',
    label: 'Nume',
    width: 100
  },
  {
    id: 'first_name',
    label: 'Prenume',
    width: 100
  },

  {
    id: 'phone',
    label: 'Telefon',
    width: 100
  },
  {
    id: 'id_card_series',
    label: 'Serie CI',
    width: 100
  },
  {
    id: 'id_card_number',
    label: "Numar CI",
    width: 100
  },
  {
    id: 'id_card_issued_by',
    label: "Emitent CI",
    width: 100
  },
  {
    id: 'role',
    label: "Rol",
    width: 100
  }
]
