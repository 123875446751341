import React, {Fragment} from 'react';
import {Document, Image, Page, StyleSheet, Text, View} from '@react-pdf/renderer';
import {CreateBankAccountDto} from '../../types/CreatePartnerType';
import {ProductDto} from '../../types/ProductDto';
import {calculatePercentage} from "../../utils/price";

// @ts-ignore
// @ts-ignore
const styles = StyleSheet.create({
  container: {
    padding: 20,
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  columnHeader: {
    fontWeight: 'bold',
  },
  textRight: {
    textAlign: 'right',
    width: '30%'
  },
  pageContainer: {
    paddingVertical: 20
  },
  textBold: {
    fontWeight: 'bold',
    fontSize: 18,
    fontFamily: 'Roboto'
  },
  textSmall: {
    fontWeight: 'demibold',
    fontSize: 10,
    fontFamily: 'Roboto'
  },
  textNormal: {
    fontWeight: 'thin',
    fontSize: 12,
    fontFamily: 'Roboto'
  },
  title: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    marginTop: 40,
    marginBottom: 20
  },
  table: {
    display: "table" as any,
    width: "100%",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
    width: 'auto'
  },
  tableCol: {
    width: "20%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableCell: {
    margin: "auto",
    marginTop: 5,
    fontSize: 10
  }
});

interface Props {
  invoiceData: any;
  logo?: string;
}

const InvoicePdfTemplate = ({invoiceData, logo}: Props) => {
  return (
    <Document>
      <Page size="A4" wrap style={styles.pageContainer}>
        <View style={styles.container}>
          {Object.keys(invoiceData).length && (
            <Fragment>

              <View style={styles.row}>
                <View style={{width: '30%'}}>
                  <Text style={styles.textSmall}>Furnizor: {invoiceData?.invoice?.client?.name}</Text>
                  <Text
                    style={styles.textSmall}>C.U.I.: {invoiceData?.invoice?.client?.unique_identification_number}</Text>
                  <Text style={styles.textSmall}>Reg.
                    Com.: {invoiceData?.invoice?.client?.trade_register_registration_number}</Text>
                  <Text style={styles.textSmall}>Sediu: {invoiceData?.invoice?.client?.address}</Text>
                  {invoiceData?.invoice?.client?.BankAccounts?.map((bankAccount: CreateBankAccountDto) => {
                    return (
                      <Fragment key={bankAccount.iban}>
                        <Text style={styles.textSmall}>Banca: {bankAccount.bank_name}</Text>
                        <Text style={styles.textSmall}>IBAN: {bankAccount.iban}</Text>
                      </Fragment>
                    );
                  })}
                </View>

                <Image style={{width: '31%', height: 'auto', marginTop: 60}} src={logo}/>

                <View style={styles.textRight}>
                  <Text wrap style={styles.textSmall}>Client: {invoiceData?.invoice?.buyer?.name}</Text>
                  <Text
                    wrap
                    style={styles.textSmall}>C.U.I.: {invoiceData?.invoice?.buyer?.unique_identification_number}</Text>
                  <Text wrap style={styles.textSmall}>Reg.
                    Com.: {invoiceData?.invoice?.buyer?.trade_register_registration_number}</Text>
                  <Text wrap style={styles.textSmall}>Sediu: {invoiceData?.invoice?.buyer?.address}</Text>
                </View>
              </View>

              <View style={styles.title}>
                <Text style={styles.textBold}>FACTURA</Text>

                <Text style={styles.textNormal}>Nr.
                  factura: {invoiceData?.invoice?.series} {invoiceData?.invoice?.number}</Text>
                <Text style={styles.textNormal}>
                  Data emitere: {new Date(invoiceData?.invoice?.created_at_utc).toLocaleDateString('ro-RO')}
                </Text>
                <Text style={styles.textNormal}>
                  Data scadenta: {new Date(invoiceData?.invoice?.deadline_at_utc).toLocaleDateString('ro-RO') || ''}
                </Text>
                <Text style={styles.textNormal}>Cota TVA: 19%</Text>
              </View>

              <View style={styles.table}>
                <View style={styles.tableRow}>
                  <View style={{
                    width: "30%",
                    borderStyle: "solid",
                    borderWidth: 1,
                    borderLeftWidth: 0,
                    borderTopWidth: 0
                  }}><Text style={styles.tableCell}>Denumire produs sau serviciu</Text></View>
                  <View style={{
                    width: "15%",
                    borderStyle: "solid",
                    borderWidth: 1,
                    borderLeftWidth: 0,
                    borderTopWidth: 0
                  }}><Text style={styles.tableCell}>U.M.</Text></View>
                  <View style={{
                    width: "15%",
                    borderStyle: "solid",
                    borderWidth: 1,
                    borderLeftWidth: 0,
                    borderTopWidth: 0
                  }}><Text style={styles.tableCell}>Cant.</Text></View>
                  <View style={styles.tableCol}><Text style={styles.tableCell}>Pret unitar (fara TVA)</Text></View>
                  <View style={styles.tableCol}><Text style={styles.tableCell}>TVA</Text></View>
                </View>
                {invoiceData?.productList?.map((product: ProductDto) => {
                  return (
                    <View key={product.product_id} style={styles.tableRow}>
                      <View style={{
                        width: "30%",
                        borderStyle: "solid",
                        borderWidth: 1,
                        borderLeftWidth: 0,
                        borderTopWidth: 0
                      }}><Text style={styles.tableCell}>{product.product_name}</Text></View>
                      <View style={{
                        width: "15%",
                        borderStyle: "solid",
                        borderWidth: 1,
                        borderLeftWidth: 0,
                        borderTopWidth: 0
                      }}><Text
                        style={styles.tableCell}>{product.unit_of_measure}</Text></View>
                      <View style={{
                        width: "15%",
                        borderStyle: "solid",
                        borderWidth: 1,
                        borderLeftWidth: 0,
                        borderTopWidth: 0
                      }}><Text style={styles.tableCell}>{product.quantity}</Text></View>
                      <View style={styles.tableCol}><Text
                        style={styles.tableCell}>{product.purchase_price}</Text></View>
                      <View style={styles.tableCol}><Text
                        style={styles.tableCell}>{
                        parseFloat((calculatePercentage(Number(product.purchase_price), Number(product.vat))
                          - Number(product.purchase_price)).toFixed(2))
                      }</Text></View>
                    </View>
                  );
                })}
              </View>

              <View style={{flexDirection: 'column', alignItems: 'flex-end', marginVertical: 20, marginHorizontal: 20}}>
                <View style={styles.row}>
                  <Text style={styles.textNormal}>Total unitar (fara TVA):</Text>
                  <Text style={{marginRight: 10}}/>
                  <Text style={styles.textNormal}>
                    {parseFloat(Number(invoiceData?.invoice?.total_price).toFixed(2))} {invoiceData?.invoice?.currency}
                  </Text>
                </View>
                <View style={styles.row}>
                  <Text style={styles.textNormal}>Total TVA:</Text>
                  <Text style={{marginRight: 10}}/>
                  <Text style={styles.textNormal}>
                    {invoiceData?.invoice?.total_vat} {invoiceData?.invoice?.currency}
                  </Text>
                </View>
                <View style={styles.row}>
                  <Text style={styles.textNormal}>Total de plata:</Text>
                  <Text style={{marginRight: 10}}/>
                  <Text style={styles.textNormal}>
                    {parseFloat(Number(invoiceData?.invoice?.total_price_incl_vat).toFixed(2))} {invoiceData?.invoice?.currency}
                  </Text>
                </View>
              </View>
              <View style={{marginTop: 15}}>
                <Text style={styles.textNormal}>
                  Conform articol 14. alin. 4 al regulamentului general privind protectia datelor nr.679/2016, va
                  informam ca datele Dvs. personale, constand in nume,prenume,serie si nr.CI/BI,adresa, colectate pt
                  emiterea prezentei facturi/avize, nu se va prelucra cu alte scopuri, si se va pastra ( stoca )
                  cf.legii.
                </Text>
                <Text style={styles.textNormal}>
                  Factura circula fara stampila si semnatura conform Legii 227/2015 privind Codul Fiscal Art. 319,
                  Alin. 29
                </Text>
              </View>
            </Fragment>
          )}
        </View>
      </Page>
    </Document>
  );
};

export default InvoicePdfTemplate;
