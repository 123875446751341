export const ReceiptTableConstants = [
    {
        id: ['series', 'series_number'],
        label: 'Serie / Nr chitanta',
        width: 100,
        type: 'href',
        composite: true
    },
    {
        id: 'buyer_partner',
        label: 'Cumparator',
        width: 100
    },
    {
        id: 'seller_partner',
        label: 'Vanzator',
        width: 100
    },
    {
        id: 'total_price',
        label: 'Pret total',
        width: 100
    },
    {
        id: 'vat_price',
        label: 'TVA',
        width: 100
    },
    {
        id: 'currency',
        label: 'Valuta',
        width: 100
    },
    {
        id: 'cash_register',
        label: 'Casa',
        width: 100
    },
    {
        id: 'issued_date',
        label: 'Data emiterii',
        width: 100,
        type: 'date'
    }
]