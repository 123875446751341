import { Box, Typography } from "@mui/material";
import ReportsCard from "../reports-card/ReportsCard";
import { MonetizationOn, ReceiptLong } from "@mui/icons-material";
import { useEffect, useState } from "react";

interface Props {
  content: any
}

const RepairEstimateReports = (props: Props) => {
  const [totalRepairEstimates, setTotalRepairEstimates] = useState<number>(0);

  useEffect(() => {
    setTotalRepairEstimates(props?.content?.devize?.length);
  }, [props.content]);

  return (
    <Box sx={{
      display: 'flex',
      flexWrap: 'wrap', justifyContent: { xs: 'space-evenly', md: 'space-between' }
    }}>
      <ReportsCard titleText="Total RON" icon={<MonetizationOn />} content={<>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Box sx={{ display: 'flex' }}>
            <Typography color='white' fontWeight='bold'
              sx={{ textShadow: '1px 1px 2px black', width: '150px' }}>Val. fara TVA </Typography>
            <Typography color='green' fontWeight='bold'
              sx={{ marginRight: '0.5rem' }}>{props?.content?.valfaraTvaRon}</Typography>
          </Box>
          <Box sx={{ display: 'flex' }}>
            <Typography color='white' fontWeight='bold'
              sx={{ textShadow: '1px 1px 2px black', width: '150px' }}>Total</Typography>
            <Typography color='green' fontWeight='bold'
              sx={{ marginRight: '0.5rem' }}>{props?.content?.totalRon}</Typography>
          </Box>
          <Box sx={{ display: 'flex' }}>
            <Typography color='white' fontWeight='bold'
              sx={{ textShadow: '1px 1px 2px black', width: '150px' }}>TVA</Typography>
            <Typography color='green' fontWeight='bold'
              sx={{ marginRight: '0.5rem' }}>{Number(Number(props?.content?.totalRon)-Number(props?.content?.valfaraTvaRon)).toFixed(2)}</Typography>
          </Box>
        </Box>
      </>
      } />
      <ReportsCard titleText="Total EURO" icon={<MonetizationOn />} content={<>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Box sx={{ display: 'flex' }}>
            <Typography color='white' fontWeight='bold'
              sx={{ textShadow: '1px 1px 2px black', width: '150px' }}>Val. fara TVA </Typography>
            <Typography color='green' fontWeight='bold'
              sx={{ marginRight: '0.5rem' }}>{props?.content?.valfaraTvaEuro}</Typography>
          </Box>
          <Box sx={{ display: 'flex' }}>
            <Typography color='white' fontWeight='bold'
              sx={{ textShadow: '1px 1px 2px black', width: '150px' }}>Total</Typography>
            <Typography color='green' fontWeight='bold'
              sx={{ marginRight: '0.5rem' }}>{props?.content?.totalEuro}</Typography>
          </Box>
          <Box sx={{ display: 'flex' }}>
            <Typography color='white' fontWeight='bold'
              sx={{ textShadow: '1px 1px 2px black', width: '150px' }}>TVA</Typography>
            <Typography color='green' fontWeight='bold'
              sx={{ marginRight: '0.5rem' }}>{Number(Number(props?.content?.totalEuro)-Number(props?.content?.valfaraTvaEuro)).toFixed(2)}</Typography>
          </Box>
        </Box>
      </>
      } />
      <ReportsCard titleText='Numar devize' icon={<ReceiptLong />} content={<Box sx={{ display: 'flex' }}>
        <Typography color='green' fontWeight='bold'
          sx={{
            marginRight: '0.5rem',
          }}>{totalRepairEstimates}</Typography>
        <Typography color='white' fontWeight='bold'
          sx={{
            marginRight: '0.5rem',
            textShadow: '1px 1px 2px black',
            fontSize: '1rem'
          }}>
          {totalRepairEstimates === 1 ? 'deviz' : 'devize'}
        </Typography>
      </Box>
      } />
    </Box>
  )
}

export default RepairEstimateReports;